import { VaccinationAct as TeamVaccinationAct } from "@syadem/kairos-team-js";
import { useEffect, useState } from "react";
import { useApis } from "../providers/Dependencies";
import { useAppContext } from "./useAppContext";
import { VaccinationAct as ProVaccinationAct } from "@syadem/kairos-pro-js";

export function useUnsharedHealthRecordVaccinationAct(
  healthRecordId: string,
  vaccinationActId: string
): {
  isLoading: boolean;
  unsharedVaccinationAct?: ProVaccinationAct | TeamVaccinationAct;
  error?: Response | Error;
} {
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();

  const [unsharedVaccinationAct, setUnsharedVaccinationAct] = useState<ProVaccinationAct | TeamVaccinationAct | undefined>(undefined);
  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        // TODO: Wrap this inside a query
        if (organizationId && teamId) {
          const { vaccinationActs } = await apis.team.vaccinationActApi.allUnsharedVaccinationActs(organizationId, teamId, healthRecordId)
          if (vaccinationActs && vaccinationActs.length > 0) {
            setUnsharedVaccinationAct(vaccinationActs.find((vaccinationAct) => vaccinationAct.id === vaccinationActId));
          }
        } else {
          const { vaccinationActs } = await apis.pro.vaccinationActApi.allUnsharedVaccinationActs(healthRecordId)
          if (vaccinationActs && vaccinationActs.length > 0) {
            setUnsharedVaccinationAct(vaccinationActs.find((vaccinationAct) => vaccinationAct.id === vaccinationActId));
          }
        }
      } catch (error) {
        if (error instanceof Response || error instanceof Error) {
          setError(error);
        } else {
          throw error;
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [apis, healthRecordId, vaccinationActId, organizationId, teamId]);

  return { isLoading, unsharedVaccinationAct, error };
}
