import { parseApiTeam } from "../network/parsers/parseApiTeam";
import { TeamApiInterface } from "@syadem/kairos-team-js";
import { AppStore } from "../store";
import { Success } from "../actionResult";
import { Team } from "../domain/team";
import { Service } from "./service";

interface Payload {
  team: {
    name: string;
    address: string;
    city: string;
    zipCode: string;
    phoneNumber: string;
  };
  organizationId: string;
}

export class CreateTeamService implements Service<Payload, Team> {
  constructor(
    private readonly store: AppStore,
    private readonly teamApi: TeamApiInterface
  ) {}

  async call({ team, organizationId }: Payload): Promise<Success<Team>> {
    const apiTeam = (await this.teamApi.createTeam(organizationId, { team })).team;
    const domainTeam = parseApiTeam(apiTeam, organizationId);

    this.store.setState((state) => ({
      ...state,
      teams: {
        ...(state.teams || {}),
        [domainTeam.id]: domainTeam
      },
    }));

    return {
      type: "success",
      value: domainTeam
    };
  }
}
