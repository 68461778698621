import { VaccinationAct as TeamVaccinationAct } from "@syadem/kairos-team-js";
import { useCallback, useEffect, useState } from "react";
import { useApis } from "../providers/Dependencies";
import { useAppContext } from "./useAppContext";
import { VaccinationAct as ProVaccinationAct } from "@syadem/kairos-pro-js";

export function useHealthRecordVaccinations(id: string): {
  isLoading: boolean;
  vaccinationActs?: ProVaccinationAct[] | TeamVaccinationAct[];
  error?: Response | Error;
  refreshVaccinations: () => void;
} {
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();

  const [vaccinationActs, setVaccinationActs] = useState<ProVaccinationAct[] | TeamVaccinationAct[] | undefined>(undefined);
  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const refreshVaccinations = useCallback(() => {
    setRefreshCounter((prev) => prev + 1);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        // TODO: Wrap this inside a query
        if (organizationId && teamId) {
          const { vaccinationActs } = await apis.team.vaccinationActApi.allVaccinationActs(organizationId, teamId, id);
          setVaccinationActs(vaccinationActs);
        } else {
          const { vaccinationActs } = await apis.pro.vaccinationActApi.allVaccinationActs(id);
          setVaccinationActs(vaccinationActs);
        }
      } catch (error) {
        if (error instanceof Response || error instanceof Error) {
          setError(error);
        } else {
          throw error;
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [apis, id, refreshCounter, organizationId, teamId]);

  return { isLoading, vaccinationActs, error, refreshVaccinations };
}
