import { useContext } from "react";
import { ServiceBus } from "../../services/serviceBus";
import { DependenciesContext } from "../providers/Dependencies";

export function useServiceBus(): ServiceBus {
  const dependencies = useContext(DependenciesContext);
  if (!dependencies) {
    throw new Error("Dependencies container is null, did you forget to pass it to the provider?");
  } else {
    return dependencies.serviceBus;
  }
}
