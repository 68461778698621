import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Grid, Typography } from "@mui/material";
import { City, Professional, Team } from "@syadem/ariane-js";
import { isEqual } from "lodash-es";
import { useEffect, useState } from "react";
import { useArianeApi } from "../hooks/useArianeApi";
import { useI18n } from "../hooks/useI18n";
import { theme } from "../layout/Theme";
import StyledAutoComplete from "./mui/StyledAutoComplete";
import { CitySearchField } from "./shared/CitySearchField";

export function CityAndProAutocomplete({
  city,
  cityError,
  onCityChange,
  professional,
  professionalError,
  onProfessionalChange,
  onArianeError,
}: {
  city?: City;
  cityError?: boolean;
  onCityChange: (city?: City) => void;
  professional?: Professional | Team;
  professionalError?: boolean;
  onProfessionalChange: (professional?: Professional | Team) => void;
  onArianeError: (error: unknown) => void;
}) {
  const { t } = useI18n();
  const arianeApi = useArianeApi();
  const [professionalOptions, setProfessionalOptions] = useState<(Professional | Team)[]>([]);
  const [professionalInput, setProfessionalInput] = useState<string>("");
  const [isLoadingProfessionals, setIsLoadingProfessionals] = useState<boolean>(false);
  const [openPro, setOpenPro] = useState(false);

  // Pull professionals from current city on city change
  useEffect(() => {
    (async function () {
      if (city) {
        try {
          setIsLoadingProfessionals(true);
          setProfessionalOptions(
            await arianeApi.professionals.searchByNameAndZipCodes(professionalInput, city.zip_code)
          );
          onArianeError(undefined);
        } catch (e) {
          // TODO: report error to error reporting service
          onArianeError(e);
        } finally {
          setIsLoadingProfessionals(false);
        }
      } else {
        // Reset professional input on city deselect
        setProfessionalOptions([]);
        if (professional !== undefined) {
          onProfessionalChange(undefined);
        }
      }
    })();
  }, [arianeApi.professionals, city, professional, onArianeError, onProfessionalChange, professionalInput]);

  return (
    <>
      <Grid item xs={12} md={6}>
        <CitySearchField
          city={city}
          onCityChange={onCityChange}
          splitZipCode
          onProfessionalChange={onProfessionalChange}
          professional={professional}
          cityError={cityError}
          onArianeError={onArianeError}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledAutoComplete
          open={openPro}
          onOpen={() => {
            // only open when in focus and cityInput is not empty
            if (professionalInput) {
              setOpenPro(true);
            }
          }}
          onClose={() => setOpenPro(false)}
          disabled={!city}
          options={professionalOptions}
          value={professional ?? null}
          filterOptions={(x) => x}
          onInputChange={(_event, professionalInput) => {
            setProfessionalInput(professionalInput);
            if (!professionalInput) {
              setOpenPro(false);
            }
          }}
          onChange={(_event, newProfessional) => {
            onProfessionalChange(newProfessional ?? undefined);
            if (!newProfessional) {
              setOpenPro(false);
            }
          }}
          getOptionLabel={(professional) => renderLabel(professional)}
          testId="professional"
          required
          label={t("pro")}
          placeholder={`ex : ${t("pro")}`}
          error={professionalError || undefined}
          loading={isLoadingProfessionals}
          fullWidth
          renderOption={(props, professional) => (
            <li data-testid="professionalOption" {...props}>
              {renderResult(professional)}
            </li>
          )}
          isOptionEqualToValue={isEqual}
        />
      </Grid>
    </>
  );
}

function renderLabel(result: Professional | Team) {
  if (isProfessional(result)) {
    return `${result.first_name} ${result.last_name}`;
  }
  if (isTeam(result)) {
    return result.name;
  }
  return "";
}

function renderResult(result: Professional | Team) {
  if (isProfessional(result)) {
    return renderProfessional(result);
  }
  if (isTeam(result)) {
    return renderTeam(result);
  }
}

function isProfessional(object: object): object is Professional {
  return "first_name" in object;
}

function isTeam(object: object): object is Team {
  return "name" in object;
}

function renderProfessional(professional: Professional): JSX.Element {
  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Typography flex="1 1 auto" fontSize="0.8rem">
        {`${professional.first_name} ${professional.last_name}`}
        <br />
        {professional.address}
        <br />
        {`${professional.zip_code} ${professional.city?.toUpperCase()}`}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <PersonIcon sx={{ color: theme.palette.primary[500] }} />
      </Box>
    </Box>
  );
}

function renderTeam(team: Team): JSX.Element {
  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Typography flex="1 1 auto" fontSize="0.8rem">
        {`${team.name}`}
        <br />
        {team.address}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <GroupsIcon />
      </Box>
    </Box>
  );
}

export function renderCity(city: City): JSX.Element {
  return (
    <>
      <Typography fontSize="0.8rem">{city.department ? `${city.name} (${city.department})` : city.name}</Typography>
    </>
  );
}
