import { HealthRecord } from "@syadem/kairos-pro-js";
import { useEffect, useState } from "react";
import { useApis } from "../providers/Dependencies";
import { useAppContext } from "./useAppContext";

interface UseHealthRecords {
  isLoading: boolean;
  healthRecords?: HealthRecord[];
  error?: Response | Error;
  fetchHealthRecords: () => void;
}

export function useAllHealthRecords(): UseHealthRecords {
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();

  const [healthRecords, setHealthRecords] = useState<HealthRecord[] | undefined>(undefined);
  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const fetchHealthRecords = () => {
    (async () => {
      try {
        // TODO: Wrap this inside a query
        if (organizationId && teamId) {
          const { healthRecords } = await apis.team.healthRecordApi.allHealthRecords(organizationId, teamId)
          setHealthRecords(healthRecords);
        } else {
          const { healthRecords } = await apis.pro.healthRecordApi.allHealthRecords()
          setHealthRecords(healthRecords);
        }
      } catch (error) {
        if (error instanceof Response || error instanceof Error) {
          setError(error);
        } else {
          throw error;
        }
      } finally {
        setIsLoading(false);
      }
    })();
  };

  useEffect(fetchHealthRecords, [apis, organizationId, teamId]);

  return { isLoading, healthRecords, error, fetchHealthRecords };
}
