import { useFormikContext } from "formik";
import { useI18n } from "../../src/ui/hooks/useI18n";
import { Asserts } from "yup";
import { YupHealthRecordSchema } from "../../src/ui/pages/health_record/HealthRecordForm";
import { useArianeApi } from "../../src/ui/hooks/useArianeApi";
import { forwardRef, useEffect } from "react";
import { useCountryConfig } from "../../src/ui/hooks/useCountryConfig";
import { StyledInput } from "../../src/ui/components/mui/StyledInput";
import ReactInputMask from "react-input-mask-format";
import { SecurityNumberInputProps } from "../../src/appConfig";

export function SecurityNumberInput({ isReadonly, translateErrors, openDatepicker }: SecurityNumberInputProps) {
  const { t } = useI18n();
  const { values, getFieldProps, errors, touched, setFieldValue } = useFormikContext<Asserts<YupHealthRecordSchema>>();
  const arianeApi = useArianeApi();
  const { securityNumber } = useCountryConfig();

  useEffect(() => {
    const cleanedValue = values?.externalId?.trim().replaceAll(" ", "").replaceAll("_", "");
    const isValid = cleanedValue && securityNumber.validator(cleanedValue);

    if (isValid && arianeApi?.cities) {
      const genderFromExternalId = retrieveGenderFromExternalId(cleanedValue.slice(0, 1));
      const birthDateFromExternalId = retrieveBirthDateFromExternalId(
        cleanedValue.slice(1, 3),
        cleanedValue.slice(3, 5)
      );
      if (genderFromExternalId !== undefined && !values.gender) {
        setFieldValue("gender", genderFromExternalId);
      }
      if (!values.birthDate) {
        setFieldValue("birthDate", birthDateFromExternalId);
        openDatepicker();
      }

      //Get default city
      arianeApi?.cities.getByCode(cleanedValue.slice(5, 10)).then((data) => {
        if (data?.code) {
          setFieldValue("birthCityCode", data?.code);
        }
      });
    }
  }, [
    errors?.externalId,
    values?.externalId,
    setFieldValue,
    values.birthDate,
    values.gender,
    openDatepicker,
    arianeApi,
    securityNumber
  ]);

  return (
    <>
      <StyledInput
        {...getFieldProps("externalId")}
        fullWidth
        label={t("common.user_infos.social_security_number")}
        placeholder="ex : 1 83 04 33 440 039 91"
        inputComponent={!isReadonly ? (SocialSecurityMaskInput as any) : undefined}
        testId="externalId"
        readOnly={isReadonly}
        error={!!errors.externalId}
        errorMessage={errors.externalId}
        touched={touched.externalId}
        translateErrors={translateErrors}
        value={values.externalId}
      />
    </>
  );
}

function retrieveGenderFromExternalId(genderDigit: string): string | undefined {
  switch (genderDigit) {
    case "1":
      return "M";
    case "2":
      return "F";
    default:
      return undefined;
  }
}

function retrieveBirthDateFromExternalId(yearDigit: string, monthDigit: string): Date {
  const yearFromExternalId = new Date().getFullYear().toString().slice(0, 2) + yearDigit;
  const birthDateFromExternalId = new Date(`${yearFromExternalId}-${monthDigit}-01`);

  if (birthDateFromExternalId > new Date()) {
    birthDateFromExternalId.setFullYear(parseInt(yearFromExternalId) - 100);
  }

  return birthDateFromExternalId;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  value: string;
}
const SocialSecurityMaskInput = forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(props, ref) {
  return (
    <ReactInputMask
      {...props}
      mask="9 99 99 9* 999 999 99"
      onChange={props.onChange}
      value={props.value}
      ref={ref as any}
    />
  );
});
