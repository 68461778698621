import { Backdrop, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { ErrorBoundary } from "../components/error_handling/ErrorBoundary";
import { GlobalFallback } from "../components/error_handling/GlobalFallback";
import { theme } from "./Theme";
import { useAuthenticatedUser } from "../../store";
import Navigator from "../components/left_sidebar/Navigator";

export const drawerWidth = 300;

type OutletContextType = {
  onDrawerToggle: () => void;
};

export function Layout() {
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const currentUser = useAuthenticatedUser();
  const [mobileOpen, setMobileOpen] = useState(false);

  const drawerToggleHandler = () => {
    setMobileOpen(!mobileOpen);
  };

  const outletContext: OutletContextType = {
    onDrawerToggle: drawerToggleHandler,
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!currentUser}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: "flex", minHeight: "100vh", width: "100vw", color: theme.palette.neutral[600] }}>
        <CssBaseline />
        <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { md: 0 } }}>
          {isLgUp ? (
            <Navigator PaperProps={{ style: { width: drawerWidth }}} sx={{ display: { lg: "block", xs: "none" }}} />
          ) : (
            <Navigator
              PaperProps={{ style: { width: drawerWidth }}}
              variant={"temporary"}
              open={mobileOpen}
              onClose={drawerToggleHandler}
            />
          )}
        </Box>
        <ErrorBoundary fallback={GlobalFallback}>
          <Outlet context={outletContext} />
        </ErrorBoundary>
      </Box>
    </>
  );
}

export function useDrawerToggleHandler() {
  return useOutletContext<OutletContextType>().onDrawerToggle;
}
