import { Professional, Team } from "@syadem/ariane-js";
import { Daphne } from "@syadem/daphne-js";
import { HealthRecord } from "@syadem/kairos-pro-js";
import { VaccinationAct as ProVaccinationAct } from "@syadem/kairos-pro-js";
import { VaccinationAct as TeamVaccinationAct } from "@syadem/kairos-team-js";
import { VaccinationActListItem } from "./VaccinationActListItem";

function VaccinationActsByList({
  certificators,
  sortedVaccinationActs,
  healthRecord,
  daphne,
  refreshVaccinations,
  showActions = true,
}: {
  certificators: (Professional | Team)[];
  sortedVaccinationActs: ProVaccinationAct[] | TeamVaccinationAct[];
  healthRecord: HealthRecord;
  daphne: Daphne;
  refreshVaccinations?: () => void;
  showActions?: boolean;
}) {
  return sortedVaccinationActs.map((vaccinationAct) => (
    <VaccinationActListItem
      vaccinationAct={vaccinationAct}
      healthRecord={healthRecord}
      showActions={showActions}
      refreshCallback={refreshVaccinations}
      key={vaccinationAct.id}
      daphne={daphne}
      certificators={certificators}
    />
  ));
}

export default VaccinationActsByList;
