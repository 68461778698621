import { Dayjs } from "dayjs";
import Polyglot from "node-polyglot";
import { $enum } from "ts-enum-util";
import { dayjs } from "./dayjs";

const getAgeString = (
  age: { value: number[]; unit: string[] },
  t: (phrase: string, options?: number | Polyglot.InterpolationOptions | undefined) => string
): string => {
  return age.value.length > 1
    ? `${age.value[0]} ${t(age.unit[0], { smart_count: age.value[0] })} ${t("common.and")} ${age.value[1]} ${t(
        age.unit[1],
        { smart_count: age.value[1] }
      )}`
    : `${age.value[0]} ${t(age.unit[0], { smart_count: age.value[0] })}`;
};

export function utcToday(date: Date = new Date()) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}

export const calculateAge = (
  end: Date,
  begin: Date,
  withPrecision: boolean,
  t: (phrase: string, options?: number | Polyglot.InterpolationOptions | undefined) => string
): string => {
  const daysDiff = dayjs(end).diff(dayjs(begin), "day");
  const weeksDiff = dayjs(end).diff(dayjs(begin), "week");
  const monthsDiff = dayjs(end).diff(dayjs(begin), "month");
  const yearsDiff = dayjs(end).diff(dayjs(begin), "year");

  if (daysDiff <= 28) {
    return getAgeString({ value: [daysDiff], unit: ["common.dates.day"] }, t);
  } else if (weeksDiff <= 7) {
    return getAgeString({ value: [weeksDiff], unit: ["common.dates.week"] }, t);
  } else if (monthsDiff <= 23) {
    const diffWeek = Math.floor((daysDiff - monthsDiff * 30.5) / 7);

    if (withPrecision && diffWeek > 0) {
      return getAgeString({ value: [monthsDiff, diffWeek], unit: ["common.dates.month", "common.dates.week"] }, t);
    } else {
      return getAgeString({ value: [monthsDiff], unit: ["common.dates.month"] }, t);
    }
  } else {
    const diffMonth = monthsDiff - yearsDiff * 12;

    if (withPrecision && diffMonth > 0) {
      return getAgeString({ value: [yearsDiff, diffMonth], unit: ["common.dates.year", "common.dates.month"] }, t);
    } else {
      return getAgeString({ value: [yearsDiff], unit: ["common.dates.year"] }, t);
    }
  }
};

export enum DateUnits {
  Year = "year",
  Month = "month",
  Week = "week",
}

export const DateUnitsValues = $enum(DateUnits).getValues();

export const getAgeNumericAndUnit = (end: Dayjs, begin: Dayjs): { ageNumeric: number | null; ageUnit: DateUnits } => {
  const weeksDiff = end.diff(begin, "week");
  const monthsDiff = end.diff(begin, "month");
  const yearsDiff = end.diff(begin, "year");

  if (weeksDiff <= 4) {
    return { ageNumeric: weeksDiff, ageUnit: DateUnits.Week };
  } else if (monthsDiff <= 12) {
    return { ageNumeric: monthsDiff, ageUnit: DateUnits.Month };
  } else {
    return { ageNumeric: yearsDiff, ageUnit: DateUnits.Year };
  }
};

export const getBirthdayDate = (numeric: number, unit: string) => {
  const today = utcToday();
  switch (unit) {
    case DateUnits.Week:
      today.setDate(today.getDate() - numeric * 7);
      break;
    case DateUnits.Month:
      today.setMonth(today.getMonth() - numeric);
      break;
    case DateUnits.Year:
      today.setFullYear(today.getFullYear() - numeric);
      break;
  }

  return today;
};
