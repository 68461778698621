import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { LinearProgress, Link, Typography } from "@mui/material";
import { Diagnostic } from "@syadem/sad-js";
import { DiagnosticResults } from "@syadem/sad-ui";
import { PatientProfile } from "@syadem/sad-ui/dist/domain/PatientProfile";
import { useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { buildPatientFromHealthRecord } from "../../domain/patient";
import { useDaphne } from "../hooks";
import { useI18n } from "../hooks/useI18n";
import { useSad } from "../hooks/useSadApi";
import { useUnsharedHealthRecord } from "../hooks/useUnsharedHealthRecord";
import { useUnsharedHealthRecordVaccinationActs } from "../hooks/useUnsharedHealthRecordVaccinationActs";
import { theme } from "../layout/Theme";

const MatchingCondition = ({
  conditionId,
  conditionLabelWithValue,
  testId,
}: {
  conditionId: string;
  conditionLabelWithValue: string;
  testId?: string;
}) => {
  const { id } = useParams() as { id: string };

  return (
    <Link
      component={RouterLink}
      to={`/liberal/unshared-health-records/${id}/profile`}
      data-testid={testId ? `${testId}-matchingCondition${conditionId}` : undefined}
      underline="none"
      sx={{
        display: "flex",
        alignItems: "center",
        "&:hover": {
          color: theme.palette.primary[400],
        },
      }}
    >
      <FavoriteBorderIcon sx={{ mr: 1, fontSize: "16px" }} />
      <Typography variant="body2" textAlign="justify">
        {conditionLabelWithValue}
      </Typography>
    </Link>
  );
};

export function UnsharedDiagnostic() {
  const { id } = useParams() as { id: string };
  const { unsharedHealthRecord, isLoading: isLoadingHealthRecords } = useUnsharedHealthRecord(id);
  const daphne = useDaphne();
  const { t, locale } = useI18n();
  const api = useSad();
  const [healthProfile, setHealthProfile] = useState<Diagnostic | undefined>(undefined);

  //TO DO: remove this call when the unsharedHealthRecord will contain the vaccination acts
  const { unsharedVaccinationActs, isLoading: isLoadingVaccinationActs } = useUnsharedHealthRecordVaccinationActs(id);

  // const { diagnostic, isLoading: isLoadingDiagnostic } = useDiagnostic(unsharedHealthRecord);
  // Temp useEffect to remove when the unsharedHealthRecord will contain the vaccination acts. Uncomment the line above and remove the useEffect
  useEffect(() => {
    if (
      unsharedHealthRecord &&
      !isLoadingHealthRecords &&
      unsharedVaccinationActs &&
      !isLoadingVaccinationActs
    ) {
      (async () => {
        const diagnostic = await api.diagnosticForPatient(
          buildPatientFromHealthRecord({
            ...unsharedHealthRecord,
            vaccinationActs: unsharedVaccinationActs,
          })
        );
        setHealthProfile(diagnostic);
      })();
    }
  }, [
    api,
    unsharedHealthRecord,
    isLoadingHealthRecords,
    unsharedVaccinationActs,
    isLoadingVaccinationActs,
    daphne
  ]);

  return (
    <>
      {!healthProfile && <LinearProgress variant="query" />}
      {healthProfile && daphne && (
        <DiagnosticResults
          diagnostic={healthProfile}
          profile={unsharedHealthRecord?.profile as PatientProfile}
          vaccinations={unsharedVaccinationActs}
          daphne={daphne}
          locale={locale}
          injectedComponents={{
            MatchingConditionComponent: MatchingCondition,
          }}
          publicDestination="professional"
          infosMessage={t("recommandations.DiagnosticList.infosMessage")}
        />
      )}
    </>
  );
}
