import { Alert, Snackbar } from "@mui/material";
import { PageLayout } from "../../../layout/PageLayout";
import { useI18n } from "../../../hooks/useI18n";
import { useAuthenticatedUser, useOrganization, useSubscriptionOrganization } from "../../../../store";
import { Organization } from "@syadem/kairos-subscription-js";
import { useEffect, useState } from "react";
import { SubscriptionSummary } from "./SubscriptionSummary";
import { PaymentMethod } from "./PaymentMethod";
import { useQueries } from "../../../providers/Dependencies";
import { SubscriptionQuantity } from "./SubscriptionQuantity";
import { Invoices } from "./Invoices";
import { PromotionCode } from "./PromotionCode";
import { useAppContext } from "../../../hooks/useAppContext";
import { useCountryConfig } from "../../../hooks/useCountryConfig";

export interface SubscriptionComponentProps {
  subscriptionOrganization: Organization;
  setErrorDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export function Subscription() {
  const { t } = useI18n();
  const { organizationId } = useAppContext();
  const subscriptionOrganization = useSubscriptionOrganization(organizationId ?? undefined);
  const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false);
  const [successDisplayed, setSuccessDisplayed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const { allOrganizationsQuery } = useQueries();
  const { subscription } = useCountryConfig();
  const organization = useOrganization(organizationId ?? undefined);
  const currentUser = useAuthenticatedUser();

  useEffect(() => {
    subscription?.queries.subscriptionOrganizationsQuery.call();
  }, [subscription]);

  useEffect(() => {
    allOrganizationsQuery.call();
  }, [allOrganizationsQuery]);
  
  if (subscriptionOrganization && subscription && subscriptionOrganization.subscription.product.id != subscription.defaultProductId) {
    return (
      <PageLayout title={t("subscriptions.title")}>
        <Alert severity="info">
          {t("subscriptions.referToSupport")}
        </Alert>
      </PageLayout>
    )
  }

  if (currentUser && organization && !organization.adminIds?.includes(currentUser.id)) {
    return (
      <PageLayout title={t("subscriptions.title")}>
        <Alert severity="info">
          {t("subscriptions.referToOrganizationAdmin")}
        </Alert>
      </PageLayout>
    )
  }

  return (
    <PageLayout title={t("subscriptions.title")}>
      {subscriptionOrganization && (
        <>
          <SubscriptionSummary
            subscriptionOrganization={subscriptionOrganization}
            setErrorDisplayed={setErrorDisplayed}
            setSuccessDisplayed={setSuccessDisplayed}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
          />
          <PromotionCode
            subscriptionOrganization={subscriptionOrganization}
            setErrorDisplayed={setErrorDisplayed}
            setSuccessDisplayed={setSuccessDisplayed}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
          />
          <PaymentMethod
            subscriptionOrganization={subscriptionOrganization}
            setErrorDisplayed={setErrorDisplayed}
            setSuccessDisplayed={setSuccessDisplayed}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
          />
          <SubscriptionQuantity
            subscriptionOrganization={subscriptionOrganization}
            setErrorDisplayed={setErrorDisplayed}
            setSuccessDisplayed={setSuccessDisplayed}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
          />
          <Invoices subscriptionOrganization={subscriptionOrganization} />
        </>
      )}
      <Snackbar
        open={errorDisplayed}
        autoHideDuration={6000}
        onClose={() => setErrorDisplayed(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Snackbar
        open={successDisplayed}
        autoHideDuration={6000}
        onClose={() => setSuccessDisplayed(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success">{successMessage}</Alert>
      </Snackbar>
    </PageLayout>
  );
}