export function compareNormalizedString(str1: string, str2: string) {
  return str1
    ?.toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .includes(
      str2
        ?.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    );
}
export function replaceSpecialChars(value: string) {
  return value
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace("-", " ")
    .replace(/[\u0300-\u036f]/g, "");
}

export function formatFirstName(name: string) {
  return name
    .split("-")
    .map((word) => word.toLowerCase())
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("-");
}
