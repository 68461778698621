import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, alpha, Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "../../layout/Theme";
import { Organization } from "../../../domain/organization";

export function OrganizationMenu({
  title,
  expanded,
  organization,
  children,
  icon,
  defaultPathname,
}: {
  title: string;
  expanded: boolean;
  organization?: Organization;
  children: ReactNode;
  icon?: ReactNode;
  defaultPathname: string;
}): JSX.Element {
  const navigate = useNavigate();

  return (
    <Accordion
      elevation={0}
      disableGutters
      expanded={expanded}
      sx={{
        "&::before": {
          opacity: 0,
        },
      }}
      onClick={() => !expanded && navigate(defaultPathname)}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              color: organization
                ? expanded
                  ? organization.color
                  : theme.palette.neutral[600]
                : theme.palette.neutral[600],
            }}
          />
        }
        sx={{
          borderRadius: 1,
          pl: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {icon}
          <Typography
            color={
              organization ? (expanded ? organization.color : theme.palette.neutral[600]) : theme.palette.neutral[600]
            }
            variant="body2"
            sx={{ fontWeight: "500" }}
          >
            {title}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0, pt: 0 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              ml: 2,
              pl: 1,
              borderLeft: `1px solid ${alpha(
                organization ? organization.color : (theme.palette.primary[500] as string),
                0.5
              )}`,
            }}
          >
            {children}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
