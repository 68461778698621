import { Alert, Box, Paper, Tooltip, Typography } from "@mui/material";
import { theme } from "../../../layout/Theme";
import { useI18n } from "../../../hooks/useI18n";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Organization } from "@syadem/kairos-subscription-js";

export function SubscriptionRequired(
  { subscriptionOrganizations }:
  { subscriptionOrganizations: { [id: string]: Organization } }
) {
  const { t } = useI18n();

  return (
    <Paper
      sx={{
        maxWidth: "md",
        marginX: "auto",
        overflow: "hidden",
        border: `solid 1px ${theme.palette.neutral[200]}`,
        paddingX: 4,
        paddingY: 2
      }}
      elevation={0}
    >
      <Alert severity="info" sx={{ mb: 2 }}>
        {Object.keys(subscriptionOrganizations).length == 0 ? t("payment.freeTrial") : t("payment.howToSubscribe")}
      </Alert>
      <Typography color={theme.palette.neutral["600"]} mb={2}>{t("payment.description_1")}</Typography>
      <Typography color={theme.palette.neutral["600"]} mb={1}>{t("payment.description_2")}</Typography>
      <Box display="flex" alignItems="center">
        <Typography color={theme.palette.neutral["600"]} mr={1}>{t("payment.description_3")}</Typography>
        <Tooltip title={t("payment.diagnosticDetails")}>
          <HelpOutlineIcon sx={{ color: theme.palette.neutral["600"] }} fontSize="small" />
        </Tooltip>
      </Box>
      <Typography color={theme.palette.neutral["600"]}>{t("payment.description_4")}</Typography>
      <Typography color={theme.palette.neutral["600"]} mb={2}>{t("payment.description_5")}</Typography>
      <Typography color={theme.palette.neutral["600"]} mb={2}>{t("payment.description_6")}</Typography>
      <Typography color={theme.palette.neutral["600"]} mb={1}>{t("payment.description_7")}</Typography>
      <Typography color={theme.palette.neutral["600"]}>{t("payment.description_8")}</Typography>
      <Typography color={theme.palette.neutral["600"]}>{t("payment.description_9")}</Typography>
    </Paper>
  )
}