import { Chip, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useI18n } from "../../../hooks/useI18n";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { Professional, Team } from "@syadem/ariane-js";
import { ProfileConditionsText, ProfileState } from "@syadem/sad-ui";
import { profileCertificationDescription } from "../../../../utils/profileCertificationDescription";
import { useArianeApi, useDaphne } from "../../../hooks";
import { useUnsharedHealthRecord } from "../../../hooks/useUnsharedHealthRecord";
import { theme } from "../../../layout/Theme";

const ShowProfileConditions = ({ state }: { state: ProfileState }) => {
  const { id } = useParams() as { id: string };
  const { unsharedHealthRecord } = useUnsharedHealthRecord(id);
  const { t, locale } = useI18n();
  const daphne = useDaphne();
  const arianeApi = useArianeApi();
  const [certificators, setCertificators] = useState<(Professional | Team)[] | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (unsharedHealthRecord) {
        let actCertificators: (Professional | Team)[] = [];

        if (unsharedHealthRecord.validatorIds.length > 0) {
          try {
            actCertificators = await arianeApi.professionals.searchByIds([
              ...new Set(unsharedHealthRecord.validatorIds),
            ]);
          } catch (error) {
            actCertificators = [];
          }
        }

        setCertificators(actCertificators);
      }
    })();
  }, [arianeApi?.professionals, unsharedHealthRecord]);

  return (
    <>
      {unsharedHealthRecord?.firstNames && unsharedHealthRecord?.lastName && t && daphne && (
        <>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container>
                <Typography
                  fontWeight="bold"
                  sx={{ mb: 2, maxWidth: "240px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                >
                  {t("profile.conditionsTitle", {
                    firstnames: unsharedHealthRecord?.firstNames,
                    lastname: unsharedHealthRecord?.lastName,
                  })}
                </Typography>
                {unsharedHealthRecord.validatorIds.length > 0 && certificators && certificators.length > 0 && (
                  <Tooltip title={profileCertificationDescription(certificators, unsharedHealthRecord, t)}>
                    <Chip
                      label={t("profile.validated")}
                      color="success"
                      size="small"
                      sx={{ fontWeight: "500", ml: 1, border: `1px solid ${theme.palette.success[200]}` }}
                      icon={<TaskAltOutlinedIcon fontSize="small" />}
                    />
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
          <ProfileConditionsText profileConditions={state.conditionsProfile} daphne={daphne} locale={locale} disabled />
        </>
      )}
    </>
  );
};

export default ShowProfileConditions;
