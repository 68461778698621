import { Organization as ApiOrganization } from "@syadem/kairos-team-js";
import { Organization } from "../../domain/organization";

export function parseApiOrganization(apiOrganization: ApiOrganization): Organization {
  return {
    id: apiOrganization.id,
    name: apiOrganization.name,
    color: apiOrganization.color,
    address: apiOrganization.address,
    city: apiOrganization.city,
    zipCode: apiOrganization.zipCode,
    phoneNumber: apiOrganization.phoneNumber,
    adminIds: apiOrganization.adminIds
  }
}