import { FormControl, FormLabel, RadioGroup } from "@mui/material";
import { theme } from "../../layout/Theme";

interface StyledRadioGroupProps {
  label?: string;
  groupName?: string;
  fullWidth?: boolean;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  children: React.ReactNode;
  value?: any;
  testId?: string;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void) | undefined;
}

export const StyledRadioGroup = ({
  label,
  groupName,
  fullWidth,
  error,
  disabled,
  required,
  children,
  value,
  testId = "",
  onChange,
}: StyledRadioGroupProps) => {
  return (
    <FormControl
      variant="standard"
      error={error}
      fullWidth={!!fullWidth}
      disabled={disabled}
      required={required}
      component="fieldset"
    >
      {label && (
        <FormLabel
          sx={{
            color: theme.palette.neutral[600],
            fontSize: 14,
            fontWeight: 600,
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            transition: "color 0.2s ease-in-out",
            transform: "none",
            ".MuiFormLabel-asterisk": {
              color: theme.palette.error[500],
            },
            mb: 1.3,
          }}
          data-testid={`${testId}Label`}
        >
          {label}
        </FormLabel>
      )}
      <RadioGroup
        value={value}
        onChange={onChange}
        name={groupName}
        sx={{
          ".MuiRadio-root": {
            py: 0.5,
            color: theme.palette.neutral[400],
            "&.Mui-checked": {
              color: theme.palette.primary.main,
            },
          },
          ".MuiFormControlLabel-root": {
            "&.Mui-disabled": {
              color: theme.palette.neutral[500],
              ".MuiRadio-root": {
                "&.Mui-checked": {
                  color: theme.palette.neutral[500],
                },
              },
            },
          },
        }}
        data-testid={`${testId}RadioGroup`}
      >
        {children}
      </RadioGroup>
    </FormControl>
  );
};
