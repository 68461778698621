import { useMemo } from "react";
import { useTeams } from "../../store";
import { Team } from "../../domain/team";
import { useAppContext } from "./useAppContext";

interface UseCurrentTeam {
  team?: Team;
}

export function useCurrentTeamInfos(): UseCurrentTeam {
  const { teamId } = useAppContext();
  const teams = useTeams();

  const currentTeam = useMemo(() => {
    if (teamId && teams) {
      return teams[teamId];
    }
  }, [teams, teamId]);

  return { team: currentTeam };
}
