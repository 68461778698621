import { useEffect, useState } from "react";
import { getTranslatedFieldByLocale } from "../../utils/translatedFields";
import { useDaphne } from "./useDaphne";
import { useI18n } from "./useI18n";
import { VaccineWithDiseases, getDiseasesNames } from "./useVaccinesWithDiseases";

export function useVaccineWithDiseases(vaccineId: string | undefined): VaccineWithDiseases | undefined {
  const [vaccineWithDiseases, setVaccineWithDiseases] = useState<VaccineWithDiseases | undefined>(undefined);
  const daphne = useDaphne();
  const { locale } = useI18n();

  useEffect(() => {
    if (daphne?.repositories?.vaccines) {
      if(vaccineId){
        const vaccine = daphne.repositories.vaccines.find(vaccineId);
        if (vaccine) {
          const diseases = daphne.queries.diseasesByVaccine(vaccine);
          const diseasesName = getDiseasesNames(diseases, locale);
          setVaccineWithDiseases({
            vaccine,
            diseases,
            vaccineNameWithDiseases: `${getTranslatedFieldByLocale(locale, vaccine.name)}${
              diseasesName ? ` (${diseasesName})` : ""
            }`,
          });
        }
      }
      else {
        setVaccineWithDiseases(undefined);
      }
    }
  }, [daphne, vaccineId, locale]);
  return vaccineWithDiseases;
}
