/* eslint-disable @typescript-eslint/ban-types */
import { Box, Typography } from "@mui/material";
import { theme } from "../../layout/Theme";

export interface EmptyListPlaceholderProps {
  icon?: React.ReactNode;
  title: string;
  button?: React.ReactNode;
  testId?: string;
  children?: React.ReactNode;
}

export const EmptyListPlaceholder = (props: EmptyListPlaceholderProps) => {
  const { icon, title, button, testId, children, ...rest } = props;

  return (
    <Box
      sx={{
        textAlign: "center",
        ".icon": {
          height: "40px !important",
          color: theme.palette.primary[500],
        },
        padding: { xs: 4, sm: 6 },
        border: `solid 1px ${theme.palette.neutral[200]}`,
        borderRadius: 1,
        backgroundColor: "background.paper",
        mt: 1,
      }}
      data-testid={testId}
      {...rest}
    >
      {icon && (
        <Box
          sx={{
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: theme.palette.primary[100],
            margin: "auto",
            marginBottom: 2,
          }}
        >
          {icon}
        </Box>
      )}

      {title && (
        <Typography fontWeight="bold" variant="h6" gutterBottom component="div">
          {title}
        </Typography>
      )}

      <Typography variant="body1" gutterBottom>
        {children}
      </Typography>

      {button && (
        <Box
          sx={{
            marginTop: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {button}
        </Box>
      )}
    </Box>
  );
};
