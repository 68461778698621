import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { LinearProgress, Link, Typography } from "@mui/material";
import { SubscriptionStatusEnum } from "@syadem/kairos-subscription-js";
import { DiagnosticResults } from "@syadem/sad-ui";
import { PatientProfile } from "@syadem/sad-ui/dist/domain/PatientProfile";
import { some } from "lodash-es";
import { useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useSubscriptionOrganizations } from "../../../../store";
import { useDaphne } from "../../../hooks";
import { useDiagnostic } from "../../../hooks/useDiagnostic";
import { useHealthRecord } from "../../../hooks/useHealthRecord";
import { useI18n } from "../../../hooks/useI18n";
import { theme } from "../../../layout/Theme";
import { SubscriptionRequired } from "../../organization/subscription/SubscriptionRequired";
import { useAppContext } from "../../../hooks/useAppContext";
import { useCountryConfig } from "../../../hooks/useCountryConfig";

const MatchingCondition = ({
  conditionId,
  conditionLabelWithValue,
  testId,
}: {
  conditionId: string;
  conditionLabelWithValue: string;
  testId?: string;
}) => {
  const { id } = useParams() as { id: string };
  const { organizationId, teamId } = useAppContext();
  return (
    <Link
      component={RouterLink}
      to={
        organizationId && teamId
          ? `/organizations/${organizationId}/teams/${teamId}/health-records/${id}/profile`
          : `/liberal/health-records/${id}/profile`
      }
      data-testid={testId ? `${testId}-matchingCondition${conditionId}` : undefined}
      underline="none"
      sx={{
        display: "flex",
        alignItems: "center",
        "&:hover": {
          color: theme.palette.primary[400],
        },
      }}
    >
      <FavoriteBorderIcon sx={{ mr: 1, fontSize: "16px" }} />
      <Typography variant="body2" textAlign="justify">
        {conditionLabelWithValue}
      </Typography>
    </Link>
  );
};

export function Diagnostic() {
  const { id } = useParams() as { id: string };
  const { healthRecord } = useHealthRecord(id);
  const { diagnostic } = useDiagnostic(healthRecord);
  const daphne = useDaphne();
  const { t, locale } = useI18n();
  const { subscription } = useCountryConfig();
  const subscriptionOrganizations = useSubscriptionOrganizations();

  useEffect(() => {
    if (subscription) {
      subscription.queries.subscriptionOrganizationsQuery.call();
    }
  }, [subscription]);

  if (
    subscriptionOrganizations && (
      Object.keys(subscriptionOrganizations).length == 0 ||
      !some(
        Object.entries(subscriptionOrganizations),
        ([_, o]) =>
          o.subscription.status == SubscriptionStatusEnum.Active ||
          o.subscription.status == SubscriptionStatusEnum.Trialing
      )
    )
  ) {
    return <SubscriptionRequired subscriptionOrganizations={subscriptionOrganizations} />;
  }

  return (
    <>
      {!diagnostic && <LinearProgress variant="query" />}
      {diagnostic && daphne && (
        <DiagnosticResults
          diagnostic={diagnostic}
          // We are forced to cast here because kairos-proto does not provide a proper type
          // for healthRecord.profile
          profile={healthRecord?.profile as PatientProfile}
          vaccinations={healthRecord?.vaccinationActs}
          daphne={daphne}
          locale={locale}
          injectedComponents={{
            MatchingConditionComponent: MatchingCondition,
          }}
          publicDestination="professional"
          infosMessage={t("recommandations.DiagnosticList.infosMessage")}
        />
      )}
    </>
  );
}
