import { RecordSharingApiInterface as ProRecordSharingApiInterface } from "@syadem/kairos-pro-js";
import { RecordSharingApiInterface as TeamRecordSharingApiInterface } from "@syadem/kairos-team-js";
import { Service } from "./service";
import { Success } from "../actionResult";

interface Payload {
  healthRecordId: string;
  email: string;
  organizationId: string | null;
  teamId: string | null;
}

export class ShareWithCitizenByMailService implements Service<Payload, null> {
  constructor(
    private readonly proRecordSharingApi: ProRecordSharingApiInterface,
    private readonly teamRecordSharingApi: TeamRecordSharingApiInterface
  ) {}

  async call({ healthRecordId, email, organizationId, teamId }: Payload): Promise<Success<null>> {
    if (organizationId && teamId) {
      await this.teamRecordSharingApi.sendSharingTokenToCitizenByMail(organizationId, teamId, healthRecordId, { email });
    } else {
      await this.proRecordSharingApi.sendSharingTokenToCitizenByMail(healthRecordId, { email });

    }

    return {
      type: "success",
      value: null
    };
  }
}
