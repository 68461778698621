import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { AppBar, IconButton, InputAdornment, TextField, Toolbar } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";

interface HealthRecordSearchBarProps {
  searchValue: string | undefined;
  setSearchValue: Dispatch<SetStateAction<string | undefined>>;
}

export default function HealthRecordSearchBar({ setSearchValue, searchValue }: HealthRecordSearchBarProps) {
  const { t } = useI18n();

  const handleSearch: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <AppBar position="static" color="default" elevation={0} sx={{ backgroundColor: "inherit" }}>
      <Toolbar
        sx={{ border: `solid 1px ${theme.palette.neutral[300]}`, borderRadius: 1, backgroundColor: "background.paper" }}
      >
        <TextField
          fullWidth
          placeholder={t("common.user_infos.placeholder")}
          value={searchValue || ""}
          InputProps={{
            disableUnderline: true,
            sx: {
              fontSize: "default",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setSearchValue(undefined)}
                  aria-label="delete search"
                  data-testid="deleteSearch"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="inherit" sx={{ display: "block" }} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
          variant="standard"
        />
      </Toolbar>
    </AppBar>
  );
}
