import React from "react";

const IconHospital = ({ color, style }: { color?: string; style?: React.CSSProperties | undefined }) => {
  return (
    <svg viewBox="0 0 16 16" color={color} style={{ ...style, fill: color }}>
      <g>
        <path d="M12.6 7.6c-.4 0-.8.4-.8.8v5.1H4.2V8.4c0-.4-.4-.8-.8-.8s-.8.4-.8.8v5.1c0 .9.7 1.6 1.6 1.6h7.6c.9 0 1.6-.7 1.6-1.6V8.4c0-.4-.3-.8-.8-.8z"></path>
        <path d="M14.9 7L8.5 1.1c-.3-.3-.8-.3-1.1 0L1.1 7c-.3.3-.3.8 0 1.1.3.3.8.3 1.1 0L8 2.8l5.8 5.4c.1.1.3.2.5.2s.4-.1.6-.3c.1-.2.2-.4.2-.6 0-.2-.1-.4-.2-.5z"></path>
        <path d="M8.3 6.8h-.6c-.3 0-.5.2-.5.5v1.2H6c-.3 0-.5.2-.5.5v.7c0 .3.2.5.5.5h1.2v1.2c0 .3.2.5.5.5h.7c.3 0 .5-.2.5-.5v-1.2H10c.3 0 .5-.2.5-.5v-.8c0-.3-.2-.5-.5-.5H8.8V7.3c0-.3-.2-.5-.5-.5z"></path>
      </g>
    </svg>
  );
};

export default IconHospital;
