import {
  alpha,
  Autocomplete,
  AutocompleteProps,
  FormControl,
  FormHelperText,
  InputLabel,
  Popper,
  PopperProps,
  styled,
} from "@mui/material";
import React from "react";
import { ErrorI18nObject, TranslateFunction } from "../../../utils/formUtils";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";
import { BootstrapInput, StyledInput } from "./StyledInput";

export interface StyledAutoCompleteProps<T> extends Omit<AutocompleteProps<T, false, false, false>, "renderInput"> {
  id?: string;
  error?: boolean;
  required?: boolean;
  label?: string;
  name?: string;
  errorMessage?: string;
  touched?: boolean;
  translateErrors?: TranslateFunction;
  testId?: string;
  placeholder?: string;
}

const StyledPopper = styled(Popper)<PopperProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
  borderRadius: 4,
  zIndex: 10000,
  ".MuiPaper-root": {
    background: "white",
    maxHeight: "400px",
  },

  ".MuiAutocomplete-noOptions": {
    fontSize: 14,
  },
  ".MuiAutocomplete-loading": {
    fontSize: 14,
  },
  ".MuiAutocomplete-listbox": {
    overflow: "initial",
  },

  "&.error": {
    borderColor: theme.palette.error[600],
    boxShadow: `${alpha(theme.palette.error[600] as string, 0.25)} 0 0 0 0.2rem`,

    ".MuiPaper-root, .group .MuiBox-root": {
      background: theme.palette.error[100],
      color: theme.palette.error[400],
    },
    ".MuiSvgIcon-root": {
      color: theme.palette.error[400],
    },
    ".MuiAutocomplete-noOptions": {
      color: theme.palette.error[400],
    },
    ".MuiAutocomplete-loading": {
      color: theme.palette.error[400],
    },
  },
}));

const StyledAutoComplete = <T,>({
  id,
  error,
  fullWidth,
  disabled,
  required,
  label,
  value,
  name,
  placeholder,
  errorMessage,
  touched,
  translateErrors,
  readOnly = false,
  testId = "",
  ...rest
}: StyledAutoCompleteProps<T>) => {
  const { t } = useI18n();

  return !readOnly ? (
    <FormControl variant="standard" error={error} fullWidth={!!fullWidth} disabled={disabled} required={required}>
      {label && (
        <InputLabel
          shrink
          htmlFor={`${id}Input`}
          sx={{
            color: theme.palette.neutral[600],
            fontSize: 14,
            fontWeight: 600,
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            transition: "color 0.2s ease-in-out",
            transform: "none",
            ".MuiInputLabel-asterisk": {
              color: theme.palette.error[500],
            },
            "&.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.38)",
              ".MuiInputLabel-asterisk": {
                color: "rgba(0, 0, 0, 0.38)",
              },
            },
          }}
          data-testid={`${testId || name || id}Label`}
        >
          {label}
        </InputLabel>
      )}
      <Autocomplete
        {...rest}
        disabled={disabled}
        noOptionsText={t("common.errors.noResults")}
        loadingText={t("common.loading")}
        sx={{
          ".MuiInputBase-root": {
            pr: "0 !important",
            "&.Mui-disabled": {
              ".MuiAutocomplete-endAdornment button svg": {
                color: "rgba(0, 0, 0, 0.38)",
              },
            },
          },
          marginTop: theme.spacing(3),
          ".MuiAutocomplete-endAdornment button": {
            color: error ? theme.palette.error[400] : "inherit",
          },
          backgroundColor: "background.paper",
        }}
        PopperComponent={(params) => (
          <StyledPopper
            {...params}
            modifiers={[
              {
                name: "offset",
                options: {
                  offset: [0, 12],
                },
              },
            ]}
            className={error ? "error" : ""}
          />
        )}
        value={value}
        renderInput={(params) => (
          <BootstrapInput
            {...params.InputProps}
            data-testid={`${testId || name || id}`}
            inputProps={{ ...params.inputProps, "data-testid": `${testId || name || id}Input` }}
            fullWidth={!!fullWidth}
            id={`${id}Input`}
            name={name}
            size="small"
            placeholder={placeholder}
          />
        )}
        data-testid={`${testId || name || id}Autocomplete`}
      />
      {!readOnly && error && errorMessage && touched && translateErrors && (
        <FormHelperText data-testid={`${testId || name || id}Error`}>
          {translateErrors(errorMessage as unknown as ErrorI18nObject)}
        </FormHelperText>
      )}
    </FormControl>
  ) : (
    <StyledInput
      id={id || name}
      fullWidth={!!fullWidth}
      size="small"
      value={
        value && typeof value === "object" && rest.getOptionLabel
          ? rest.getOptionLabel(value)
          : (value as unknown as string) || ""
      }
      label={label}
      placeholder={placeholder}
      readOnly
      required={required}
      name={name || id || ""}
      disabled={disabled}
      testId={testId || name || id}
    />
  );
};
export default React.memo(StyledAutoComplete) as typeof StyledAutoComplete;
