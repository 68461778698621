import { Disease, Vaccine, VaccineDistribution } from "@syadem/daphne-js";
import { sortBy } from "lodash-es";
import { useMemo, useState } from "react";
import { dayjs } from "../../utils/dayjs";
import { getTranslatedFieldByLocale } from "../../utils/translatedFields";
import { useDaphne } from "./useDaphne";
import { useI18n } from "./useI18n";

export interface VaccineWithDiseases {
  vaccine: Vaccine;
  distributed?: boolean;
  firstDistributionDateText?: string;
  diseases: Disease[];
  diseasesName?: string;
  distribution?: VaccineDistribution;
  vaccineNameWithDiseases: string;
}

export function getDiseasesNames(diseases: Disease[] | undefined, locale: string) {
  if (!diseases) {
    return "";
  }
  // Sort diseases name for specific diseases ROR and DDTPcoq
  const sortedArray: Disease[] = [];
  const specificDiseases = ["Rougeole", "Oreillons", "Rubéole", "Diphtérie", "Tétanos", "Poliomyélite", "Coqueluche"];
  //Get first the specific diseases in order
  specificDiseases.forEach((value: string) => {
    const specificDisease = diseases.find((disease: Disease) => disease.name["fr"] === value);
    if (specificDisease) {
      sortedArray.push(specificDisease);
    }
  });
  //Get others diseases
  const others = diseases.filter((disease: Disease) =>
    disease.name["fr"] ? !specificDiseases.includes(disease.name["fr"]) : diseases
  );
  //Join all diseases with sorted specific ones first
  const allDiseases = [...(sortedArray || []), ...(others || [])];
  //Concat names
  return allDiseases.map((d) => getTranslatedFieldByLocale(locale, d.name)).join(", ");
}

export function useVaccinesWithDiseases(
  keyword?: string,
  performedOn?: Date
): {
  vaccinesWithDiseases: VaccineWithDiseases[];
  isLoading: boolean | undefined;
} {
  const { t, locale } = useI18n();
  const daphne = useDaphne();
  const [isLoading, setIsLoading] = useState(true);
  const countryId = import.meta.env.VITE_COUNTRY_ID || "";

  return useMemo(() => {
    if (!daphne) {
      return { vaccinesWithDiseases: [], isLoading: true };
    }

    function getVaccinesWithDiseases() {
      setIsLoading(true);
      const vaccines = daphne
        ? sortBy(daphne.queries.searchVaccinesForArea(keyword || "", countryId, performedOn || undefined), (v) =>
            getTranslatedFieldByLocale(locale as string, v.name)
          )
        : [];

      const vaccinesWithDiseases = vaccines.map((vaccine) => {
        const diseases = daphne?.queries.diseasesByVaccine(vaccine);
        const diseasesName = getDiseasesNames(diseases, locale);
        const distribution = vaccine.distributions.find((distribution) => distribution.areaId === countryId);

        return {
          vaccine,
          distributed: vaccine.distributed,
          firstDistributionDateText: distribution?.startsOn
            ? t("vaccines.distributionDate", { date: dayjs(distribution?.startsOn).locale(locale).format("L") })
            : "",
          diseases: diseases || [],
          diseasesName,
          distribution,
          vaccineNameWithDiseases: `${getTranslatedFieldByLocale(locale, vaccine.name)}${
            diseasesName ? ` (${diseasesName})` : ""
          }`,
        };
      });

      setIsLoading(false);
      return vaccinesWithDiseases;
    }

    return {
      vaccinesWithDiseases: getVaccinesWithDiseases().sort((a, b) => {
        if (a.distributed && !b.distributed) return -1;
        if (!a.distributed && b.distributed) return 1;
        return 0;
      }),
      isLoading,
    };
  }, [daphne, locale, isLoading, keyword, performedOn, t, countryId]);
}
