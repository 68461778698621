import { TeamApiInterface } from "@syadem/kairos-team-js";
import { parseApiTeam } from "../network/parsers/parseApiTeam";
import { Service } from "./service";
import { Success } from "../actionResult";
import { AppStore } from "../store";
import { Team } from "../domain/team";

interface Payload {
  team: {
    visible: boolean;
  };
  organizationId: string;
  teamId: string;
}
export class UpdateTeamVisibilityService implements Service<Payload, Team> {
  constructor(
    private readonly store: AppStore,
    private readonly teamApi: TeamApiInterface
  ) {}

  async call({ team, organizationId, teamId }: Payload): Promise<Success<Team>> {
    const apiTeam = (await this.teamApi.updateTeamVisibility(organizationId, teamId, { team })).team;
    const domainTeam = parseApiTeam(apiTeam, organizationId);

    this.store.setState((state) => {
      const storedTeams = state.teams || {};

      return {
        teams: {
          ...storedTeams,
          [domainTeam.id]: {
            ...(storedTeams[domainTeam.id] ?? {}),
            ...domainTeam
          }
        }
      }
    });

    return {
      type: "success",
      value: domainTeam
    };
  }
}
