import { Subscription, SubscriptionApiInterface } from "@syadem/kairos-subscription-js";
import { Service } from "./service";
import { AppStore } from "../store";
import { Success } from "../actionResult";

interface Payload {
  subscription: {
    promotionCode: string;
  };
  organizationId: string;
}

export class ApplySubscriptionDiscountService implements Service<Payload, Subscription> {
  constructor(
    private readonly store: AppStore,
    private readonly subscriptionApi: SubscriptionApiInterface
  ) {}

  async call({ subscription, organizationId }: Payload): Promise<Success<Subscription>> {
    const apiSubscription = await this.subscriptionApi.applySubscriptionDiscount(organizationId, { subscription });

    this.store.setState((state) => {
      if (state.subscriptionOrganizations && state.subscriptionOrganizations[organizationId]) {
        return {
          ...state,
          subscriptionOrganizations: {
            ...state.subscriptionOrganizations,
            [organizationId]: {
              ...state.subscriptionOrganizations[organizationId],
              subscription: apiSubscription
            }
          }
        };
      } else {
        return {
          ...state,
          subscriptionOrganizations: {
            ...(state.subscriptionOrganizations || {}),
            [organizationId]: {
              id: organizationId,
              invoices: [],
              defaultPaymentMethod: null,
              subscription: apiSubscription
            }
          }
        };
      }
    })

    return {
      type: "success",
      value: apiSubscription
    };
  }
}
