import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Button, Divider, Link, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import { LanguageSelector } from "../LanguageSelector";
import { useI18n } from "../../hooks/useI18n";
import { MainMenu } from "./MainMenu";
import { theme } from "../../layout/Theme";
import { useAuthenticatedUser } from "../../../store";
import { useServiceBus } from "../../hooks/useServiceBus";
import { useCallback } from "react";

export default function Navigator(props: DrawerProps) {
  const { ...other } = props;
  const serviceBus = useServiceBus();
  const { t } = useI18n();
  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));
  const currentUser = useAuthenticatedUser();

  const handleSignout = useCallback(() => {
    serviceBus.dispatch({ type: "signOut" });
  }, [serviceBus]);

  return (
    <Drawer variant="permanent" {...other} sx={{ ".MuiDrawer-paper": { border: "none" } }}>
      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        sx={{ borderRight: `solid 1px ${theme.palette.neutral[200]}` }}
      >
        <Box display="flex" flex="1 1 auto" overflow="auto">
          <MainMenu />
        </Box>
        <Box sx={{ p: 2, borderTop: `solid 1px ${theme.palette.neutral[300]}` }}>
          {breakpointSm && (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
              <LanguageSelector width="100%" />
              <Divider sx={{ width: "100%", my: 2 }} />
              <div>
                <Link
                  href="https://support-cvnpro.mesvaccins.net"
                  target="_blank"
                  sx={{
                    textDecoration: "none",
                    display: "inline-flex",
                    alignItems: "center",
                    color: theme.palette.neutral[600],
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  <HelpCenterOutlinedIcon sx={{ mr: 2 }} />
                  {t("common.helpCenter")}
                </Link>
                <Link
                  href={`https://support-cvnpro.mesvaccins.net/hc/fr/requests/new?tf_anonymous_requester_email=${
                    currentUser?.email
                  }${currentUser?.mobileNumber ? `&tf_13137831594129=${currentUser?.mobileNumber}` : ""}`}
                  target="_blank"
                  sx={{
                    textDecoration: "none",
                    display: "inline-flex",
                    alignItems: "center",
                    color: theme.palette.neutral[600],
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  <MailOutlineIcon sx={{ mr: 2 }} />
                  {t("common.createHelpTicket")}
                </Link>
              </div>
            </Box>
          )}
          <Button
            fullWidth
            variant="outlined"
            onClick={handleSignout}
            sx={{ padding: "8px", borderRadius: "5px", mt: { xs: 3, md: 0 } }}
            startIcon={<LogoutIcon />}
            color="error"
          >
            <div style={{ margin: "3.25px 0" }}>{t("common.cta.logout")}</div>
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
