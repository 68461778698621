import { Box, Button, Paper, Typography } from "@mui/material";
import { useI18n } from "../../../hooks/useI18n";
import { NoteForm } from "./NoteForm";
import { theme } from "../../../layout/Theme";
import { NotesList } from "./NotesList";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from "react-router-dom";
import { useHealthRecord } from "../../../hooks/useHealthRecord";
import { useState } from "react";

export function Notes() {
  const { t } = useI18n();
  const { id } = useParams() as { id: string };
  const { healthRecord } = useHealthRecord(id);
  const [notesDisplayed, setNotesDisplayed] = useState<boolean>(false);

  return (
    <Box mb={4}>
      <Button
        variant="text"
        endIcon={
          notesDisplayed ?
            <ExpandMoreIcon sx={{ color: theme.palette.neutral[600] }} /> :
            <NavigateNextIcon sx={{ color: theme.palette.neutral[600] }} />
        }
        sx={{ mb: notesDisplayed ? 2 : 0, p: 0 }}
        onClick={() => notesDisplayed ? setNotesDisplayed(false) : setNotesDisplayed(true)}
      >
        <Typography
          fontWeight="bold"
          sx={{ 
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: theme.palette.neutral[600]
          }}
        >
          {
            healthRecord && healthRecord.notes.length > 0 ?
              `${t("notes.title")} (${healthRecord.notes.length})` :
              t("notes.addANote")
          }
        </Typography>
      </Button>
      {notesDisplayed && (
        <Paper
          sx={{
            overflow: "hidden",
            backgroundColor: "background.paper",
            border: `solid 1px ${theme.palette.neutral[200]}`,
            maxWidth: "900px",
            p: 2
          }}
          elevation={0}
        >
          <NotesList />
          <NoteForm />
        </Paper>
      )}
    </Box>
  )
}