import { Service } from "./service";
import { Success } from "../actionResult";
import { UserManager } from "oidc-client-ts";
import { buildAccountLinkingUrl } from "../infrastructure/account_linking/build_psc_account_linking_url";

interface Payload {
  uuid: string;
}

export class BuildPscAccountLinkingUrlService implements Service<Payload, URL> {
  constructor(
    private readonly window: Window,
    private readonly oidcManager: UserManager
  ) {}

  async call({ uuid }: Payload): Promise<Success<URL>> {
    const sessionState = (await this.oidcManager.getUser())?.session_state;

    const url = await buildAccountLinkingUrl(
      new URL(this.oidcManager.settings.authority),
      "professional",
      this.oidcManager.settings.client_id,
      "pro-sante-connect",
      sessionState ?? "",
      uuid,
      window.location.href
    )
    
    return {
      type: "success",
      value: url
    };
  }
}