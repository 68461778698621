export const IconMedicalInstrumentSyringe = ({
  height,
  width,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      style={{
        height: height || "20px",
        width: width || "auto",
        display: "inline-block",
        fill: "currentcolor",
        maxWidth: "100%",
        position: "relative",
        userSelect: "none",
        verticalAlign: "bottom",
        transition: "fill 0.2s ease-in-out",
      }}
      viewBox={`0 0 16 18`}
      className="iconSyringe"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.908 3.856c.199 0 .39.084.53.233l3.092 3.285a.832.832 0 010 1.126l-5.927 6.313a.771.771 0 01-.09.081 1.558 1.558 0 01-2.026.08.758.758 0 01-.165-.133l-1.86-1.977-.006-.006a1.814 1.814 0 01-.077-2.366.78.78 0 01.07-.087L8.377 4.09a.728.728 0 01.53-.234zM5.6 13.625l5.34-5.687L8.909 5.78l-5.345 5.695a.788.788 0 01-.05.058.146.146 0 00.002.198l.006.006 1.747 1.856a.764.764 0 01.097.087.125.125 0 00.18-.005.752.752 0 01.054-.05z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.076 13.133a.833.833 0 010 1.127L2.53 15.902a.72.72 0 01-1.06 0 .832.832 0 010-1.127l1.545-1.642a.72.72 0 011.061 0zM7.47 3.124a.72.72 0 011.06 0l4.947 5.256a.833.833 0 010 1.127.72.72 0 01-1.06 0L7.47 4.25a.833.833 0 010-1.127z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.783 2.631a.72.72 0 011.06 0l2.166 2.3a.833.833 0 010 1.128l-1.895 2.013a.72.72 0 01-1.06 0l-2.166-2.3a.833.833 0 010-1.128l1.895-2.013zm.53 1.69l-.834.887 1.105 1.174.834-.887-1.104-1.174z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.31 2.098a.72.72 0 011.06 0l3.092 3.285a.833.833 0 010 1.127.72.72 0 01-1.06 0L10.31 3.225a.833.833 0 010-1.127zM7.145 8.782a.72.72 0 011.061 0l1.237 1.314a.833.833 0 010 1.127.72.72 0 01-1.06 0L7.144 9.909a.833.833 0 010-1.127zM5.29 10.76a.72.72 0 011.061 0l1.237 1.313a.833.833 0 010 1.127.72.72 0 01-1.06 0L5.29 11.886a.832.832 0 010-1.127z"
        />
      </g>
    </svg>
  );
};
