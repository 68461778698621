import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, Link, Skeleton, Tab, Tabs, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Outlet, Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { calculateAge, utcToday } from "../../utils/ageCalculator";
import { getFullName } from "../../utils/healthRecord";
import { useI18n } from "../hooks/useI18n";
import { useUnsharedHealthRecord } from "../hooks/useUnsharedHealthRecord";
import { PageLayout } from "../layout/PageLayout";
import { theme } from "../layout/Theme";
import { dayjsWithLocale } from "../../utils/dayjs";

export function UnsharedHealthRecord() {
  const { t, locale } = useI18n();

  const { id } = useParams() as { id: string };
  const { unsharedHealthRecord } = useUnsharedHealthRecord(id);
  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <PageLayout
      title={
        breakpointMd ? (
          <Box sx={{ ml: 1 }}>
            <Link
              underline="none"
              color={theme.palette.primary.main}
              to="/liberal/unshared-health-records"
              component={RouterLink}
              sx={{
                "&:hover": {
                  color: theme.palette.primary[300],
                },
              }}
            >
              <Typography variant="h6">{t("common.interface.unshared_health_records")}</Typography>
            </Link>
            <Typography
              variant="h6"
              sx={{
                fontSize: 16,
                maxWidth: "240px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textTransform: "capitalize",
              }}
            >
              {unsharedHealthRecord ? (
                <>
                  {getFullName({
                    firstNames: unsharedHealthRecord.firstNames,
                    lastName: unsharedHealthRecord.lastName,
                    customaryName: unsharedHealthRecord.customaryName,
                    gender: unsharedHealthRecord.gender,
                    t,
                  })}
                </>
              ) : (
                <Skeleton variant="text" width={150} />
              )}
            </Typography>
            <Typography variant={"subtitle2"} component="span" sx={{ color: theme.palette.neutral[500], fontSize: 14 }}>
              {unsharedHealthRecord ? (
                `${dayjsWithLocale(unsharedHealthRecord.birthDate, locale)} (${calculateAge(
                  utcToday(),
                  unsharedHealthRecord.birthDate,
                  true,
                  t
                )})`
              ) : (
                <Skeleton variant="text" width={150} />
              )}
            </Typography>
          </Box>
        ) : (
          <>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ color: theme.palette.neutral[600], fontWeight: "bold" }}
              data-testid="breadcrumbs"
              separator={<NavigateNextIcon fontSize={breakpointSm ? "small" : "medium"} />}
            >
              <Link
                underline="none"
                color={theme.palette.primary.main}
                to="/liberal/unshared-health-records"
                component={RouterLink}
                sx={{
                  "&:hover": {
                    color: theme.palette.primary[300],
                  },
                }}
              >
                <Typography variant={breakpointSm ? "h6" : "h5"}>
                  {t("common.interface.unshared_health_records")}
                </Typography>
              </Link>
              <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <Typography
                  variant={breakpointSm ? "h6" : "h5"}
                  sx={{
                    maxWidth: "450px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    textTransform: "capitalize",
                  }}
                >
                  {unsharedHealthRecord ? (
                    <>
                      {getFullName({
                        firstNames: unsharedHealthRecord.firstNames,
                        lastName: unsharedHealthRecord.lastName,
                        customaryName: unsharedHealthRecord.customaryName,
                        gender: unsharedHealthRecord.gender,
                        t,
                      })}
                    </>
                  ) : (
                    <Skeleton variant="text" width={150} />
                  )}
                </Typography>
                <Typography variant={"h6"} component="span" sx={{ color: theme.palette.neutral[500], ml: 2 }}>
                  {unsharedHealthRecord ? (
                    `${dayjsWithLocale(unsharedHealthRecord.birthDate, locale)} (${calculateAge(
                      utcToday(),
                      unsharedHealthRecord.birthDate,
                      true,
                      t
                    )})`
                  ) : (
                    <Skeleton variant="text" width={150} />
                  )}
                </Typography>
              </Box>
            </Breadcrumbs>
          </>
        )
      }
      tabs={<PageTabs />}
    >
      <Outlet />
    </PageLayout>
  );
}

function PageTabs() {
  let currentTab = "unshared-health-records/:id";
  const { t } = useI18n();
  const { pathname } = useLocation();
  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (pathname.endsWith("/diagnostic")) {
    currentTab = "unshared-health-records/:id/diagnostic";
  } else if (pathname.endsWith("/show")) {
    currentTab = "unshared-health-records/:id/show";
  } else if (pathname.endsWith("/profile")) {
    currentTab = "unshared-health-records/:id/profile";
  }

  return (
    <Tabs
      value={currentTab}
      textColor="primary"
      sx={{
        backgroundColor: "none",
        border: "none",
        px: { xs: 0, md: 2 },
        ".MuiButtonBase-root": {
          padding: { xs: "0 8px", md: "0 16px" },
        },
      }}
      variant={breakpointSm ? "fullWidth" : "standard"}
    >
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 12 : 14, fontWeight: 500 }}>
            {t("patients.tabs.immunization")}
          </Typography>
        }
        value="unshared-health-records/:id"
        to=""
        component={RouterLink}
      />

      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 12 : 14, fontWeight: 500 }}>
            {t("patients.tabs.profile")}
          </Typography>
        }
        value="unshared-health-records/:id/profile"
        to="profile"
        component={RouterLink}
      />
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 12 : 14, fontWeight: 500 }}>
            {t("patients.tabs.vaccinations_to_be_done")}
          </Typography>
        }
        value="unshared-health-records/:id/diagnostic"
        to="diagnostic"
        component={RouterLink}
      />
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 12 : 14, fontWeight: 500 }}>
            {t("patients.tabs.identity")}
          </Typography>
        }
        value="unshared-health-records/:id/show"
        to="show"
        component={RouterLink}
      />
    </Tabs>
  );
}
