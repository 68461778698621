import AddIcon from "@mui/icons-material/Add";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { Alert, Avatar, Box, Button, FormControlLabel, Grid, LinearProgress, Switch } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { HealthRecord } from "@syadem/kairos-pro-js";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LAST_HOME_PAGE_KEY } from "../../services/RedirectToLiberalOrTeamService";
import { EmptyListPlaceholder } from "../components/shared/EmptyListPlaceholder";
import HealthRecordSearchBar from "../components/shared/HealthRecordSearchBar";
import HealthRecordsList from "../components/shared/HealthRecordsList";
import TeamAdminButton from "../components/TeamAdminButton";
import { useAllHealthRecords } from "../hooks/useAllHealthRecords";
import { useI18n } from "../hooks/useI18n";
import { PageLayout } from "../layout/PageLayout";
import { theme } from "../layout/Theme";
import { useApis } from "../providers/Dependencies";
import { useAppContext } from "../hooks/useAppContext";

const NoHealthRecords = () => {
  const { t } = useI18n();
  return (
    <EmptyListPlaceholder
      title={t("patients.none")}
      icon={<AssignmentIndIcon fontSize="large" sx={{ color: theme.palette.primary[500] }} />}
      button={
        <Button size="large" component={Link} to="new" variant="contained" startIcon={<AddIcon />} disableElevation>
          {t("patients.add")}
        </Button>
      }
    >
      {t("health_records.create_or_access")}
    </EmptyListPlaceholder>
  );
};

export default function TeamHealthRecords() {
  const { t } = useI18n();
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();
  const { isLoading, healthRecords, error, fetchHealthRecords } = useAllHealthRecords();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [filteredHealthRecords, setFilteredHealthRecords] = useState<HealthRecord[] | undefined>(healthRecords);

  useEffect(() => {
    (async () => {
        if (healthRecords === undefined) {
          return setFilteredHealthRecords(undefined);
        }
        if (searchValue === undefined) {
          setFilteredHealthRecords(healthRecords);
        } else {
          // TODO: Wrap this inside a query
          if (organizationId && teamId) {
            const filteredRecords = (
              await apis.team.healthRecordApi.allHealthRecords(organizationId, teamId, searchValue)
            ).healthRecords;
            setFilteredHealthRecords(filteredRecords);
          }
        }
    })();
  }, [healthRecords, searchValue, apis, organizationId, teamId]);

  const { pathname } = useLocation();
  useEffect(() => {
    localStorage.setItem(LAST_HOME_PAGE_KEY, pathname);
  }, [pathname]);

  return (
    <PageLayout title={t("patient")} actions={<TeamAdminButton />} data-testid="team-health-records">
      <Paper sx={{ overflow: "hidden", bgcolor: "inherit", height: "100%" }} elevation={0}>
        {(error || (!isLoading && !healthRecords)) && (
          <Typography sx={{ my: 4, mx: 2 }} align="center" component="div">
            <Alert severity="error" data-testid="alert">
              {t("common.alerts.alert_notification")}
            </Alert>
          </Typography>
        )}

        {isLoading && <LinearProgress variant="query" />}

        {healthRecords && healthRecords.length > 0 && (
          <Box sx={{ height: "100%" }}>
            <Grid container spacing={{ xs: 1, md: 4 }}>
              <Grid item xs={12} sm={8} md={9} sx={{ display: "flex", alignItems: "center" }}>
                <HealthRecordSearchBar setSearchValue={setSearchValue} searchValue={searchValue} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Box sx={{ display: "flex" }}>
                  <Button
                    component={Link}
                    to={"new"}
                    startIcon={
                      <Avatar sx={{ background: theme.palette.primary[500] }}>
                        <AddIcon fontSize="small" sx={{ color: "white" }} />
                      </Avatar>
                    }
                    sx={{
                      ".MuiButton-startIcon": {
                        minWidth: "56px",
                        m: 0,
                      },
                      p: 2,
                      alignSelf: "flex-end",
                    }}
                    disableElevation
                  >
                    <Typography color="primary" fontWeight="500">
                      {t("patients.add")}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
            {filteredHealthRecords && filteredHealthRecords.length > 0 ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row-revert",
                    justifyContent: "space-between",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
                    {t("common.resultsCount", {
                      smart_count: filteredHealthRecords?.length,
                      totalCount: healthRecords?.length,
                    })}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={() =>
                          navigate("../unshared-health-records")
                        }
                      />
                    }
                    label={t("common.interface.unshared_health_records")}
                  />
                </Box>
                <HealthRecordsList healthRecords={filteredHealthRecords} refreshCallback={fetchHealthRecords} />
              </>
            ) : (
              <NoHealthRecords />
            )}
          </Box>
        )}

        {!isLoading && healthRecords && healthRecords.length === 0 && <NoHealthRecords />}
      </Paper>
    </PageLayout>
  );
}
