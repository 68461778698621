import { VaccinationAct as TeamVaccinationAct } from "@syadem/kairos-team-js";
import { VaccinationAct as ProVaccinationAct } from "@syadem/kairos-pro-js";
import { useEffect, useState } from "react";
import { useHealthRecordVaccinations } from "./useHealthRecordVaccinations";

export function useHealthRecordVaccination(
  healthRecordId: string,
  vaccinationActId: string
): {
  isLoading: boolean;
  vaccinationAct?: ProVaccinationAct | TeamVaccinationAct;
  error?: Response | Error;
} {
  const { isLoading, vaccinationActs, error } = useHealthRecordVaccinations(healthRecordId);

  const [vaccinationAct, setVaccinationAct] = useState<ProVaccinationAct | TeamVaccinationAct | undefined>(undefined);
  useEffect(() => {
    vaccinationActs &&
      vaccinationActs.length > 0 &&
      setVaccinationAct(vaccinationActs.find((vaccinationAct) => vaccinationAct.id === vaccinationActId));
  }, [vaccinationActs, vaccinationActId]);

  return { isLoading, vaccinationAct, error };
}
