import { ProfessionalAccount, ProfessionalAccountApiInterface } from "@syadem/kairos-pro-js";
import { AppStore } from "../store";

export class CurrentAccountQuery {
  constructor(
    private readonly store: AppStore,
    private readonly professionalAccountApi: ProfessionalAccountApiInterface
  ) {}

  async call(): Promise<ProfessionalAccount> {
    const currentAccount = (await this.professionalAccountApi.showCurrentProfessionalAccount()).professionalAccount;
    this.store.setState({ currentAccount });
    return currentAccount;
  }
}
