import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    containerBackground: {
      light: string;
      dark: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    containerBackground?: {
      light?: string;
      dark?: string;
    };
  }

  interface Palette {
    neutral: Palette["primary"];
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }

  interface PaletteColor {
    100?: string;
    200?: string;
    300?: string;
    400?: string;
    500?: string;
    600?: string;
    700?: string;
    800?: string;
    900?: string;
  }
  interface SimplePaletteColorOptions {
    100?: string;
    200?: string;
    300?: string;
    400?: string;
    500?: string;
    600?: string;
    700?: string;
    800?: string;
    900?: string;
  }
}

export let theme = createTheme({
  containerBackground: {
    light: "#f7f9fc",
    dark: "#141a32",
  },
  palette: {
    primary: {
      main: "#1c63fe",
      100: "#f4f6ff",
      200: "#cbd9ff",
      300: "#a0bcfe",
      400: "#578aff",
      500: "#1c63fe",
      600: "#0749ea",
      700: "#0037ce",
      800: "#002cac",
      900: "#012087",
    },
    neutral: {
      main: "#f7f9fc",
      100: "#f7f9fc",
      200: "#ecf1f8",
      300: "#e4e9f2",
      400: "#c5cee0",
      500: "#8c9bb4",
      600: "#2B3A5C",
      700: "#1f2b47",
      800: "#17203a",
      900: "#141a32",
    },
    success: {
      100: "#eaffec",
      200: "#cff8d7",
      300: "#73e995",
      400: "#4cd382",
      500: "#1bb769",
      600: "#129d66",
      700: "#0d835f",
      800: "#096a56",
      900: "#05574f",
    },
    warning: {
      100: "#fffad7",
      200: "#fef3af",
      300: "#ffeb87",
      400: "#ffe469",
      500: "#ffd739",
      600: "#dbb428",
      700: "#b7911b",
      800: "#937112",
      900: "#7a5a0b",
    },
    error: {
      100: "#fff4ee",
      200: "#ffd9cc",
      300: "#ff9f8b",
      400: "#ff7b6f",
      500: "#ff3f3f",
      600: "#db2e3d",
      700: "#b71f3a",
      800: "#931435",
      900: "#7a0b32",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});
theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(1.1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          padding: "0 16px",
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
          backgroundColor: "#000",
        },
        arrow: {
          color: "#000",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#1c63fe",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          "@media (min-width:600px)": {
            paddingLeft: 40,
            paddingRight: 40,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: "#eaffec",
          color: "#096a56",
          ".MuiAlert-icon": {
            color: "#4caf50",
          },
        },
        standardError: {
          backgroundColor: "rgb(253, 237, 237)",
          color: "rgb(95, 33, 32)",
          ".MuiAlert-icon": {
            color: "#ef5350",
          },
        },
        standardWarning: {
          backgroundColor: "rgb(255, 244, 229)",
          color: "rgb(102, 60, 0)",
          ".MuiAlert-icon": {
            color: "#ff9800",
          },
        },
        standardInfo: {
          backgroundColor: "rgb(229, 246, 253)",
          color: "rgb(1, 67, 97)",
          ".MuiAlert-icon": {
            color: "#03a9f4",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            ...(ownerState.color === "error" && {
              backgroundColor: "rgb(253, 237, 237)",
              color: "rgb(95, 33, 32)",
              ".MuiChip-icon": {
                color: "#ef5350",
              },
            }),
            ...(ownerState.color === "success" && {
              backgroundColor: "#eaffec",
              color: "#096a56",
              ".MuiChip-icon": {
                color: "#4caf50",
              },
            }),
            ...(ownerState.color === "warning" && {
              backgroundColor: "rgb(255, 244, 229)",
              color: "rgb(102, 60, 0)",
              ".MuiChip-icon": {
                color: "#ff9800",
              },
            }),
            ...(ownerState.color === "info" && {
              backgroundColor: "rgb(229, 246, 253)",
              color: "rgb(1, 67, 97)",
              ".MuiChip-icon": {
                color: "#03a9f4",
              },
            }),
          };
        },
      },
    },
  },
};
