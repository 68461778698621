import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { Team } from "@syadem/kairos-pro-js";
import { capitalize } from "lodash-es";
import { useI18n } from "../hooks/useI18n";
import { theme } from "../layout/Theme";
import IconMedicalTeamRegular from "./icons/IconMedicalTeamRegular";

interface TeamShareListItemProps {
  team: Team;
}

export function TeamShareListItem(props: TeamShareListItemProps) {
  const { team } = props;
  const { t } = useI18n();

  return (
    <ListItem
      sx={{
        px: 3,
        py: 2,
        mb: 1,
        border: `solid 1px ${theme.palette.neutral[200]}`,
        borderRadius: 1,
        bgcolor: "background.paper",
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ background: theme.palette.primary[100] }}>
          <IconMedicalTeamRegular color={theme.palette.primary[500]} style={{ height: "20px" }} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={<Typography>{capitalize(team.name)}</Typography>} secondary={t("users.team")} />
    </ListItem>
  );
}
