import { Professional, Team } from "@syadem/ariane-js";
import { HealthRecord } from "@syadem/kairos-pro-js";
import Polyglot from "node-polyglot";

export function profileCertificationDescription(
  certificators: (Professional | Team)[],
  healthRecord: HealthRecord,
  t: (phrase: string, options?: number | Polyglot.InterpolationOptions | undefined) => string
): string {
  const certificator = certificators.find((c) => c.id === healthRecord.validatorIds.at(-1));

  if (certificator) {
    const professional = certificator as Professional;
    return(t("profile.validatedBy", { certificator: `${professional.first_name} ${professional.last_name}` }));
  } else {
    return(t("profile.validatedByAPro"));
  }
}