import { Daphne, Disease } from "@syadem/daphne-js";
import { VaccinationAct as ProVaccinationAct } from "@syadem/kairos-pro-js";
import { VaccinationAct as TeamVaccinationAct } from "@syadem/kairos-team-js";
import { LocaleString } from "../translations";
import { dayjs } from "./dayjs";
import { getTranslatedFieldByLocale } from "./translatedFields";

// List of logical disease groupings
const diseaseGroupsFr = [
  {
    diseases: ["Diphtérie", "Tétanos", "Poliomyélite"],
    groupName: { fr: "Diphtérie, Tétanos, Poliomyélite", en: "Diphtheria, Tetanus, Poliomyelitis" },
  },
  {
    diseases: ["Diphtérie", "Tétanos", "Coqueluche", "Poliomyélite"],
    groupName: {
      fr: "Diphtérie, Tétanos, Coqueluche, Poliomyélite",
      en: "Diphtheria, Tetanus, Pertussis, Poliomyelitis",
    },
  },
  {
    diseases: ["Diphtérie", "Tétanos", "Coqueluche"],
    groupName: { fr: "Diphtérie, Tétanos, Coqueluche", en: "Diphtheria, Tetanus, Pertussis" },
  },
  {
    diseases: ["Diphtérie", "Tétanos", "Coqueluche", "Poliomyélite", "Haemophilus influenzae b"],
    groupName: {
      fr: "Diphtérie, Tétanos, Coqueluche, Poliomyélite, Haemophilus influenzae b",
      en: "Diphtheria, Tetanus, Pertussis, Poliomyelitis, Haemophilus influenzae b",
    },
  },
  {
    diseases: ["Diphtérie", "Tétanos", "Coqueluche", "Poliomyélite", "Haemophilus influenzae b", "Hépatite B"],
    groupName: {
      fr: "Diphtérie, Tétanos, Coqueluche, Poliomyélite, Haemophilus influenzae b, Hépatite B",
      en: "Diphtheria, Tetanus, Pertussis, Poliomyelitis, Haemophilus influenzae b, Hepatitis B",
    },
  },
  {
    diseases: ["Rougeole", "Oreillons", "Rubéole"],
    groupName: { fr: "Rougeole, Oreillons, Rubéole", en: "Measles, Mumps, Rubella" },
  },
  { diseases: ["Hépatite A", "Hépatite B"], groupName: { fr: "Hépatites A et B", en: "Hepatitis A and B" } },
  {
    diseases: ["Méningocoque A", "Méningocoque C", "Méningocoque W", "Méningocoque Y"],
    groupName: { fr: "Méningocoques ACWY", en: "Meningococci ACWY" },
  },
  { diseases: ["Hépatite A", "Typhoïde"], groupName: { fr: "Hépatite A, Typhoïde", en: "Hepatitis A, Typhoid" } },
];

function setsAreEqual(setA: Set<string>, setB: Set<string>) {
  if (setA.size !== setB.size) return false;
  for (const item of setA) {
    if (!setB.has(item)) return false;
  }
  return true;
}

function findMatchingGroup(diseases: Disease[]) {
  const diseaseNames = new Set(diseases.map((disease) => disease.name.fr || ""));
  for (const group of diseaseGroupsFr) {
    const groupSet = new Set(group.diseases);
    if (setsAreEqual(diseaseNames, groupSet)) {
      return group;
    }
  }
}

function sortDiseasesByName(diseases: Disease[]) {
  return diseases.sort((a, b) => {
    const nameA = a.name.fr?.toLowerCase() || "";
    const nameB = b.name.fr?.toLowerCase() || "";
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
}

function sortGroupOfDiseasesByName(diseaseGroups: Record<string, ProVaccinationAct[] | TeamVaccinationAct[]>) {
  const sortedKeys = Object.keys(diseaseGroups).sort();
  const sortedDiseaseGroups: Record<string, ProVaccinationAct[] | TeamVaccinationAct[]> = {};

  for (const key of sortedKeys) {
    sortedDiseaseGroups[key] = diseaseGroups[key];
  }

  return sortedDiseaseGroups;
}

const sortByPerformedOnDates = (a: ProVaccinationAct | TeamVaccinationAct, b: ProVaccinationAct | TeamVaccinationAct) => {
  if (dayjs(a.performedOn).isBefore(dayjs(b.performedOn))) {
    return 1;
  }
  if (dayjs(a.performedOn).isAfter(dayjs(b.performedOn))) {
    return -1;
  }
  return 0;
};

export const groupVaccinationActsByDisease = (
  vaccinationActs: ProVaccinationAct[] | TeamVaccinationAct[],
  daphne: Daphne,
  locale: LocaleString
): Record<string, ProVaccinationAct[] | TeamVaccinationAct[]> => {
  return vaccinationActs.reduce((acc, vaccinationAct) => {
    const vaccine = daphne.repositories.vaccines.find(vaccinationAct.vaccineId);
    const diseases = daphne.queries.diseasesByVaccine(vaccine);

    if (diseases.length) {
      const matchingGroup = findMatchingGroup(diseases);
      if (matchingGroup) {
        acc[getTranslatedFieldByLocale(locale === "fr" ? "fr" : "en", matchingGroup.groupName)] = [
          ...(acc[getTranslatedFieldByLocale(locale, matchingGroup.groupName)] || []),
          vaccinationAct,
        ];
      } else {
        const sortedDiseaseNames = sortDiseasesByName(diseases)
          .map((disease) => getTranslatedFieldByLocale(locale, disease.name))
          .join(", ");
        acc[sortedDiseaseNames] = [...(acc[sortedDiseaseNames] || []), vaccinationAct].sort(sortByPerformedOnDates);
      }
    }
    return sortGroupOfDiseasesByName(acc);
  }, {} as Record<string, ProVaccinationAct[] | TeamVaccinationAct[]>);
};
