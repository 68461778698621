import { Professional, Team } from "@syadem/ariane-js";
import { VaccinationAct as ProVaccinationAct } from "@syadem/kairos-pro-js";
import { VaccinationAct as TeamVaccinationAct } from "@syadem/kairos-team-js";
import Polyglot from "node-polyglot";
import { dayjs } from "./dayjs";

export function certificationDescription(
  certificators: (Professional | Team)[],
  vaccinationAct: ProVaccinationAct | TeamVaccinationAct,
  teamId: string | undefined,
  t: (phrase: string, options?: number | Polyglot.InterpolationOptions | undefined) => string,
  locale: string
): string {
  const certificator = certificators.find((c) => c.id === vaccinationAct.certificator?.id);
  const dateLabel = dayjs(vaccinationAct.certifiedOn).locale(locale).format(locale === "fr" ? "DD/MM/YYYY" : "MM/DD/YYYY")
  let certificatorLabel = "";

  if (vaccinationAct.certificator?.type === "professional") {
    if (certificator === undefined) {
      certificatorLabel = t("vaccines.aProfessional");
    } else {
      const professional = certificator as Professional;
      certificatorLabel = `${professional.first_name} ${professional.last_name}`;
    }
  } else if (vaccinationAct.certificator?.type === "team") {
    if (certificator === undefined) {
      certificatorLabel = t("vaccines.aTeam");
    } else {
      const team = certificator as Team;

      if (teamId === team.id) {
        const teamMember = certificators.find((c) => c.id === (vaccinationAct as TeamVaccinationAct).certificator?.teamMemberAccountId);
        const teamMemberLabel = teamMember ? `${(teamMember as Professional).first_name} ${(teamMember as Professional).last_name}` : undefined;
        certificatorLabel = t("vaccines.aTeamMember", { teamInfo: teamMemberLabel ? `"${team.name}" (${teamMemberLabel})` : team.name });
      } else {
        certificatorLabel = t("vaccines.aTeamMember", { teamInfo: `"${team.name}"` });
      }
    }
  } else {
    return("");
  }

  if (vaccinationAct.certificationMethod === "execution") {
    return(t("vaccines.validatedUponExecutionBy", { certificator: certificatorLabel, date: dateLabel }));
  } else if (vaccinationAct.certificationMethod === "proof") {
    return(t("vaccines.validatedUponProofBy", { certificator: certificatorLabel, date: dateLabel }));
  } else {
    return("");
  }
}
