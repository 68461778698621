import { ProKairosCertApiInterface, TeamKairosCertApiInterface } from "../network/apis/kairosCertApi";

interface Payload {
  healthRecordId: string;
  extended?: boolean;
  organizationId: string | null;
  teamId: string | null;
}

export class GetVaccinationCertificateQuery {
  constructor(
    private readonly proKairosCertApi: ProKairosCertApiInterface,
    private readonly teamKairosCertApi: TeamKairosCertApiInterface
  ) {}

  async call({ healthRecordId, extended = false, organizationId, teamId }: Payload) {
    if (organizationId && teamId) {
      return await this.teamKairosCertApi.getVaccinationCertificate(healthRecordId, organizationId, teamId, extended);
    } else {
      return await this.proKairosCertApi.getVaccinationCertificate(healthRecordId, extended);
    }
  }
}
