import { TranslatedField } from "@syadem/daphne-js";

export const getTranslatedFieldByLocale = (
  locale: string | undefined,
  field: TranslatedField
): string => {
  const shortLocale: string =
    !locale || locale?.toLowerCase() === "en-gb" ? "en" : locale;
  // @ts-ignore
  return field ? field[shortLocale] : "";
};
