import { VaccinationAct as TeamVaccinationAct } from "@syadem/kairos-team-js";
import { useEffect, useState } from "react";
import { useApis } from "../providers/Dependencies";
import { useAppContext } from "./useAppContext";
import { VaccinationAct as ProVaccinationAct } from "@syadem/kairos-pro-js";

interface UnsharedVaccinationActs {
  isLoading: boolean;
  unsharedVaccinationActs?: ProVaccinationAct[] | TeamVaccinationAct[];
  error?: Response | Error;
}

export function useUnsharedHealthRecordVaccinationActs(id: string): UnsharedVaccinationActs {
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();

  const [unsharedVaccinationActs, setUnsharedVaccinationActs] = useState<ProVaccinationAct[] | TeamVaccinationAct[] | undefined>(undefined);
  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        // TODO: Wrap this inside a query
        if (organizationId && teamId) {
          const { vaccinationActs } = await apis.team.vaccinationActApi.allUnsharedVaccinationActs(organizationId, teamId, id)
          setUnsharedVaccinationActs(vaccinationActs);
        } else {
          const { vaccinationActs } = await apis.pro.vaccinationActApi.allUnsharedVaccinationActs(id)
          setUnsharedVaccinationActs(vaccinationActs);
        }
      } catch (error) {
        if (error instanceof Response || error instanceof Error) {
          setError(error);
        } else {
          throw error;
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [apis, id, organizationId, teamId]);

  return { isLoading, unsharedVaccinationActs, error };
}
