import { Checkbox, FormControlLabel, Grid, LinearProgress, Paper, Radio, Typography } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import { isCertified } from "../../../components/Vaccine";
import { CancelLinkButton } from "../../../components/mui/StyledButtons";
import { StyledCheckbox } from "../../../components/mui/StyledCheckbox";
import { StyledInput } from "../../../components/mui/StyledInput";
import { StyledRadioGroup } from "../../../components/mui/StyledRadioGroup";
import { useI18n } from "../../../hooks/useI18n";
import { useUnsharedHealthRecordVaccinationAct } from "../../../hooks/useUnsharedHealthRecordVaccinationAct";
import { useVaccineWithDiseases } from "../../../hooks/useVaccineWithDiseases";
import { theme } from "../../../layout/Theme";

export function ShowVaccination() {
  const { t, locale } = useI18n();
  const { id: healthRecordId, vaccinationActId } = useParams() as {
    id: string;
    vaccinationActId: string;
  };
  const { pathname } = useLocation();
  const { unsharedVaccinationAct, isLoading } = useUnsharedHealthRecordVaccinationAct(healthRecordId, vaccinationActId);

  const vaccineWithDiseases = useVaccineWithDiseases(
    unsharedVaccinationAct?.vaccineId
  );
  return (
    <>
      {isLoading && <LinearProgress variant="query" />}
      {!isLoading && unsharedVaccinationAct && vaccineWithDiseases?.vaccineNameWithDiseases && (
        <Paper sx={{ overflow: "hidden", border: `solid 1px ${theme.palette.neutral[200]}` }} elevation={0}>
          <Grid container rowSpacing={2} columnSpacing={4} justifyContent="space-between" padding="30px">
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6" marginBottom={4}>
                  {unsharedVaccinationAct ? t("vaccines.edit_vaccination") : t("vaccines.add_vaccination")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} lg={6}>
                <StyledInput
                  placeholder={t("common.dates.datePlaceholder")}
                  name="actDate"
                  label={t("vaccines.date_act")}
                  defaultValue={unsharedVaccinationAct.performedOn.toLocaleDateString(locale, {
                    timeZone: "UTC",
                  })}
                  readOnly
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledInput
                  required
                  id="country"
                  name="country"
                  label={t("common.user_infos.country")}
                  fullWidth
                  defaultValue="France"
                  readOnly
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <StyledInput
                placeholder="ex : Priorix"
                name="vaccineId"
                label={t("vaccines.name_or_disease")}
                defaultValue={vaccineWithDiseases?.vaccineNameWithDiseases}
                readOnly
                fullWidth
              />
            </Grid>
            <Grid item xs={12} container columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} lg={6}>
                <StyledInput
                  id="batchNumber"
                  name="batchNumber"
                  label={t("vaccines.batch_number")}
                  readOnly
                  defaultValue={unsharedVaccinationAct.batchNumber || ""}
                  fullWidth
                  placeholder="ex : 3455673452372737"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledInput
                  id="serialNumber"
                  name="serialNumber"
                  label={t("vaccines.serial_number")}
                  readOnly
                  defaultValue={unsharedVaccinationAct.serialNumber || ""}
                  fullWidth
                  placeholder="ex : 27482783002389"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} lg={6}>
                <StyledInput
                  name="injection-method"
                  label={t("vaccines.injection_path")}
                  readOnly
                  defaultValue={
                    unsharedVaccinationAct.injectionMethod
                      ? t(`injectionMethods.${unsharedVaccinationAct.injectionLocation}`)
                      : ""
                  }
                  fullWidth
                  placeholder={`ex : ${t("injectionMethods.intramuscular")}`}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledInput
                  name="injection-location"
                  label={t("vaccines.injection_location")}
                  readOnly
                  defaultValue={
                    unsharedVaccinationAct.injectionLocation
                      ? t(`injectionLocations.${unsharedVaccinationAct.injectionLocation}`)
                      : ""
                  }
                  fullWidth
                  placeholder={`ex : ${t("injectionLocations.left_deltoid")}`}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} lg={6}>
                <StyledInput
                  placeholder={`ex: ${t("common.dates.datePlaceholder")}`}
                  name="expirationDate"
                  label={t("vaccines.expiration_date")}
                  defaultValue={
                    unsharedVaccinationAct.expirationDate
                      ? unsharedVaccinationAct.expirationDate.toLocaleDateString(locale, { timeZone: "UTC" })
                      : ""
                  }
                  readOnly
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledCheckbox label={"Type"} fullWidth disabled>
                  <FormControlLabel
                    control={
                      <Checkbox checked={!!unsharedVaccinationAct.booster} name="booster" value="yes" size="small" />
                    }
                    label={<Typography variant="body2">{t("vaccines.booster")}</Typography>}
                  />
                </StyledCheckbox>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <StyledRadioGroup
                label="Validation"
                fullWidth
                disabled
                value={
                  isCertified(unsharedVaccinationAct.certificationMethod)
                    ? unsharedVaccinationAct.certificationMethod
                    : "not_validated"
                }
                groupName="certification-radio-buttons"
              >
                <FormControlLabel
                  value="not_validated"
                  control={<Radio size="small" />}
                  label={<Typography variant="body2">{t("vaccines.confirmation.not_validated")}</Typography>}
                />
                <FormControlLabel
                  value="proof"
                  control={<Radio size="small" />}
                  label={<Typography variant="body2">{t("vaccines.confirmation.validate")}</Typography>}
                />
                <FormControlLabel
                  value="execution"
                  control={<Radio size="small" />}
                  label={<Typography variant="body2">{t("vaccines.confirmation.do_validate")}</Typography>}
                />
              </StyledRadioGroup>
            </Grid>
            <Grid item xs={12} container justifyContent="space-between" marginTop="30px">
              <CancelLinkButton
                component={Link}
                to={`${pathname.substring(0, pathname.lastIndexOf("/vaccination"))}`}
                variant="contained"
                disableElevation
              >
                {t("common.cta.cancel")}
              </CancelLinkButton>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}
