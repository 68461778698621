import { Dispatch, SetStateAction, useEffect, useState } from "react";

function usePersistedState<T>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const localStorageItem = localStorage.getItem(key);
  const [state, setState] = useState<T>(
    (localStorageItem && JSON.parse(localStorageItem)) || defaultValue
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}

export default usePersistedState;
