import { useContext } from "react";
import { DependenciesContext } from "../providers/Dependencies";
import { CountryConfig } from "../../appConfig";

export function useCountryConfig(): CountryConfig {
  const dependencies = useContext(DependenciesContext);
  if (!dependencies) {
    throw new Error("Dependencies container is null, did you forget to pass it to the provider?");
  } else {
    return dependencies.countryConfig;
  }
}
