import { useParams } from "react-router-dom";
import { useUnsharedHealthRecord } from "../../../hooks/useUnsharedHealthRecord";
import { HealthRecordForm } from "../HealthRecordForm";

export function ShowHealthRecord() {
  const { id } = useParams() as { id: string };
  const { unsharedHealthRecord } = useUnsharedHealthRecord(id);

  if (unsharedHealthRecord === undefined) {
    return null;
  }

  return (
    <>
      <HealthRecordForm healthRecord={unsharedHealthRecord} isReadonly />
    </>
  );
}
