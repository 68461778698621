import { Box, Typography } from "@mui/material";
import { ProfileAction, ProfileNestedAccordions, ProfileState } from "@syadem/sad-ui";
import { Dispatch } from "react";
import { useParams } from "react-router-dom";
import StyledDatepicker from "../../../components/mui/StyledDatepicker";
import { useDaphne } from "../../../hooks";
import { useHealthRecord } from "../../../hooks/useHealthRecord";
import { useI18n } from "../../../hooks/useI18n";
const ProfileQuestionnaire = ({
  state,
  dispatch,
  setCondition,
}: {
  state: ProfileState;
  dispatch: Dispatch<ProfileAction>;
  setCondition: (value: string | Date | number | boolean | null | undefined, conditionId: string) => Promise<void>;
}) => {
  const { id } = useParams() as { id: string };
  const { healthRecord } = useHealthRecord(id);
  const { t, locale } = useI18n();
  const daphne = useDaphne();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          alignItems: "flex-start",
          mt: 4,
        }}
        data-testid="healthProfileView"
      >
        <Typography fontWeight="bold" sx={{ mb: 2, textAlign: "left" }}>
          {t("profile.questionnaireTitle")}
        </Typography>
        {healthRecord && daphne && (
          <ProfileNestedAccordions
            state={state}
            daphne={daphne}
            dispatch={dispatch}
            setCondition={setCondition}
            locale={locale}
            injectedComponents={{
              DatePicker: StyledDatepicker,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ProfileQuestionnaire;
