import { LoadingButton } from "@mui/lab";
import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { LinkProps } from "react-router-dom";

const cancelStyle = ({ theme }: { theme: Theme }) => ({
  background: theme.palette.neutral[200],
  boxShadow: `0 0 0 1px ${theme.palette.neutral[200]}`,
  color: theme.palette.neutral[600],
  "&:hover": {
    background: theme.palette.neutral[300],
    boxShadow: `0 0 0 1px ${theme.palette.neutral[300]}`,
  },
  "&:focus": {
    background: theme.palette.neutral[200],
    boxShadow: `0 0 0 1px ${theme.palette.neutral[200]}`,
  },
  ".MuiSvgIcon-root": {
    fill: theme.palette.neutral[500],
  },
});

export const CancelLinkButton = styled(Button)<LinkProps>(cancelStyle) as unknown as typeof Button;
export const CancelButton = styled(Button)(cancelStyle);

const deleteStyle = ({ theme }: { theme: Theme }) => ({
  background: "white",
  color: theme.palette.error[600],
  boxShadow: `0 0 0 1px ${theme.palette.error[400]}`,
  "&:hover": {
    background: theme.palette.error[100],
    boxShadow: `0 0 0 1px ${theme.palette.error[600]}`,
  },
  "&:focus": {
    boxShadow: `0 0 0 1px ${theme.palette.error[600]}, 0 0 0 4px var ${theme.palette.error[200]}`,
  },
  ".MuiSvgIcon-root": {
    fill: theme.palette.error[600],
  },
});

export const DeleteButton = styled(LoadingButton)(deleteStyle);
