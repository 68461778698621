import { Alert, AlertProps } from "@mui/material";
import { useEffect } from "react";

interface AutoCloseAlertProps extends AlertProps {
  open: boolean;
  handleClose: () => void;
}
function AutoCloseAlert({ open, handleClose, ...rest }: AutoCloseAlertProps) {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [open, handleClose]);

  return open && <Alert {...rest}>{rest.children}</Alert>;
}

export default AutoCloseAlert;
