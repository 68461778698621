import { Alert, Button, Chip, Divider, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, Tooltip, Typography } from "@mui/material";
import { useI18n } from "../../../hooks/useI18n";
import { useCallback, useState } from "react";
import { useCurrentTeamInfos } from "../../../hooks/useCurrentTeamInfos";
import { LoadingButton } from "@mui/lab";
import { useHealthRecord } from "../../../hooks/useHealthRecord";
import { useParams } from "react-router-dom";
import { theme } from "../../../layout/Theme";
import { NoteVisibilityEnum } from "@syadem/kairos-pro-js";
import { useApis } from "../../../providers/Dependencies";
import { useAppContext } from "../../../hooks/useAppContext";

export function NoteForm() {
  const { t } = useI18n();
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();
  const { team } = useCurrentTeamInfos();
  const { id } = useParams() as { id: string };
  const { healthRecord, refetch } = useHealthRecord(id);
  const [content, setContent] = useState<string | undefined>(undefined);
  const [visibility, setVisibility] = useState<NoteVisibilityEnum | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const tooltipLabel = useCallback(
    () => {
      if (visibility == NoteVisibilityEnum.Internal) {
        return team ? t("notes.internalDescriptionTeam", { teamName: team.name }) : t("notes.internalDescriptionPro");
      } else if (visibility == NoteVisibilityEnum.Public) {
        return t("notes.publicDescription");
      }
    }, [visibility, team, t]
  )

  const inputLabel = useCallback(
    () => {
      if (visibility == NoteVisibilityEnum.Internal) {
        return team ? t("notes.newInternalNote") : t("notes.newPrivateNote");
      } else if (visibility == NoteVisibilityEnum.Public) {
        return t("notes.newPublicNote");
      }
    }, [visibility, team, t]
  )

  const submitForm = useCallback(
    async () => {
      if (healthRecord && visibility && content && content.trim().length > 0) {
        setErrorMessage(undefined);
        setIsSubmitting(true);
  
        try {
          // TODO: Wrap this inside a service
          if (organizationId && teamId) {
            await apis.team.noteApi.createNote(
              organizationId,
              teamId,
              healthRecord.id,
              { note: { content: content.trim(), visibility } }
            )
          } else {
            await apis.pro.noteApi.createNote(
              healthRecord.id,
              { note: { content: content.trim(), visibility } }
            )
          }

          refetch();
          setVisibility(undefined);
          setContent("");
        } catch (e) {
          setErrorMessage(t("common.alerts.alert_notification"));
        } finally {
          setIsSubmitting(false);
        }
      }
    }, [apis, visibility, content, healthRecord, refetch, t, organizationId, teamId]
  )

  return (
    <>
      {!visibility && (
        <Grid container alignItems="center" justifyContent="center">
          <Tooltip
            title={
              team ?
                t("notes.internalDescriptionTeam", { teamName: team.name }) :
                t("notes.internalDescriptionPro")
            }
          >
            <Button
              variant="text"
              onClick={() => setVisibility(NoteVisibilityEnum.Internal)}
              size="large"
            >
              {team ? t("notes.newInternalNote") : t("notes.newPrivateNote")}
            </Button>
          </Tooltip>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />
          <Tooltip title={t("notes.publicDescription")}>
            <Button
              variant="text"
              onClick={() => setVisibility(NoteVisibilityEnum.Public)}
              size="large"
            >
              {t("notes.newPublicNote")}
            </Button>
          </Tooltip>
        </Grid>
      )}

      {visibility && (
        <Grid container alignItems="center">
          <Grid item container alignItems="center" xs={10}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel size="small">{inputLabel()}</InputLabel>
              <OutlinedInput
                label={inputLabel()}
                size="small"
                multiline
                autoFocus
                value={content}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setContent(event.target.value)}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline" : {
                    borderColor: theme.palette.neutral[400]
                  }
                }}
                endAdornment={
                  <Tooltip
                    title={tooltipLabel()}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                            offset: [0, 12],
                            }
                          }
                        ]
                      }
                    }}
                  >
                    <InputAdornment position="end">  
                      {visibility == NoteVisibilityEnum.Internal && (
                        <Chip
                          label={team ? t("notes.internal") : t("notes.private")}
                          size="small"
                          sx={{ background: theme.palette.warning[200] }}
                        />
                      )}
                      {visibility == NoteVisibilityEnum.Public && <Chip label={t("notes.public")} size="small" />}
                    </InputAdornment>
                  </Tooltip>
                }
              />
            </FormControl>
          </Grid>
          <Grid item container alignItems="center" justifyContent="flex-end" xs={2}>
            <LoadingButton
              disableElevation
              variant="contained"
              loading={isSubmitting}
              onClick={submitForm}
              sx={{ ml: 2 }}
            >
              {t("common.cta.add")}
            </LoadingButton>
          </Grid>
          <Grid item xs={12} pl="14px">
            <Button
              variant="text"
              sx={{ p: 0 }}
              onClick={() => {
                setVisibility(undefined);
                setContent("");
              }}
            >
              <Typography component="span" color={theme.palette.neutral[500]} fontSize="0.85rem">
                {t("notes.cancelForm")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}

      {errorMessage && <Alert severity="error" sx={{ mt: 2, py: 0, px: 1 }}>{errorMessage}</Alert>}
    </>
  )
}