import { Box, CircularProgress, Container } from "@mui/material";
import { ConditionProfile, ProfileActionType, ProfileState, reducer } from "@syadem/sad-ui";
import { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { useI18n } from "../../../hooks/useI18n";
import { useProfilQuestionnaire } from "../../../hooks/useProfilQuestionnaire";
import { useUnsharedHealthRecord } from "../../../hooks/useUnsharedHealthRecord";

import ShowProfileConditions from "./ShowProfileConditions";

export function ShowProfile() {
  const { id } = useParams() as { id: string };
  const { unsharedHealthRecord } = useUnsharedHealthRecord(id);

  const { questionnaireSections } = useProfilQuestionnaire(unsharedHealthRecord);
  const { t } = useI18n();
  const [profileIsLoading, setProfileIsLoading] = useState(true);
  const initialState: ProfileState = {
    breadcrumb: [
      {
        level: 0,
        sectionId: "root",
        name: t("profile.shortTitle"),
      },
    ],
    currentSectionId: "root",
    searchConditionTerm: "",
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (questionnaireSections && unsharedHealthRecord?.profile) {
      //Update state of the reducer with the conditions
      dispatch({
        type: ProfileActionType.LoadQuestionnaireAndConditions,
        payload: {
          sections: questionnaireSections,
          conditionsProfile: Object.entries(unsharedHealthRecord?.profile).reduce(
            (acc: ConditionProfile[], [key, value]) => [
              ...acc,
              {
                id: key,
                value: value.value,
              },
            ],
            []
          ),
        },
      });
    }
    setProfileIsLoading(false);
  }, [questionnaireSections, unsharedHealthRecord?.profile]);

  return (
    <Container maxWidth="lg" disableGutters>
      {profileIsLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "900px" }}>
          <Box sx={{ flex: 1 }}>
            <ShowProfileConditions state={state} />
          </Box>
        </Box>
      )}
    </Container>
  );
}
