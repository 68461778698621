import { TranslationObject } from "./fr";

export const lv: TranslationObject = {
  notifications: {
    unmanaged_error: "Radās neparedzēta kļūda"
  },
  users: {
    pro: "Veselības aprūpes speciālists",
    team: "Aprūpes komanda",
  },
  logoTitle: "Digitālā vakcinācijas karte",
  area: "Profesionālā zona",
  injectionLocations: {
    left_deltoid: "Kreisais deltoīds",
    right_deltoid: "Labais deltoīds",
    left_thigh: "Kreisais augšstilbs",
    right_thigh: "Labais augšstilbs",
  },
  injectionMethods: {
    intramuscular: "Intramuskulāri",
    subcutaneous: "Zemādas",
    intradermal: "Intradermāli",
    oral: "Mutiski",
    intranasal: "Intranasāli",
  },
  sharingRequestStatus: {
    accepted: "Pieņemts",
    pending: "Gaida",
    rejected: "Noraidīts",
  },
  patient: "Pacienti",
  pro: "Veselības aprūpes speciālists",
  recommandations: {
    DiagnosticList: {
      infosMessage: "Vakcinācijas lēmumu pieņemšanas sistēma seko veselības iestāžu ieteikumiem.",
    },
  },
  profile: {
    expandAll: "Izvērst visu",
    shrinkAll: "Sakļaut visu",
    closeQuestionnaireCta: "Aizvērt veselības anketu",
    noConditions: "Nav raksturlielumu",
    addConditionCta: "Pievienot raksturlielumu",
    shortTitle: "Profils",
    conditionsTitle: "Norādītie raksturlielumi",
    questionnaireTitle: "Aizpildiet šeit veselības profilu",
    deleteCondition: {
      title: "Dzēst raksturlielumu",
      content: "Vai tiešām vēlaties dzēst šo raksturlielumu?",
    },
    validate: "Apstiprināt profilu",
    validated: "Profils apstiprināts",
    validatedBy: "Apstiprinājis %{certificator}",
    validatedByAPro: "Apstiprinājis veselības aprūpes speciālists",
  },
  notes: {
    title: "Piezīmes",
    newNote: "Jauna piezīme",
    internalDescriptionPro: "Redzams tikai jums",
    internalDescriptionTeam: "Redzams komandas %{teamName} dalībniekiem",
    publicDescription: "Redzams visām personām, kurām ir piekļuve šai kartei",
    internal: "Iekšējais",
    public: "Kopīgots",
    private: "Privāts",
    unknownAuthor: "Nezināms autors",
    deleteModalTitle: "Dzēst piezīmi",
    deleteModalContent: "Vai tiešām vēlaties dzēst šo piezīmi?",
    editContent: "Rediģēt saturu",
    cancelModifications: "Atcelt izmaiņas",
    lastModified: "Modificēts pirms %{distance}",
    addedAt: "Pievienots pirms %{distance}",
    addANote: "Pievienot piezīmi",
    newInternalNote: "Jauna iekšējā piezīme",
    newPublicNote: "Jauna koplietotā piezīme",
    newPrivateNote: "Jauna privātā piezīme",
    cancelForm: "Atcelt ievadi",
  },
  organization: {
    name: "Organizācijas nosaukums",
    namePlaceholder: "piem: Bordo CHU",
    creationGuidanceOne: "Ievadiet jūsu struktūras kontaktinformāciju.",
    creationGuidanceTwo: "Pēc tam varēsiet izveidot komandas šīs jaunās organizācijas ietvaros.",
    teamActivationPending:
      "Komandas %{name} pievienošanu apstiprinās MesVaccins.net administrators pēc iespējas drīzāk.",
  },
  healthRecordsTransfer: {
    noTeamTitle: "Nav aprūpes komandas",
    noTeamDescription:
      "Jums jābūt piesaistītam vismaz vienai aprūpes komandai, lai varētu pārsūtīt savus vakcinācijas kartes uz to.",
    guidanceOne:
      "Izvēlieties aprūpes komandu, uz kuru vēlaties pārsūtīt visu savu pacientu grupu.",
    guidanceTwo: "Vakcinācijas kartes vienmēr būs pieejamas caur individuālo Profesionālo režīmu.",
    guidanceThree: "Kartes, kuras jau ir koplietotas ar attiecīgo komandu, tiks automātiski ignorētas.",
    healthcareTeam: "Aprūpes komanda",
    successMessage: "Vakcinācijas kartes veiksmīgi pārsūtītas",
    successMessageWithErrorsCount:
      "Vakcinācijas karšu pārsūtīšana pabeigta: %{errorsCount} karte(s) nevarēja tikt pārsūtīta(s)",
    inProgressGuidance: "Neaizveriet lapu, līdz process nav pabeigts.",
  },
  common: {
    paidfeature:
      "No 2024. gada 8. janvāra šī funkcija būs pieejama tikai profesionālajiem kontiem vai komandām ar premium abonementu.",
    orgaPaidfeature:
      "No 2024. gada 8. janvāra premium abonements būs nepieciešams, lai piekļūtu komandas kartēm.",
    whySuscribe: "Kāpēc abonēt pilno versiju?",
    unlockPaidFeatures: "Atbloķējiet visas funkcijas ar premium kontu.",
    helpCenter: "Palīdzības centrs",
    createHelpTicket: "Sazināties ar mums",
    and: "un",
    others: "Citi",
    loading: "Ielādē...",
    navbar: {
      title: "Vakcinācijas ieteikumi",
      subtitle: "Jums un jūsu tuviniekiem",
    },
    copied: "Nokopēts!",
    resultsCount:
      "%{smart_count} rezultāts parādīts no %{totalCount} |||| %{smart_count} rezultāti parādīti no %{totalCount}",
    printing: "Drukāšana",
    cta: {
      previous: "Iepriekšējais",
      back: "Atpakaļ",
      next: "Nākamais",
      save: "Saglabāt",
      add: "Pievienot",
      edit: "Rediģēt",
      delete: "Dzēst",
      cancel: "Atcelt",
      print: "Izdrukāt",
      close: "Aizvērt",
      reset: "Atiestatīt",
      submit: "Iesniegt",
      savePdf: "PDF",
      validate: "Apstiprināt",
      logout: "Izrakstīties",
      accept: "Apstiprināt",
      reject: "Noraidīt",
      remove: "Noņemt",
      search: "Meklēt",
      view: "Apskatīt",
      subscribe: "Pāriet uz premium kontu",
      enter: "Ievadīt",
    },
    errors: {
      title: "Kļūda %{statusCode}",
      title_unknown: "Nezināma kļūda",
      404: "Pieprasītā lapa neeksistē.",
      401: "Jums nav atļaujas piekļūt šai lapai.",
      default: "Radās kļūda, mūsu tehniskā komanda ir informēta.",
      noResults: "Nav rezultātu",
    },
    gender: {
      m: "Vīrietis",
      f: "Sieviete",
      s: "Dzimums",
    },
    conditions: {
      at: "pie",
      equalTo: "ir vienāds ar",
    },
    dates: {
      datePlaceholder: "MM/DD/YYYY",
      dateFormat: "MM/dd/yyyy",
      "DD/MM/YYYY": "MM/DD/YYYY",
      "MM/DD/YYYY": "MM/DD/YYYY",
      placeholder: "MM/DD/YYYY",
      bornThe: "dzimis %{birthDate}",
      born: {
        male: "dzimis",
        female: "dzimusi",
      },
      day: "diena |||| dienas",
      month: "mēnesis |||| mēneši",
      week: "nedēļa |||| nedēļas",
      year: "gads |||| gadi",
      toDoIn: "Jāveic pēc",
      toDoAsap: "Jāveic pēc iespējas ātrāk",
      lastModified: "Modificēts pirms %{distance}",
      addedAt: "Pievienots pirms %{distance}",
      processedAt: "Apstrādāts pirms %{distance}",
    },
    interface: {
      language: "valoda",
      my_practice: "Individuālais profesionālais režīms",
      teamAccounts: "Komandas profesionālais režīms |||| Komandas profesionālais režīms",
      patients: "Pacienti",
      unshared_health_records: "Arhivētie ieraksti",
      pending_sharing_requests: "Gaidošie koplietošanas pieprasījumi",
      informations: "Mans konts",
      sort_by: "Kārtot pēc",
      back_to_home: "Atpakaļ uz sākumlapu",
      reconnect: "Pieslēgties vēlreiz",
      organizations: "organizācijas",
      premium: "Premium konts",
      basic: "Pamata konts",
      health_records_transfer: "Ierakstu pārsūtīšana",
      newTeam: "Jauna komanda",
      pendingTeamActivation: "Gaidāmā aktivizācija",
      newOrganization: "Jauna organizācija",
      subscription: "Abonements",
      subscriptionRequired: "Nepieciešams abonements",
      paymentMethodRequired: "Nepieciešams maksājuma veids",
    },
    user_infos: {
      title: "Lūdzu, pārbaudiet sniegto informāciju precizitāti",
      first_names: "Vārds(i)",
      first_name: "Vārds",
      last_name: "Dzimšanas uzvārds",
      usual_name: "Izmantotais uzvārds",
      customary_name: "Izmantotais uzvārds",
      birth_date: "Dzimšanas datums",
      social_security_number: "Sociālās apdrošināšanas numurs",
      social_security_number_infos:
        "Lūdzu, ievadiet sociālās apdrošināšanas numuru, lai automātiski aizpildītu dzimumu, mēnesi un dzimšanas gadu",
      phone: "Tālrunis",
      address: "Adrese",
      zip_code: "Pasta indekss dzīvesvietai",
      city: "Pilsēta",
      country: "Valsts",
      placeholder: "Meklēt pēc vārda, dzimšanas datuma un/vai NIR",
      team_placeholder: "Meklēt pēc nosaukuma",
      birth_country: "Dzimšanas valsts",
      birth_place: "Dzimšanas vieta",
      birth_zip_code: "Dzimšanas vietas pasta indekss",
      subscription: {
        paid_by_pro: "Premium kontu apmaksājis profesionālis: %{name}",
        paid_by_organization: "Premium kontu apmaksājusi organizācija: %{name}",
        valid_until: "Abonementa derīguma termiņš: %{date}",
      },
    },
    alerts: {
      alert_notification: "Radās kļūda, mūsu tehniskā komanda ir informēta.",
      alert_classic: "Radās kļūda",
      alert_profile: "Jūsu profils ir atjaunināts.",
      alert_auth: "Radās kļūda autentifikācijas laikā, mūsu tehniskā komanda ir informēta.",
      alert_auth_unknown_error: "Autentifikācijas kļūda",
      alert_logout: "Jūs esat izrakstījies",
      alert_logout_long: "Jūsu sesija ir beigusies, lūdzu, piesakieties vēlreiz.",
      alert_saved_success: "Izmaiņas saglabātas",
      alert_auth_failed: "Autentifikācija neizdevās"
    },
    add_team: "Pievienot komandu",
    city: {
      placeholder: "piem: Bordo vai 33000",
    }
  },
  payment: {
    title: "Nepieciešams abonements",
    description_1:
      "Lai pilnībā izmantotu visas MesVaccins.net funkcijas, nepieciešams abonements.",
    description_2: "Šis abonements nodrošinās piekļuvi:",
    description_3: "- vakcinācijas lēmumu pieņemšanas palīdzībai",
    description_4: "- ātrajam vakcinācijas diagnostikas rīkam bez ieraksta izveides (Mentor Pro)",
    description_5: "- digitālo vakcinācijas karšu pārvaldīšanai komandā",
    description_6: "Abonementa izmaksas ir 299 € gadā ar PVN.",
    description_7: "Sazinieties ar mums caur palīdzības centru šādos gadījumos:",
    description_8: "- Jūs strādājat Jaunajā Akvitānijā",
    description_9: "- Jūs esat farmaceits Auvergne-Rhône-Alpes reģionā",
    error: "Radās kļūda maksājuma laikā, ja kļūda turpinās, lūdzu, sazinieties ar atbalsta dienestu.",
    freeTrial: "Izveidojiet savu organizāciju, lai izmantotu 30 dienu bezmaksas izmēģinājumu bez saistībām",
    howToSubscribe: "Organizācijas administratori var pārvaldīt abonementu, izmantojot atbilstošo izvēlni",
    diagnosticDetails:
      "Vakcinācijas statuss, pamatojoties uz vakcinācijas vēsturi, veselības profilu un pašreizējiem vakcinācijas ieteikumiem (aktuāli, jāveic, nākamais termiņš, kontrindikācijas), ieteikuma pamatojums, pieejamās vakcīnas, profesionāļi, kas var izrakstīt vai vakcinēt, un avoti",
  },
  payment_success: {
    title: "Paldies!",
    description_1: "Paldies par reģistrāciju, tagad varat izmantot visas funkcijas.",
  },
  paywall: {
    title: "Nepieciešams premium konts",
    description_1: "Šī funkcija ir pieejama tikai profesionālajiem kontiem vai komandām ar Premium kontu.",
    cta: "Pāriet uz premium kontu",
  },
  payment_error: {
    title: "Kļūda",
    description_1: "Maksājums neizdevās, lūdzu, mēģiniet vēlreiz.",
  },
  informations: {
    title: "Kontu pārvaldība",
  },
  subscriptions: {
    title: "Abonementa pārvaldība",
    seats: "vietas",
    amount: "%{amount}€/gadā ar PVN",
    subscriptionStartedOn: "Abonements sākts %{date}",
    subscriptionTrialEndsOn: "Izmēģinājuma periods līdz %{date}",
    subscriptionNextRenewal: "Nākamā atjaunošana %{date}",
    subscriptionEndsOn: "Abonements beidzas %{date}",
    paymentInformations: "Maksājuma informācija",
    cardDetails: "Kartes %{brand} ar pēdējiem četriem cipariem %{lastFourDigits} (derīguma termiņš %{expiration})",
    noPaymentMethod: "Nav norādīts maksājuma veids",
    updatePaymentMethod: "Atjaunināt maksājuma veidu",
    addPaymentMethod: "Pievienot maksājuma veidu",
    cancel: "Pārtraukt abonementu",
    aboutCancellation1: "Jūsu abonements beigsies pašreizējā norēķinu perioda beigās.",
    aboutCancellation2: "Jūs varēsiet turpināt izmantot savus pakalpojumus līdz %{date}.",
    aboutCancellation3: "Turklāt, jūsu abonementu varēs atkārtoti aktivizēt jebkurā laikā.",
    stopCancellation: "Atkārtoti aktivizēt abonementu",
    successfullyCanceled: "Abonementa pārtraukšanas pieprasījums veiksmīgi apstrādāts",
    successfullyReactivated: "Abonements veiksmīgi atkārtoti aktivizēts",
    quantitySuccessfullyUpdated: "Vietu skaits veiksmīgi pielāgots",
    forbiddenDowngrade: "Nav iespējams pielāgot vietu skaitu uz mazāku nekā pašreiz aizņemtās vietas",
    amountOfSeats: "Vietu skaits",
    perFiveSeats: "x 5 vietas",
    totalCalculatedSeats: "%{seats} vietas",
    totalCalculatedAmount: "tātad %{amount}€/gadā ar PVN",
    seatsOccupiedAmount: "Pašlaik jūsu organizācijā ir aizņemtas %{amount} vietas.",
    adjustSeats: "Pielāgojiet vērtību atbilstoši jūsu vajadzībām un pašreizējam darbinieku skaitam.",
    adjustSeatsQuantity: "Pielāgot vietu skaitu",
    paymentFailed: "Norādīto maksājuma veidu nevarēja izmantot",
    upgradeSeats1:
      "Lai uzreiz pievienotu papildu vietas, jums ir jāsamaksā gada summa, kas pielāgota, ņemot vērā pašreizējo norēķinu periodu.",
    upgradeSeats2: "Tādējādi summa %{amount}€ ar PVN tiks ieturēta no jūsu konta.",
    upgradeSeats3:
      "Jūsu abonementa jaunā gada summa tiks ieturēta parastajā termiņā.",
    downgradeSeats1: "Vietu samazināšana stājas spēkā nekavējoties.",
    downgradeSeats2: "Jau iekasētā summa par šīm vietām tiks atskaitīta no jūsu nākamā rēķina.",
    resume: "Atkārtoti aktivizēt abonementu",
    aboutUnpaidResuming1:
      "Lai atkārtoti aktivizētu abonementu, jums ir jāsamaksā pēdējais nesamaksātais rēķins.",
    aboutUnpaidResuming2: "Pārliecinieties, ka esat norādījis derīgu maksājuma veidu.",
    aboutUnpaidResuming3: "Nākamie automātiskie maksājumi notiks kā parasti gadskārtējā termiņā.",
    aboutPausedResuming1:
      "Lai atkārtoti aktivizētu abonementu, jums ir jāsamaksā summa par nākamo gadu.",
    aboutPausedResuming2: "Pārliecinieties, ka esat norādījis derīgu maksājuma veidu.",
    aboutPausedResuming3: "Nākamie automātiskie maksājumi notiks katru gadu šajā datumā.",
    invoices: "Rēķini",
    noInvoice: "Nav rēķinu",
    referToOrganizationAdmin:
      "Sazinieties ar savas organizācijas administratoru vai sazinieties ar MesVaccins.net atbalsta dienestu, lai pārvaldītu savu abonementu",
    referToSupport: "Sazinieties ar MesVaccins.net atbalsta dienestu, lai pārvaldītu savu abonementu",
    specialDiscount: "Īpaša atlaide",
    invalidPromotionCode: "Nederīgs akcijas kods",
    applyPromotionCode: "Pielietot akcijas kodu",
    discountSuccessfullyApplied: "Atlaide veiksmīgi piemērota",
    appliedDiscount: "Piemērota %{amount}€ atlaide (%{name})",
    amountWithDiscount: "%{amount}€/gadā ar PVN, nevis",
    statuses: {
      trialing: "Izmēģinājuma periods",
      active: "Aktīvs",
      paused: "Neaktīvs",
      past_due: "Neapmaksāts",
      canceled: "Atcelts",
      unpaid: "Neapmaksāts",
    },
    invoiceStatuses: {
      open: "Neapmaksāts",
      paid: "Apmaksāts",
      uncollectible: "Atcelts",
      void: "Atcelts",
    },
  },
  sharing_requests: {
    status: "Nav gaidāmu koplietošanas pieprasījumu",
    notification: "Jūs tiksiet informēts, ja pacienta karte tiks koplietota ar jums.",
    error: "Radās kļūda",
    success: "Pieprasījums veiksmīgi apstrādāts",
    reject: "Noraidīt koplietošanas pieprasījumu",
    reject_validation: "Vai tiešām vēlaties noraidīt šo koplietošanas pieprasījumu?",
    back_to_health_records: "Piekļūt manai pacientūrai",
  },
  patients: {
    add: "Pievienot pacientu",
    name: "Vārds",
    none: "Nav pacientu",
    nothing: "Nav norādīts",
    title: "Pacienti",
    tabs: {
      immunization: "Saņemtās vakcīnas",
      vaccinations_to_be_done: "Vakcinācijas statuss",
      profile: "Veselības profils",
      identity: "Identitāte",
      share: "Koplietošana",
    },
  },
  vaccines: {
    count: "%{smart_count} vakcinācija |||| %{smart_count} vakcinācijas",
    placeholder: "piem: Gripas vakcīna",
    distributionDate: "Pieejams kopš %{date}",
    title: "Vakcinācijas",
    error_messages: {
      error_1: "Vakcināciju nevar veikt nākotnē vai pirms pacienta dzimšanas datuma.",
      error_2: "Vakcināciju nevar veikt pēc vakcinācijas derīguma termiņa beigām.",
      error_3: "Injekcijas metode nav pareiza.",
      error_4: "Injekcijas vieta nav pareiza.",
    },
    validate: "Apstiprināt vakcīnu",
    validated: "Aktivitāte apstiprināta",
    not_validated: "Aktivitāte nav apstiprināta",
    aProfessional: "veselības aprūpes speciālists",
    aTeam: "aprūpes komanda",
    aTeamMember: "komandas speciālists %{teamInfo}",
    validatedUponExecutionBy: "Apstiprināts %{date}, jo administrēja %{certificator}",
    validatedUponProofBy: "Apstiprināts uz pierādījuma pamata %{date} ar %{certificator}",
    editableByProOnly: "Tikai veselības aprūpes speciālists, kurš veica šo darbību, var to rediģēt",
    editableByTeamOnly: "Tikai veselības aprūpes speciālists no komandas, kurš veica šo darbību, var to rediģēt",
    confirmation: {
      do_validate: "Es veicu šo darbību",
      not_validated: "Aktivitāte nav apstiprināta",
      validate: "Es apstiprinu šo darbību uz sertifikāta vai vakcinācijas kartes pamata",
    },
    no_vaccinations: "Nav reģistrētas vakcinācijas",
    no_vaccinations_sub: "Šajā kartē nav reģistrētas vakcinācijas līdz koplietošanas pārtraukšanas datumam.",
    administration_date: "Administrēšanas datums",
    administration: "Administrēts %{date} %{age} vecumā.",
    administration_booster: "Papildus deva administrēta %{date} %{age} vecumā.",
    administration_country: "Administrēts %{date} %{age} vecumā, valstī %{country}.",
    administered_the: "Administrēts ",
    administered_at: " pie ",
    name: "Vakcinācijas nosaukums",
    name_or_disease: "Vakcinācijas vai slimības nosaukums",
    add_vaccination: "Pievienot vakcināciju",
    add_vaccination_msg: "Pievienojiet vakcinācijas, lai sekotu līdzi jūsu vakcinācijas statusam.",
    view_vaccination: "Apskatīt vakcināciju",
    edit_vaccination: "Rediģēt vakcināciju",
    delete_vaccination: "Dzēst vakcināciju",
    delete_vaccination_validation: "Vai tiešām vēlaties dzēst šo vakcināciju?",
    date_act: "Darbības datums",
    act_date_greater_than_expiration_date: "Darbības datuma brīdī derīguma termiņš ir beidzies",
    expiration_date: "Derīguma termiņš",
    more: "Parādīt vairāk vakcīnu",
    serial_number: "Sērijas numurs",
    batch_number: "Partijas numurs",
    booster: "Papildus deva",
    injection_location: "Administrēšanas vieta",
    injection_path: "Administrēšanas ceļš",
    additional_information: "Detalizēta informācija",
    printCertified: "Tikai apstiprinātās vakcīnas ir izdrukājamas.",
    addVaccinationConfirmed: "Vakcinācija veiksmīgi pievienota",
    addVaccinations: {
      title: "Vakcinācijas, kas jāreģistrē",
      no_vaccines_added: "Nav pievienotas vakcīnas, lūdzu, izmantojiet veidlapu",
    },
    addVaccinationType: {
      simple_entry: "Vienkārša ievade",
      simple_entry_subtitle: "Viena vakcinācija ar visām detaļām",
      multiple_entry: "Vairāku ierakstu ievade",
      multiple_entry_subtitle: "Vairāku vakcināciju ievade bez detaļām",
    },
    addSimilarVaccination: {
      title: "Līdzīga vakcinācija",
      details: "Šim pacientam jau ir reģistrēta līdzīga vakcinācija ar to pašu vakcīnu tajā pašā datumā. Vai tiešām vēlaties to pievienot?",
    },
    vaccinesDistributed: "Vakcinācijas pieejamas norādītajā datumā",
    otherVaccines: "Citas vakcīnas (pieejamas ārzemēs, ģenēriskas vai vecas vakcīnas)",
    distributedSince: "Pieejams kopš %{distributionStart}",
    distributedBetween: "Pieejams no %{distributionStart} līdz %{distributionEnd}",
    inlineForm: {
      addHistoricVaccination: "Ātrā ievade vēsturiskajām vakcīnām",
      addNewVaccination: "Pievienot jaunu vakcināciju",
    },
    displayByDiseases: "Slimība",
    vaccineOfTheDay: "Šodienas vakcīna",
  },
  health_records: {
    success: "Kartīte veiksmīgi atjaunināta",
    birth_date_placeholder: "DD/MM/YYYY",
    none_unshared: "Nav arhivētu kartīšu",
    none_shared: "Nav kopīgošanas",
    share_button: "Kopīgot šo karti",
    share_button_2: "Kopīgot karti",
    share_patients_and_pros: "Kopīgot šo karti ar pacientu vai citiem veselības aprūpes speciālistiem",
    share_pros: "Kopīgot šo karti ar citiem veselības aprūpes speciālistiem",
    share_pro: "Kopīgot ar veselības aprūpes speciālistu",
    sharing_request_sent: "Koplietošanas pieprasījums nosūtīts",
    patient_is_owner: "Pacientam ir piekļuve šai kartei, izmantojot savu kontu",
    patient_is_not_owner: "Pacientam nav piekļuves šai kartei, izmantojot savu kontu",
    cancel_invite: "Atcelt ielūgumu",
    cancel_invite_validation: "Vai tiešām vēlaties atcelt šo ielūgumu?",
    search_other_pro: "Jauna kopīgošana",
    share: "Kopīgot",
    copy_share_link: "Kopēt koplietošanas saiti",
    copy_share_link_msg: "Noklikšķinot zemāk, kopēsiet koplietošanas saiti savā starpliktuvē.",
    copy_share_link_success: "Saite veiksmīgi nokopēta starpliktuvē.",
    pro_attestation: "Es apliecinu, ka pacients ir pilnvarojis mani veikt šo kartes kopīgošanu",
    history: "Šeit varat apskatīt iepriekš pieejamo karšu vēsturi",
    create_or_access: "Izveidojiet jaunus pacientus vai piekļūstiet viņu kartēm caur koplietošanu",
    remove_from_patients: "Noņemt karti no pacientu saraksta",
    remove_from_patients_validation: "Vai tiešām vēlaties noņemt šo karti no sava pacientu saraksta?",
    remove_from_patients_validation_intro: "Noņemtā karte tiks ievietota sadaļā 'Arhivētās kartes', kurā tā būs pieejama skatīšanai, bet ne rediģēšanai.",
    delete_the_health_record: "Dzēst karti",
    delete_the_health_record_validation: "Vai tiešām vēlaties dzēst šo karti?",
    delete_the_health_record_validation_intro: "Dzēstā karte tiks ievietota sadaļā 'Arhivētās kartes', kurā tā būs pieejama skatīšanai, bet ne rediģēšanai.",
    shared: "Karte kopīgota ar pacientu",
    shared_pro: "Karte kopīgota ar citu speciālistu vai aprūpes komandu",
    non_shared: "Karte nav kopīgota ar pacientu",
  },
  share: {
    browse_professional_directory:
      "Pārlūkojiet direktoriju, lai atlasītu veselības aprūpes speciālistu vai aprūpes komandu, kurai vēlaties piešķirt piekļuvi šai kartei.",
    with_a_health_professional: "Ar veselības aprūpes speciālistu",
    with_its_owner: "Ar pacientu īpašnieku",
    share_the_health_record: "Kopīgot karti",
    grant_ownership_disclaimer_1:
      "Jūs gatavojaties piedāvāt pacientam kļūt par šīs kartes īpašnieku.",
    link_copied: "Saite nokopēta",
    generate_sharing_link: "Ģenerēt saiti",
    link_copied_to_clipboard: "Saite veiksmīgi nokopēta starpliktuvē",
    copy_sharing_link: "Kopēt saiti",
    copy_sharing_link_disclaimer_1:
      "Noklikšķinot zemāk, jūs nokopēsiet īpašumtiesību nodošanas saiti savā starpliktuvē.",
    copy_sharing_link_disclaimer_2:
      "Kad saite ir nokopēta, jūs to varēsiet ielīmēt e-pastā, SMS utt., un nosūtīt attiecīgajai personai.",
    copy_sharing_link_disclaimer_3:
      "Drošības apsvērumu dēļ šai personai būs jānorāda koplietotās kartes īpašnieka dzimšanas datums, lai piekļūtu kartei.",
    share_with_team_disclaimer:
      "Karte, kas kopīgota ar aprūpes komandu, būs pieejama visiem komandas veselības aprūpes speciālistiem.",
    email_invalid: "Nederīgs e-pasts",
    email_sent: "E-pasts veiksmīgi nosūtīts uz << %{email} >>",
    email_sharing: "Kopīgojiet šo karti, tieši nosūtot e-pastu:",
    email_sharing_description:
      "Norādiet pacientam, ka saite ir derīga tikai 30 dienas. Ja viņš to nesaņem uzreiz, viņam jāpārbauda SPAM mape.",
    email_grant_ownership: "Piešķiriet kartes īpašumtiesības tieši, nosūtot e-pastu:",
    share_link: "Kopējiet saiti zemāk un kopīgojiet to pēc saviem ieskatiem:",
    share_link_share_description: "Šo saiti var kopīgot ar SMS vai e-pastu.",
    grant_ownership_link: "Kopējiet saiti zemāk un kopīgojiet to pēc saviem ieskatiem:",
    grant_ownership_link_description: "Šo saiti var kopīgot ar SMS vai e-pastu.",
    qrcode_share: "Lieciet skenēt šo QR kodu, lai kopīgotu karti:",
    qrcode_share_description: "Ātra metode: izmantojama, ja pacientam ir viedtālrunis.",
    qrcode_grant_ownership: "Lieciet skenēt šo QR kodu nākamajam īpašniekam:",
    or: "VAI",
    sms_grant_ownership: "Piešķiriet kartes īpašumtiesības tieši, nosūtot SMS:",
    sms_share: "Piešķiriet piekļuvi kartei, nosūtot SMS:",
    other_share_methods: "Citi kopīgošanas veidi (QR kods, saite)",
    other_transfer_methods: "Citi nodošanas veidi (QR kods, saite)",
    generate_pdf: "Izdrukāt QR kodu",
  },
  pdfView: {
    shareHealthRecord: "Šo karti jums kopīgo %{user}",
    qrCode: "Šis QR kods ir derīgs līdz %{date}",
    vaccinesList: "Pacienta vakcīnu saraksts līdz šim (%{date})",
  },
  account: {
    email: "E-pasts",
    password: "Parole",
    mobile: "Mobilā telefona numurs",
    infos:
      "E-pasta adrese un parole ir jūsu pieteikšanās identifikatori. Mobilā telefona numurs ļauj izmantot divu faktoru autentifikāciju, kas nodrošina jūsu konta drošību. Jūsu personas dati netiks izmantoti komerciāliem nolūkiem.",
  },
  account_validation: {
    title: "Jūsu konta pārbaude",
    rpps_verification: "Jūsu RPPS numura pārbaude",
    cps_button: "Pieslēgties ar savu CPS karti vai e-CPS",
    info: "Drošības apsvērumu dēļ lūdzu pieslēdzieties ar savu CPS vai e-CPS karti.",
    manual_validation_option: "Nevaru pieslēgties ar savu CPS karti",
    manual_validation_option_prompt:
      "Lūdzu, nosūtiet savu profesijas apliecinājumu (piemēram, jūsu CPS kartes skenējumu) uz šo adresi: ",
  },
  datamatrix: {
    dialogTitle: "Skenēt datamatrix kodu",
    preventionMethod: "Vakcinācija: ",
    serialNumber: "Sērijas numurs: ",
    batchNumber: "Partijas numurs: ",
    expirationDate: "Derīguma termiņš: ",
    scanCta: "Skenēt datamatrix kodu",
    loading: "Notiek serveru pārbaude",
    noDeviceError: "Nav atrasta neviena kamera.",
    notFoundToast:
      "Mūsu serveros nav atrasta atbilstība vakcīnai, lūdzu, norādiet vakcīnas nosaukumu.",
    scanError: "Radās problēma, lasot datamatrix kodu, lūdzu, mēģiniet vēlreiz.",
    serverError: "Radās problēma, vaicājot vakcīnu bāzi, lūdzu, mēģiniet vēlreiz.",
    successToast: "Veidlapa veiksmīgi aizpildīta ar skenēšanas rezultātiem.",
    camera: {
      tabTitle: "Kamera",
      steps: {
        "1": "Novietojiet datamatrix kodu dzeltenajā kvadrātā",
        "2": "Informācijas meklēšana notiks automātiski",
        "3": "Uznirstošais logs automātiski aizvērsies un aizpildīs veidlapu",
      },
    },
    barCodeScanner: {
      tabTitle: "Svītrkodu lasītājs",
      steps: {
        "1": "Skenējiet kodu ar svītrkodu lasītāju",
        "2": "Kods parādīsies teksta laukā",
        "3": "Noklikšķinot uz svītrkodu lasītāja, uznirstošais logs automātiski aizvērsies un aizpildīs veidlapu",
      },
    },
    inverseImage: "Apgriezt kameras attēlu",
  },
  healthProfessional: {
    rppsNumber: "RPPS numurs",
    noRppsNumber: "Nav RPPS numura",
  },
  team: {
    identificationNumber: "Identifikācijas numurs",
    name: "Komandas nosaukums",
    namePlaceholder: "piemērs: Bordo CHU - Radioloģija",
    address: "Adrese",
    city: "Pilsēta",
    zipCode: "Pasta indekss",
    phoneNumber: "Kontakta tālruņa numurs",
    invite: "Ielūgt jaunu dalībnieku",
    no_members: "Komandā nav dalībnieku",
    member_list: "Dalībnieku saraksts",
    member_list_remove: "Izņemt dalībnieku no komandas",
    member_list_remove_description: "Dalībnieks vairs nevarēs piekļūt kopīgotajiem kartēm ar komandu.",
    sendInvitation: {
      cta: "Nosūtīt ielūgumu",
      success: "Ielūguma e-pasts veiksmīgi nosūtīts",
      enterEmail: "Ievadiet e-pasta adresi veselības aprūpes speciālistam, kuru vēlaties ielūgt jūsu komandā",
      description:
        "Šim adresātam tiks nosūtīts e-pasts. Tajā ir ietverta vienreizēja ielūguma saite, kas ir derīga 7 dienas.",
      noSeatsLeft:
        "Jūsu organizācijā nav brīvu vietu (vietu skaitu var pielāgot sadaļā 'Abonements', kas pieejams tikai administratoriem)",
      seatsAmountLeft: "Jūsu organizācijā ir %{amount} brīva(s) vieta(s)",
    },
    role: {
      admin: "Administrators",
      member: "Dalībnieks",
    },
    createPending: {
      success:
        "Jūsu pieprasījums komandas pievienošanai ir veiksmīgi apstrādāts. MesVaccins.net administrators apstrādās jūsu pieprasījumu pēc iespējas ātrāk.",
      new: "Izveidot komandu",
      description:
        "Jūsu komandas nosaukums būs redzams pacientam vai citam speciālistam iebūvētajā digitālās vakcinācijas kartes direktorijā.",
    },
    acceptInvitation: {
      title: "Pievienoties komandai",
      success: "Jūs esat pievienojies komandai %{teamName}.",
      error: "Radās kļūda. Lūdzu, sazinieties ar komandas administratoru.",
    },
    pendingTeamActivation: "Komanda gaida aktivizāciju",
    none: "Nav aktivizētu komandu",
    add: "Izveidot komandu",
    addTeamsForOrganization: "Pievienojiet jaunas komandas jūsu organizācijai",
    contactAdminToAddTeams:
      "Sazinieties ar jūsu struktūras administratoru vai MesVaccins.net atbalsta dienestu, lai pievienotu komandas",
    reactivateSubscriptionToAddTeam: "Aktivizējiet savu abonementu, lai varētu pievienot komandas",
    addPaymentMethodToAddTeam: "Norādiet derīgu maksājuma veidu, lai varētu pievienot komandas",
  },
  mentor: {
    title: "Mentor PRO: vakcinācijas lēmumu pieņemšanas atbalsts",
  },
  verification: {
    cta: "Pieprasīt konta apstiprināšanu",
    alertMessage: "Jūsu e-pasta adrese tagad ir apstiprināta",
    text1:
      "Jūsu reģistrācija ir jāapstiprina MesVaccins.net komandai. Lai to izdarītu, mums jāapstiprina jūsu profesija.",
    text2:
      "Lūdzu, nosūtiet mums vismaz vienu no šiem dokumentiem: CPS kartes kopiju, diplomu vai pierakstu Ārstu reģistrā, noklikšķinot uz zemāk esošās pogas.",
  },
  accountSettings: {
    visibilitySettings: {
      title: "Veselības aprūpes speciālistu katalogs",
      tooltip: "Lai saņemtu kartes koplietošanas pieprasījumus, ir jābūt redzamam katalogā",
      display: "Rādīt",
      hide: "Paslēpt",
    },
  },
  yup: {
    mixed: {
      default: "ir nederīgs",
      required: "ir obligāts",
      oneOf: "jābūt vienai no šīm vērtībām: %{values}",
      notOneOf: "nedrīkst būt viena no šīm vērtībām: %{values}",
      defined: "ir obligāts",
    },
    string: {
      length: "jābūt tieši %{length} rakstzīmēm",
      min: "jābūt vismaz %{min} rakstzīmēm",
      max: "jābūt mazāk nekā %{max} rakstzīmēm",
      matches: "ir nederīgs un tam jāatbilst nepieciešamajam formātam",
      email: "jābūt derīgam e-pastam",
      url: "jābūt derīgai URL adresei",
      uuid: "jābūt derīgam unikālam identifikatoram",
      trim: "nedrīkst sākties vai beigties ar atstarpi",
      lowercase: "jābūt mazajiem burtiem",
      uppercase: "jābūt lielajiem burtiem",
    },
    number: {
      min: "jābūt lielākam vai vienādam ar %{min}",
      max: "jābūt mazākam vai vienādam ar %{max}",
      lessThan: "jābūt mazākam par %{less}",
      moreThan: "jābūt lielākam par %{more}",
      positive: "jābūt pozitīvam skaitlim",
      negative: "jābūt negatīvam skaitlim",
      integer: "jābūt veselam skaitlim",
    },
    date: {
      min: "jābūt %{min} vai vēlāk",
      max: "jābūt %{max} vai agrāk",
      invalid: "nav derīgs datums",
    },
    boolean: {
      isValue: "jābūt vērtībai %{value}",
    },
    object: {
      noUnknown: "jāietver šādas atslēgas: %{unknown}",
    },
    array: {
      min: "jāietver vismaz %{min} elementi",
      max: "jāietver ne vairāk kā %{max} elementi",
      length: "jāietver tieši %{length} elementi",
    },
  },
  countries: {
    AFG: "Afganistāna",
    ALB: "Albānija",
    DZA: "Alžīrija",
    ASM: "Amerikāņu Samoa",
    AND: "Andora",
    AGO: "Angola",
    AIA: "Angilja",
    ATA: "Antarktīda",
    ATG: "Antigva un Barbuda",
    ARG: "Argentīna",
    ARM: "Armēnija",
    ABW: "Aruba",
    AUS: "Austrālija",
    AUT: "Austrija",
    AZE: "Azerbaidžāna",
    BHS: "Bahamu salas",
    BHR: "Bahreina",
    BGD: "Bangladeša",
    BRB: "Barbadosa",
    BLR: "Baltkrievija",
    BEL: "Beļģija",
    BLZ: "Beliza",
    BEN: "Benina",
    BMU: "Bermudu salas",
    BTN: "Butāna",
    BOL: "Bolīvija",
    BES: "Bonaire, Sint-Eistatiusa un Saba",
    BIH: "Bosnija un Hercegovina",
    BWA: "Botsvāna",
    BVT: "Buvē sala",
    BRA: "Brazīlija",
    IOT: "Indijas okeāna Britu teritorija",
    BRN: "Bruneja",
    BGR: "Bulgārija",
    BFA: "Burkinafaso",
    BDI: "Burundija",
    CPV: "Kaboverde",
    KHM: "Kambodža",
    CMR: "Kamerūna",
    CAN: "Kanāda",
    CYM: "Kaimanu salas",
    CAF: "Centrālāfrikas Republika",
    TCD: "Čada",
    CHL: "Čīle",
    CHN: "Ķīna",
    CXR: "Ziemassvētku sala",
    CCK: "Kokosu (Kīlinga) salas",
    COL: "Kolumbija",
    COM: "Komoru salas",
    COD: "Kongo Demokrātiskā Republika",
    COG: "Kongo",
    COK: "Kuka salas",
    CRI: "Kostarika",
    HRV: "Horvātija",
    CUB: "Kuba",
    CUW: "Kirasao",
    CYP: "Kipra",
    CZE: "Čehija",
    CIV: "Kotdivuāra",
    DNK: "Dānija",
    DJI: "Džibutija",
    DMA: "Dominika",
    DOM: "Dominikānas Republika",
    ECU: "Ekvadora",
    EGY: "Ēģipte",
    SLV: "Salvadora",
    GNQ: "Ekvatoriālā Gvineja",
    ERI: "Eritreja",
    EST: "Igaunija",
    SWZ: "Esvatīni",
    ETH: "Etiopija",
    FLK: "Folklenda (Malvinas) salas",
    FRO: "Farēru salas",
    FJI: "Fidži",
    FIN: "Somija",
    FRA: "Francija",
    GUF: "Franču Gviāna",
    PYF: "Franču Polinēzija",
    ATF: "Franču Dienvidu un Antarktikas teritorijas",
    GAB: "Gabona",
    GMB: "Gambija",
    GEO: "Gruzija",
    DEU: "Vācija",
    GHA: "Gana",
    GIB: "Gibraltārs",
    GRC: "Grieķija",
    GRL: "Grenlande",
    GRD: "Grenāda",
    GLP: "Gvadelupa",
    GUM: "Guama",
    GTM: "Gvatemala",
    GGY: "Gērnsija",
    GIN: "Gvineja",
    GNB: "Gvineja-Bisava",
    GUY: "Gajāna",
    HTI: "Haiti",
    HMD: "Hērda un Makdonalda salas",
    VAT: "Vatikāns",
    HND: "Hondurasa",
    HKG: "Honkonga",
    HUN: "Ungārija",
    ISL: "Islande",
    IND: "Indija",
    IDN: "Indonēzija",
    IRN: "Irāna",
    IRQ: "Irāka",
    IRL: "Īrija",
    IMN: "Mena sala",
    ISR: "Izraēla",
    ITA: "Itālija",
    JAM: "Jamaika",
    JPN: "Japāna",
    JEY: "Džērsija",
    JOR: "Jordānija",
    KAZ: "Kazahstāna",
    KEN: "Kenija",
    KIR: "Kiribati",
    PRK: "Ziemeļkoreja",
    KOR: "Dienvidkoreja",
    KWT: "Kuveita",
    KGZ: "Kirgizstāna",
    LAO: "Laosa",
    LVA: "Latvija",
    LBN: "Libāna",
    LSO: "Lesoto",
    LBR: "Libērija",
    LBY: "Lībija",
    LIE: "Lihtenšteina",
    LTU: "Lietuva",
    LUX: "Luksemburga",
    MAC: "Makao",
    MDG: "Madagaskara",
    MWI: "Malāvija",
    MYS: "Malaizija",
    MDV: "Maldīvija",
    MLI: "Mali",
    MLT: "Malta",
    MHL: "Māršala salas",
    MTQ: "Martinika",
    MRT: "Mauritānija",
    MUS: "Maurīcija",
    MYT: "Majota",
    MEX: "Meksika",
    FSM: "Mikronēzija",
    MDA: "Moldova",
    MCO: "Monako",
    MNG: "Mongolija",
    MNE: "Melnkalne",
    MSR: "Montserrata",
    MAR: "Maroka",
    MOZ: "Mozambika",
    MMR: "Mjanma",
    NAM: "Namībija",
    NRU: "Nauru",
    NPL: "Nepāla",
    NLD: "Nīderlande",
    NCL: "Jaunkaledonija",
    NZL: "Jaunzēlande",
    NIC: "Nikaragva",
    NER: "Nigēra",
    NGA: "Nigērija",
    NIU: "Niue",
    NFK: "Norfolkas sala",
    MNP: "Ziemeļu Marianas salas",
    NOR: "Norvēģija",
    OMN: "Omāna",
    PAK: "Pakistāna",
    PLW: "Palau",
    PSE: "Palestīna",
    PAN: "Panama",
    PNG: "Papua Jaungvineja",
    PRY: "Paragvaja",
    PER: "Peru",
    PHL: "Filipīnas",
    PCN: "Pitkērna",
    POL: "Polija",
    PRT: "Portugāle",
    PRI: "Puertoriko",
    QAT: "Katara",
    MKD: "Ziemeļmaķedonija",
    ROU: "Rumānija",
    RUS: "Krievija",
    RWA: "Ruanda",
    REU: "Reinjona",
    BLM: "Senbartelmī",
    SHN: "Svētās Helēnas sala",
    KNA: "Sentkitsa un Nevisa",
    LCA: "Sentlūsija",
    MAF: "Sentmartena (Francijas daļa)",
    SPM: "Senpjēra un Mikelona",
    VCT: "Sentvinsenta un Grenadīnas",
    WSM: "Samoa",
    SMR: "Sanmarīno",
    STP: "Santome un Prinsipi",
    SAU: "Saūda Arābija",
    SEN: "Senegāla",
    SRB: "Serbija",
    SYC: "Seišelu salas",
    SLE: "Sjerraleone",
    SGP: "Singapūra",
    SXM: "Sintmārtena (Nīderlandes daļa)",
    SVK: "Slovākija",
    SVN: "Slovēnija",
    SLB: "Zālamana salas",
    SOM: "Somālija",
    ZAF: "Dienvidāfrika",
    SGS: "Dienviddžordžija un Dienvidsendviču salas",
    SSD: "Dienvidsudāna",
    ESP: "Spānija",
    LKA: "Šrilanka",
    SDN: "Sudāna",
    SUR: "Surinama",
    SJM: "Svalbāra un Jana Majena salas",
    SWE: "Zviedrija",
    CHE: "Šveice",
    SYR: "Sīrija",
    TWN: "Taivāna",
    TJK: "Tadžikistāna",
    TZA: "Tanzānija",
    THA: "Taizeme",
    TLS: "Austrumtimora",
    TGO: "Togo",
    TKL: "Tokelau",
    TON: "Tonga",
    TTO: "Trinidāda un Tobāgo",
    TUN: "Tunisija",
    TUR: "Turcija",
    TKM: "Turkmenistāna",
    TCA: "Turksas un Kaikosas salas",
    TUV: "Tuvalu",
    UGA: "Uganda",
    UKR: "Ukraina",
    ARE: "Apvienotie Arābu Emirāti",
    GBR: "Apvienotā Karaliste",
    UMI: "ASV Aizjūras salas",
    USA: "Amerikas Savienotās Valstis",
    URY: "Urugvaja",
    UZB: "Uzbekistāna",
    VUT: "Vanuatu",
    VEN: "Venecuēla",
    VNM: "Vjetnama",
    VGB: "Britu Virdžīnu salas",
    VIR: "ASV Virdžīnu salas",
    WLF: "Volisa un Futuna salas",
    ESH: "Rietumsahāra",
    YEM: "Jemena",
    ZMB: "Zambija",
    ZWE: "Zimbabve",
    ALA: "Olandes salas",
  }
};