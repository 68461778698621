import { useEffect } from "react";
import { Outlet, useOutletContext, useSearchParams } from "react-router-dom";
import { useAuthenticatedUser } from "../../store";
import { useServiceBus } from "../hooks/useServiceBus";

export function RedirectToLiberalOrTeam() {
  const [searchParams] = useSearchParams();
  const serviceBus = useServiceBus();
  const user = useAuthenticatedUser();

  useEffect(() => {
    serviceBus.dispatch({
      type: "redirectToLiberalOrTeam",
      searchParams,
      user
    })
  }, [user, serviceBus, searchParams]);

  const outletContext = useOutletContext();
  return <Outlet context={outletContext} />;
}
