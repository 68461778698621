import { SubscriptionStatusEnum } from "@syadem/kairos-subscription-js";
import { some } from "lodash-es";
import { useEffect, useMemo } from "react";
import { useSubscriptionOrganizations } from "../../../store";
import { useI18n } from "../../hooks/useI18n";
import { PageLayout } from "../../layout/PageLayout";
import { SubscriptionRequired } from "../organization/subscription/SubscriptionRequired";
import { useCountryConfig } from "../../hooks/useCountryConfig";
import { useConfig } from "../../hooks/useConfig";

function IndexMentor() {
  const { t, locale } = useI18n();
  const { subscription } = useCountryConfig();
  const subscriptionOrganizations = useSubscriptionOrganizations();
  const { mentorUrl } = useConfig();

  useEffect(() => {
    if (subscription) {
      subscription.queries.subscriptionOrganizationsQuery.call();
    }
  }, [subscription]);

  const mentorLocale = useMemo(() => {
    switch (locale) {
      case "fr":
        return "fr";
      case "en-gb":
        return "en";
    }
  }, [locale]);

  if (
    subscriptionOrganizations && (
      Object.keys(subscriptionOrganizations).length == 0 ||
      !some(
        Object.entries(subscriptionOrganizations),
        ([_, o]) =>
          o.subscription.status == SubscriptionStatusEnum.Active ||
          o.subscription.status == SubscriptionStatusEnum.Trialing
      )
    )
  ) {
    return (
      <PageLayout title={t("payment.title")}>
        <SubscriptionRequired subscriptionOrganizations={subscriptionOrganizations} />
      </PageLayout>
    );
  }

  return (
    <PageLayout title={t("mentor.title")}>
      <iframe
        src={`${mentorUrl}/iframe?travel=true&pro=true&lang=${mentorLocale}`}
        width="100%"
        height="100%"
        title="Mentor par MesVaccins.net"
        sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
        loading="lazy"
        style={{ overflow: "hidden" }}
        frameBorder="0"
      ></iframe>
    </PageLayout>
  );
}

export default IndexMentor;
