import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { List, ListItem, ListItemText, Stack, Typography, useMediaQuery } from "@mui/material";
import { HealthRecord } from "@syadem/kairos-pro-js";
import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { formatDistanceToNow } from "../../../utils/dayjs";
import { isCurrentOwner } from "../../../utils/healthRecord";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";
import HealthRecordListItemContent from "./HealthRecordListItemContent";
import { ListItemDeleteButton } from "./ListItemDeleteButton";
import { ListItemLink } from "./ListItemLink";
import { useAuthenticatedUser } from "../../../store";
import { useApis } from "../../providers/Dependencies";
import { useAppContext } from "../../hooks/useAppContext";

interface HealthRecordsListProps {
  healthRecords: HealthRecord[];
  refreshCallback: () => void;
}

export default function HealthRecordsList(props: HealthRecordsListProps) {
  const { healthRecords, refreshCallback } = props;
  const currentUser = useAuthenticatedUser();
  const { t, locale } = useI18n();
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();
  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDelete = useCallback(async (healthRecord: HealthRecord) => {
    // TODO: Wrap this inside a service
    if (organizationId && teamId) {
      await apis.team.healthRecordApi.deleteHealthRecord(organizationId, teamId, healthRecord.id)
    } else {
      await apis.pro.healthRecordApi.deleteHealthRecord(healthRecord.id)
    }
  }, [apis, organizationId, teamId]);

  const handleLeave = useCallback(async (healthRecord: HealthRecord) => {
    // TODO: Wrap this inside a service
    if (organizationId && teamId) {
      await apis.team.healthRecordApi.leaveHealthRecord(organizationId, teamId, healthRecord.id)
    } else {
      await apis.pro.healthRecordApi.leaveHealthRecord(healthRecord.id)
    }
  }, [apis, organizationId, teamId]);

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    const healthRecord = healthRecords[index];

    return (
      <ListItem
        style={style}
        disablePadding
        key={healthRecord.id}
        secondaryAction={
          <Stack direction="row">
            <ListItemText
              sx={{
                marginY: "auto",
                mr: 2,
                display: { xs: "none", sm: "block" },
              }}
              secondary={
                <Typography variant="body2" color={theme.palette.neutral[500]}>
                  {t(`common.dates.lastModified`, {
                    distance: formatDistanceToNow(locale, healthRecord.updatedAt),
                  })}
                </Typography>
              }
            />
            {isCurrentOwner(healthRecord, currentUser) ? (
              <ListItemDeleteButton
                deleteRecord={() => handleDelete(healthRecord)}
                refreshCallback={refreshCallback}
                title={t("common.cta.delete")}
                modalTitle={t("health_records.delete_the_health_record")}
                modalContent={
                  <>
                    <div>{t("health_records.delete_the_health_record_validation_intro")}</div>
                    <p>{t("health_records.delete_the_health_record_validation")}</p>
                  </>
                }
                icon={<DeleteOutlineIcon fontSize="small" color="error" />}
                variant="outlined"
                buttonWidth="110px"
                type={breakpointSm ? "icon" : "button"}
              />
            ) : (
              <ListItemDeleteButton
                deleteRecord={() => handleLeave(healthRecord)}
                refreshCallback={refreshCallback}
                title={t("common.cta.remove")}
                modalTitle={t("health_records.remove_from_patients")}
                modalContent={
                  <>
                    <div>{t("health_records.remove_from_patients_validation_intro")}</div>
                    <p>{t("health_records.remove_from_patients_validation")}</p>
                  </>
                }
                icon={<ExitToAppIcon fontSize="small" color="error" />}
                label={t("common.cta.remove")}
                variant="outlined"
                buttonWidth="110px"
                type={breakpointSm ? "icon" : "button"}
              />
            )}
          </Stack>
        }
        sx={{
          ".MuiListItemSecondaryAction-root": {
            right: { xs: 2, md: 16 },
          },
        }}
      >
        <ListItemLink
          to={`${healthRecord.id}`}
          sx={{
            p: { xs: 1, sm: 2 },
            border: `solid 1px ${theme.palette.neutral[200]}`,
            borderRadius: 1,
            bgcolor: "background.paper",
            "&:hover": {
              border: `solid 1px ${theme.palette.primary[500]}`,
              bgcolor: "background.paper",
            },
          }}
          data-testid="health-record"
        >
          <HealthRecordListItemContent
            healthRecord={healthRecord}
            icon={<AssignmentIndIcon sx={{ color: theme.palette.primary[500] }} />}
            testId={`healthRecordContent${index}`}
          />
        </ListItemLink>
      </ListItem>
    );
  };
  const [listHeight, setListHeight] = useState(0);
  const listRef: MutableRefObject<HTMLUListElement | null> = useRef(null);
  const breakpointSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const getItemSizeResponsive = useCallback(() => {
    return breakpointSmDown ? 130 : breakpointMdUp ? 96 : 108;
  }, [breakpointSmDown, breakpointMdUp]);

  const getItemSize = (index: number) => {
    const healthRecord = healthRecords[index];
    return breakpointSmDown
      ? healthRecord.externalId && healthRecord.externalId.trim() !== ""
        ? 130
        : 108
      : breakpointMdUp
      ? 96
      : healthRecord.externalId && healthRecord.externalId.trim() !== ""
      ? 116
      : 96;
  };

  useEffect(() => {
    if (listRef?.current?.clientHeight && listHeight === 0) {
      setListHeight(
        listRef?.current?.clientHeight > 200
          ? listRef?.current?.clientHeight - getItemSizeResponsive()
          : getItemSizeResponsive()
      );
    }
  }, [listHeight, getItemSizeResponsive]);

  return (
    <List sx={{ height: "100%", width: "100%", flex: 1 }} ref={listRef}>
      <VariableSizeList height={listHeight} itemCount={healthRecords.length} itemSize={getItemSize} width={"100%"}>
        {renderRow}
      </VariableSizeList>
    </List>
  );
}
