import { TeamMember } from "@syadem/kairos-team-js";
import { useContext } from "react";
import { devtools } from "zustand/middleware";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { createStore } from "zustand/vanilla";
import { DependenciesContext } from "./ui/providers/Dependencies";
import { ProfessionalAccount } from "@syadem/kairos-pro-js";
import { Organization } from "./domain/organization";
import { Team } from "./domain/team";
import { Organization as SubscriptionOrganization } from "@syadem/kairos-subscription-js";
import { ActionResult } from "./actionResult";
import { User } from "./domain/user";

type AuthState = { type: "signed-out" } | { type: "signed-in"; user: User } | { type: "error"; error: Error };

export interface AppState {
  authState: AuthState;
  actionResults: ActionResult<unknown>[];
  organizations?: { [id: string]: Organization };
  teamMembers?: TeamMember[];
  currentAccount?: ProfessionalAccount;
  teams?: { [id: string]: Team };
  subscriptionOrganizations?: { [id: string]: SubscriptionOrganization };
}

export type AppStore = ReturnType<typeof initStore>;

export function initStore() {
  const devtoolsImpl = import.meta.env.MODE === "development" ? devtools : (((x) => x) as typeof devtools);
  
  return createStore<AppState>()(
    devtoolsImpl(
      () =>
        ({
          authState: { type: "signed-out" },
          actionResults: []
        }) as AppState
    )
  );
}

export function useAllOrganizations(): { [id: string]: Organization } | undefined {
  return useContextStore(allOrganizationsSelector);
}

export function useOrganization(id: string | undefined): Organization | null {
  return useContextStore((state) => {
    if (!id) { return null }
    
    return (allOrganizationsSelector(state) || {})[id] ?? null;
  });
}

export function useSubscriptionOrganizations(): { [id: string]: SubscriptionOrganization } | undefined {
  return useContextStore(subscriptionOrganizationsSelector);
}

export function useSubscriptionOrganization(id: string | undefined): SubscriptionOrganization | null {
  return useContextStore((state) => {
    if (!id) { return null }
    
    return (subscriptionOrganizationsSelector(state) || {})[id] ?? null;
  });
}

export function useTeamsForOrganizationId(organizationId: string | undefined): Team[] {
  return useContextStore((state) => {
    if (!organizationId) { return [] }
    
    const teams = (teamsSelector(state) || {});
    
    return (
      Object.entries(teams)
        .filter(([_, t]) => t.organizationId == organizationId)
        .map(([_, t]) => t)
    )
  });
}

export function useTeamMembers(): TeamMember[] | undefined {
  return useContextStore(teamMembersSelector);
}

export function useCurrentAccount(): ProfessionalAccount | undefined {
  return useContextStore(professionalAccountSelector);
}

export function useTeams(): { [id: string]: Team } | undefined {
  return useContextStore(teamsSelector);
}

export function useAuthState(): AppState["authState"] {
  return useContextStore(authStateSelector);
}

export function useAuthenticatedUser(): User {
  const user = useContextStore(userSelector);
  if (!user) throw new Error("Unexpected null user");
  return user;
}

const allOrganizationsSelector = (state: AppState) => state.organizations;
const subscriptionOrganizationsSelector = (state: AppState) => state.subscriptionOrganizations;
const teamMembersSelector = (state: AppState) => state.teamMembers;
const professionalAccountSelector = (state: AppState) => state.currentAccount;
const teamsSelector = (state: AppState) => state.teams;
const authStateSelector = (state: AppState) => state.authState;
const userSelector = (state: AppState) => (state.authState.type === "signed-in" ? state.authState.user : null);

function useContextStore<T>(selector: (state: AppState) => T): T {
  const deps = useContext(DependenciesContext);
  if (!deps) {
    throw new Error("No dependencies container, did you forget to pass it to the provider?");
  } else {
    return useStoreWithEqualityFn(deps.store, selector);
  }
}
