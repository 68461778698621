import { UserManager } from "oidc-client-ts";
import { Service } from "./service";

export enum UserEditTypeEnum {
  email = "UPDATE_EMAIL",
  password = "UPDATE_PASSWORD",
  phoneNumber = "mesvaccins-update-mobile-number",
}

export class EditUserService implements Service<{ editType: UserEditTypeEnum }, void> {
  constructor(
    private readonly window: Window,
    private readonly oidcManager: UserManager
  ) {}

  async call({ editType }: { editType: UserEditTypeEnum }) {
    const baseUrl = this.oidcManager.settings.authority;
    const clientId = this.oidcManager.settings.client_id;
    const queryParamsString = this.jsonToQueryParams({
      client_id: clientId,
      redirect_uri: this.window.location.href,
      response_mode: "fragment",
      response_type: "code",
      scope: "openid",
      kc_action: editType,
    });
    const url = new URL("/realms/professional/protocol/openid-connect/auth", baseUrl);
    const completUrl = url + queryParamsString;
    this.window.location.href = completUrl;
  }

  private jsonToQueryParams(json: Record<string, string>): string {
    const queryParams = Object.entries(json)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&");

    return `?${queryParams}`;
  }
}
