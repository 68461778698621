import { InvoiceApiInterface, UpcomingInvoice } from "@syadem/kairos-subscription-js";

interface Payload {
  organizationId: string;
  quantity: number;
}

export class SubscriptionUpcomingInvoiceQuery {
  constructor(private readonly invoiceApi: InvoiceApiInterface) {}

  async call({ organizationId, quantity }: Payload): Promise<UpcomingInvoice> {
    return await this.invoiceApi.getUpcomingInvoice(organizationId, quantity);
  }
}
