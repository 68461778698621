import { Organization, OrganizationApiInterface } from "@syadem/kairos-subscription-js";
import { AppStore } from "../store";

export class SubscriptionOrganizationsQuery {
  constructor(
    private readonly store: AppStore,
    private readonly organizationApi: OrganizationApiInterface
  ) {}

  async call(): Promise<Organization[]> {
    const organizations = (await this.organizationApi.listOrganizations()).organizations;

    this.store.setState({
      subscriptionOrganizations: organizations.reduce((acc, o) => ({ ...acc, [o.id]: o }), {})
    });

    return organizations;
  }
}
