import { HealthRecord } from "@syadem/kairos-pro-js";
import Polyglot from "node-polyglot";
import { User } from "../domain/user";
import { formatFirstName } from "./string";

export function isCurrentOwner(healthRecord: HealthRecord | undefined, currentUser: User | undefined) {
  return healthRecord && currentUser && healthRecord.ownerId == currentUser.id;
}

export function isSharedToPatient(healthRecord: HealthRecord | undefined) {
  return healthRecord?.ownerReference?.type === "citizen";
}

export function isSharedToProOrTeam(healthRecord: HealthRecord | undefined) {
  if (healthRecord) {
    return healthRecord.sharedWithPro.length + healthRecord.sharedWithTeams.length > 1;
  } else {
    return false;
  }
}

export function getFullName({
  customaryName,
  firstNames,
  lastName,
  gender,
  t,
}: {
  customaryName?: string | null;
  firstNames: string;
  lastName: string;
  gender: string;
  t: (phrase: string, options?: number | Polyglot.InterpolationOptions | undefined) => string;
}) {
  if (customaryName && customaryName !== lastName) {
    const birthName = `(${t(`common.dates.born.${gender === "M" ? "male" : "female"}`)} ${lastName.toUpperCase()})`;
    return `${customaryName.toUpperCase()} ${formatFirstName(firstNames)} ${birthName}`;
  } else {
    return `${lastName.toUpperCase()} ${formatFirstName(firstNames)}`;
  }
}
