import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Alert, Box, Button, Paper, Skeleton, Snackbar, Stack, Switch, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Team } from "../../../domain/team";
import { User } from "../../../domain/user";
import { useAuthenticatedUser, useCurrentAccount, useTeams } from "../../../store";
import { StyledInput } from "../../components/mui/StyledInput";
import { useI18n } from "../../hooks/useI18n";
import { PageLayout } from "../../layout/PageLayout";
import { theme } from "../../layout/Theme";
import { useQueries } from "../../providers/Dependencies";
import { useServiceBus } from "../../hooks/useServiceBus";
import { UserEditTypeEnum } from "../../../services/editUserService";

export const MAX_EMAIL_DISPLAY_LENGTH = 25;

export function AccountManagement() {
  const currentUser = useAuthenticatedUser();

  const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false);
  const [successDisplayed, setSuccessDisplayed] = useState<boolean>(false);

  const { t } = useI18n();

  return (
    <PageLayout title={t("informations.title")} data-testid="account-management-page">
      <AccountInfo currentUser={currentUser} />
      <Snackbar
        open={errorDisplayed}
        autoHideDuration={6000}
        onClose={() => setErrorDisplayed(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{t("common.alerts.alert_classic")}</Alert>
      </Snackbar>
      <Snackbar
        open={successDisplayed}
        autoHideDuration={6000}
        onClose={() => setSuccessDisplayed(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">{t("common.alerts.alert_profile")}</Alert>
      </Snackbar>
    </PageLayout>
  );
}

function AccountInfo({ currentUser }: { currentUser?: User }) {
  const { t } = useI18n();
  const serviceBus = useServiceBus();

  const updateUserAttribute = useCallback(
    async (editType: UserEditTypeEnum) => {
      if (currentUser) {
        await serviceBus.dispatch({
          type: "editUser",
          editType,
        });
      }
    },
    [serviceBus, currentUser]
  );

  // Call Api for organizations to have them in store
  const { allOrganizationsQuery } = useQueries();
  useEffect(() => {
    allOrganizationsQuery.call();
  }, [allOrganizationsQuery]);

  return (
    <Paper
      sx={{
        maxWidth: "sm",
        marginX: "auto",
        overflow: "hidden",
        border: `solid 1px ${theme.palette.neutral[200]}`,
      }}
      elevation={0}
    >
      <Stack spacing={2} sx={{ paddingX: "32px", paddingY: "16px" }}>
        {currentUser ? (
          <>
            <Stack direction="row" spacing={2}>
              <StyledInput name="email" fullWidth readOnly value={currentUser.email} label={t("account.email")} />
              <Box sx={{ display: "flex", alignItems: "center", pt: 3 }}>
                <Button onClick={() => updateUserAttribute(UserEditTypeEnum.email)} variant="contained" disableElevation>
                  {t("common.cta.edit")}
                </Button>
              </Box>
            </Stack>
            <Stack direction="row" spacing={2}>
              <StyledInput name="password" label={t("account.password")} fullWidth value="********" readOnly />
              <Box sx={{ display: "flex", alignItems: "center", pt: 3 }}>
                <Button onClick={() => updateUserAttribute(UserEditTypeEnum.password)} variant="contained" disableElevation>
                  {t("common.cta.edit")}
                </Button>
              </Box>
            </Stack>
            <Stack direction="row" spacing={2}>
              <StyledInput
                name="phone"
                label={t("account.mobile")}
                fullWidth
                value={currentUser.mobileNumber}
                readOnly
              />
              <Box sx={{ display: "flex", alignItems: "center", pt: 3 }}>
                <Button onClick={() => updateUserAttribute(UserEditTypeEnum.phoneNumber)} variant="contained" disableElevation>
                  {t("common.cta.edit")}
                </Button>
              </Box>
            </Stack>
            <Alert severity="info">{t("account.infos")}</Alert>
          </>
        ) : (
          <Skeleton variant="text" />
        )}
        <VisibilityForm />
      </Stack>
    </Paper>
  );
}

function VisibilityForm() {
  const { t } = useI18n();
  const currentUser = useCurrentAccount();
  const teams = useTeams();
  const { currentAccountQuery } = useQueries();
  const serviceBus = useServiceBus();

  useEffect(() => {
    currentAccountQuery.call();
  }, [currentAccountQuery]);

  const handleAccountVisibilityChange = useCallback(
    async (visible: boolean) => {
      await serviceBus.dispatch({
        type: "updateProfessionalAccountVisibility",
        professionalAccount: { visible }
      })
    },
    [serviceBus]
  );

  const handleTeamVisibilityChange = useCallback(
    async (team: Team, visible: boolean) => {
      await serviceBus.dispatch({
        type: "updateTeamVisibility",
        teamId: team.id,
        organizationId: team.organizationId,
        team: { visible }
      })
    },
    [serviceBus]
  );

  return (
    currentUser &&
    teams && (
      <Box>
        <Box
          display="flex"
          alignItems="center"
          fontWeight="600"
          fontSize="14px"
          color={theme.palette.neutral["600"]}
          fontFamily="Open Sans,sans-serif"
        >
          {t("accountSettings.visibilitySettings.title")}
          <Tooltip title={t("accountSettings.visibilitySettings.tooltip")}>
            <HelpOutlineIcon fontSize="small" sx={{ ml: 1 }} />
          </Tooltip>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" fontSize="14px">
          <Typography variant="body2">{`${currentUser.firstName} ${currentUser.lastName}`}</Typography>
          <Box sx={{ ml: 1, flexGrow: 0, flexShrink: 0 }}>
            {t("accountSettings.visibilitySettings.hide")}
            <Switch checked={!!currentUser.visible} onChange={(e) => handleAccountVisibilityChange(e.target.checked)} />
            {t("accountSettings.visibilitySettings.display")}
          </Box>
        </Box>
        {Object.entries(teams)
          .filter(([_, t]) => t.activated && t.adminIds?.includes(currentUser.id))
          .map(([_, team]) => (
            <Box display="flex" alignItems="center" justifyContent="space-between" fontSize="14px" key={team.id}>
              <Typography variant="body2">{`${team.name}`}</Typography>
              <Box sx={{ ml: 1, flexGrow: 0, flexShrink: 0 }}>
                {t("accountSettings.visibilitySettings.hide")}
                <Switch checked={!!team.visible} onChange={(e) => handleTeamVisibilityChange(team, e.target.checked)} />
                {t("accountSettings.visibilitySettings.display")}
              </Box>
            </Box>
          ))}
      </Box>
    )
  );
}
