import { AllSharingRequestsStatusEnum, SharingRequest } from "@syadem/kairos-pro-js";
import { useCallback, useEffect } from "react";
import { create } from "zustand";
import { useApis } from "../providers/Dependencies";
import { Apis } from "../../network/apis";

interface UseSharingRequests {
  isLoading: boolean;
  sharingRequests?: SharingRequest[];
  error?: Response | Error;
  fetchSharingRequests: () => void;
}

export function usePendingSharingRequests(): UseSharingRequests {
  const state = useStore();
  const fetchAction = useStore((state) => state.fetch);
  const apis = useApis();

  const refetch = useCallback(() => {
    fetchAction(apis, true);
  }, [fetchAction, apis]);

  useEffect(() => {
    fetchAction(apis, false);
  }, [fetchAction, apis]);

  return {
    ...state,
    fetchSharingRequests: refetch,
  };
}

interface StoreState {
  isLoading: boolean;
  sharingRequests?: SharingRequest[];
  error?: Response | Error;
  fetch: (apis: Apis, refresh: boolean) => Promise<void>;
}

const useStore = create<StoreState>((set, get) => ({
  isLoading: false,
  sharingRequests: undefined,
  error: undefined,
  fetch: async (apis, refresh) => {
    if (get().isLoading && !refresh) {
      return;
    }
    set({ isLoading: true, error: undefined });
    try {
      // TODO: Wrap this inside a query
      const { sharingRequests } = await apis.pro.sharingRequestApi.allSharingRequests(AllSharingRequestsStatusEnum.Pending)
      set({ isLoading: false, sharingRequests, error: undefined });
    } catch (error) {
      set({ isLoading: false, sharingRequests: undefined, error: error as Response | Error });
    }
  },
}));
