import { Team as ApiTeam } from "@syadem/kairos-team-js";
import { Team } from "../../domain/team";

export function parseApiTeam(apiTeam: ApiTeam, organizationId: string) : Team {
  return {
    id: apiTeam.id,
    organizationId,
    name: apiTeam.name,
    fullName: apiTeam.fullName,
    presentationName: apiTeam.presentationName,
    address: apiTeam.address,
    city: apiTeam.city,
    zipCode: apiTeam.zipCode,
    memberIds: apiTeam.memberIds || [],
    adminIds: apiTeam.adminIds || [],
    activated: apiTeam.activated,
    visible: apiTeam.visible,
    establishmentId: apiTeam.establishmentId
  }
}