import { Alert, Box, LinearProgress, List, Paper, Typography } from "@mui/material";
import { Professional, Team } from "@syadem/ariane-js";
import { VaccinationAct as TeamVaccinationAct, VaccinationActCertificator } from "@syadem/kairos-team-js";
import { VaccinationAct as ProVaccinationAct } from "@syadem/kairos-pro-js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IconMedicalInstrumentSyringe } from "../../components/icons/IconMedicalInstrumentSyringe";
import { EmptyListPlaceholder } from "../../components/shared/EmptyListPlaceholder";
import { VaccinationActListSort } from "../../components/VaccinationActListSort";
import VaccinationActsByDiseaseGroups from "../../components/VaccinationActsByDiseaseGroups";
import VaccinationActsByList from "../../components/VaccinationActsByList";
import { useArianeApi, useDaphne } from "../../hooks";
import { useI18n } from "../../hooks/useI18n";
import { useUnsharedHealthRecord } from "../../hooks/useUnsharedHealthRecord";
import { useUnsharedHealthRecordVaccinationActs } from "../../hooks/useUnsharedHealthRecordVaccinationActs";
import { theme } from "../../layout/Theme";

export function UnsharedVaccinationActs() {
  const { t } = useI18n();
  const { id } = useParams() as { id: string };
  const daphne = useDaphne();
  const arianeApi = useArianeApi();
  const { unsharedHealthRecord } = useUnsharedHealthRecord(id);
  const { isLoading, unsharedVaccinationActs, error } = useUnsharedHealthRecordVaccinationActs(id);
  const [sortedVaccinationActs, setSortedVaccinationActs] = useState<ProVaccinationAct[] | TeamVaccinationAct[]>([]);
  const [certificators, setCertificators] = useState<(Professional | Team)[] | undefined>(undefined);
  const [listType, setListType] = useState("byDiseases");

  useEffect(() => {
    (async () => {
      if (unsharedVaccinationActs) {
        const certificatorIds = unsharedVaccinationActs
          .filter((act) => act.certificator !== null)
          .map((act) => (act.certificator as VaccinationActCertificator).id);

        let actCertificators: (Professional | Team)[] = [];

        if (certificatorIds.length > 0) {
          try {
            actCertificators = await arianeApi.professionals.searchByIds([...new Set(certificatorIds)]);
          } catch (error) {
            actCertificators = [];
          }
        }

        setCertificators(actCertificators);
      }
    })();
  }, [arianeApi?.professionals, unsharedVaccinationActs]);

  return (
    <>
      {unsharedVaccinationActs && daphne && (
        <VaccinationActListSort
          vaccinationActs={unsharedVaccinationActs}
          setSortedVaccinationActs={setSortedVaccinationActs}
          daphne={daphne}
          setListType={setListType}
        />
      )}

      <Paper sx={{ overflow: "hidden", backgroundColor: " inherit", mt: 2 }} elevation={0}>
        {error && (
          <Typography sx={{ my: 4, mx: 2 }} align="center" component="div">
            <Alert severity="error">{t("vaccines.error")}</Alert>
          </Typography>
        )}

        {isLoading && <LinearProgress variant="query" />}

        {unsharedHealthRecord && daphne && certificators ? (
          sortedVaccinationActs.length > 0 ? (
            <List sx={{ width: "100%" }}>
              {listType === "byList" && (
                <VaccinationActsByList
                  daphne={daphne}
                  certificators={certificators || []}
                  healthRecord={unsharedHealthRecord}
                  sortedVaccinationActs={sortedVaccinationActs}
                  showActions={false}
                />
              )}
              {listType === "byDiseases" && (
                <VaccinationActsByDiseaseGroups
                  vaccinationActs={sortedVaccinationActs}
                  daphne={daphne}
                  certificators={certificators}
                  healthRecord={unsharedHealthRecord}
                />
              )}
            </List>
          ) : (
            <EmptyListPlaceholder
              title={t("vaccines.no_vaccinations")}
              icon={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: theme.palette.primary[500],
                    ".iconSyringe": {
                      height: "35px !important",
                    },
                  }}
                >
                  <IconMedicalInstrumentSyringe />
                </Box>
              }
            >
              {t("vaccines.no_vaccinations_sub")}
            </EmptyListPlaceholder>
          )
        ) : null}
      </Paper>
    </>
  );
}
