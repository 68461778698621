import { useCallback, useEffect, useState } from "react";

let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;

export const useCopyToClipBoard = (text: string): [boolean, () => Promise<void>] => {
  const [copied, setCopied] = useState<boolean>(false);

  const copyToClipBoard = useCallback(async () => {
    try {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if ("clipboard" in navigator) {
        await navigator.clipboard.writeText(text);
      } else {
        document.execCommand("copy", true, text);
      }

      setCopied(true);

      timeoutId = setTimeout(() => setCopied(false), 3000);
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  }, [text]);

  useEffect(() => {
    setCopied(false);
    clearTimeout(timeoutId);
  }, []);

  return [copied, copyToClipBoard];
};
