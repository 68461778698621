import { useCallback, useState } from "react";
import { useI18n } from "../../../hooks/useI18n";
import { SubscriptionComponentProps } from "./Subscription";
import { Box, Button, Chip, Paper, Typography } from "@mui/material";
import { theme } from "../../../layout/Theme";
import { dayjs } from "../../../../utils/dayjs";
import { ResponseError, SubscriptionStatusEnum } from "@syadem/kairos-subscription-js";
import StyledDialog from "../../../components/mui/StyledDialog";
import { DeleteButton } from "../../../components/mui/StyledButtons";
import { LoadingButton } from "@mui/lab";
import { useServiceBus } from "../../../hooks/useServiceBus";

export function SubscriptionSummary(props: SubscriptionComponentProps) {
  const { subscriptionOrganization, setErrorDisplayed, setSuccessDisplayed, setErrorMessage, setSuccessMessage } = props;
  const { t } = useI18n();
  const serviceBus = useServiceBus();
  const [cancellationModalOpen, setCancellationModalOpen] = useState<boolean>(false);
  const [resumingModalOpen, setResumingModalOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const statusColor: { [status: string]: "success" | "info" | "warning" | "error" } = {
    trialing: "info",
    active: "success",
    paused: "warning",
    past_due: "error",
    canceled: "error",
    unpaid: "error"
  };

  const handleStopSubscriptionCancellation = useCallback(
    async () => {
      setErrorDisplayed(false);
      setSuccessDisplayed(false);
      setIsSubmitting(true);

      try {
        await serviceBus.dispatch({
          type: "stopSubscriptionCancellation",
          organizationId: subscriptionOrganization.id
        })

        setSuccessMessage(t("subscriptions.successfullyReactivated"));
        setSuccessDisplayed(true);
      } catch (e) {
        setErrorMessage(t("common.alerts.alert_notification"));
        setErrorDisplayed(true);
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      subscriptionOrganization,
      serviceBus,
      setErrorDisplayed,
      setSuccessDisplayed,
      setSuccessMessage,
      setErrorMessage,
      setIsSubmitting,
      t
    ]
  );

  return (
    <Paper
      sx={{
        maxWidth: "sm",
        marginX: "auto",
        overflow: "hidden",
        border: `solid 1px ${theme.palette.neutral[200]}`,
        paddingX: 4,
        paddingY: 2
      }}
      elevation={0}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography fontWeight={500} color={theme.palette.neutral["600"]} fontSize={18}>
          {subscriptionOrganization.subscription.product.name}
        </Typography>
        <Chip
          label={t(`subscriptions.statuses.${subscriptionOrganization.subscription.status}`)}
          color={statusColor[subscriptionOrganization.subscription.status]}
          variant="outlined"
        />
      </Box>
      <Typography color={theme.palette.neutral["600"]}>
        {`${subscriptionOrganization.subscription.quantity * 5} ${t("subscriptions.seats")} - 
          ${subscriptionOrganization.subscription.coupon ?
            t(
              "subscriptions.amountWithDiscount",
              { amount: subscriptionOrganization.subscription.quantity * 299 - subscriptionOrganization.subscription.coupon.amountOff / 100 }
            ) : ""
          }
          ${t("subscriptions.amount", { amount: subscriptionOrganization.subscription.quantity * 299 })}`}
      </Typography>
      {subscriptionOrganization.subscription.coupon && (
        <Typography color={theme.palette.neutral["600"]}>
          {t(
              "subscriptions.appliedDiscount",
              {
                name: subscriptionOrganization.subscription.coupon.name,
                amount: subscriptionOrganization.subscription.coupon.amountOff / 100
              }
          )}
        </Typography>
      )}
      <Typography color={theme.palette.neutral["600"]}>
        {t("subscriptions.subscriptionStartedOn", { date: dayjs(subscriptionOrganization.subscription.startDate).format("DD/MM/YYYY") })}
      </Typography>
      {subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Trialing && (
        <Typography color={theme.palette.neutral["600"]}>
          {t("subscriptions.subscriptionTrialEndsOn", { date: dayjs(subscriptionOrganization.subscription.trialEndDate).format("DD/MM/YYYY") })}
        </Typography>
      )}
      {subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Active && !subscriptionOrganization.subscription.cancelAtPeriodEnd && (
        <Typography color={theme.palette.neutral["600"]}>
          {t("subscriptions.subscriptionNextRenewal", { date: dayjs(subscriptionOrganization.subscription.currentPeriodEndDate).format("DD/MM/YYYY") })}
        </Typography>
      )}
      {(subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Trialing ||
        subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Active) &&
        subscriptionOrganization.subscription.cancelAtPeriodEnd && (
          <>
            <Typography color={theme.palette.neutral["600"]}>
              {t("subscriptions.subscriptionEndsOn", { date: dayjs(subscriptionOrganization.subscription.currentPeriodEndDate).format("DD/MM/YYYY") })}
            </Typography>
            <Box display="flex" justifyContent="center" mt={2}>
              <LoadingButton variant="outlined" onClick={handleStopSubscriptionCancellation} loading={isSubmitting}>
                {t("subscriptions.stopCancellation")}
              </LoadingButton>
            </Box>
          </>
      )}
      {(subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Trialing ||
        subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Active) &&
        !subscriptionOrganization.subscription.cancelAtPeriodEnd && (
          <>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button variant="outlined" color="error" onClick={() => setCancellationModalOpen(true)}>
                {t("subscriptions.cancel")}
              </Button>
            </Box>
            <CancelSubscriptionModal
              {...props}
              cancellationModalOpen={cancellationModalOpen}
              setCancellationModalOpen={setCancellationModalOpen}
            />
          </>
      )}
      {(subscriptionOrganization.subscription.status == SubscriptionStatusEnum.PastDue ||
        subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Unpaid ||
        subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Paused) && (
          <>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="outlined"
                disabled={!subscriptionOrganization.defaultPaymentMethod}
                onClick={() => setResumingModalOpen(true)}
              >
                {t("subscriptions.resume")}
              </Button>
            </Box>
            <ResumeSubscriptionModal
              {...props}
              resumingModalOpen={resumingModalOpen}
              setResumingModalOpen={setResumingModalOpen}
            />
          </>
      )}
    </Paper>
  );
}

interface CancelSubscriptionModalProps extends SubscriptionComponentProps {
  cancellationModalOpen: boolean;
  setCancellationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function CancelSubscriptionModal(props: CancelSubscriptionModalProps) {
  const { subscriptionOrganization, setErrorDisplayed, setSuccessDisplayed, setErrorMessage, setSuccessMessage, cancellationModalOpen, setCancellationModalOpen } = props;
  const { t } = useI18n();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const serviceBus = useServiceBus();

  const handleSubscriptionCancellation = useCallback(
    async () => {
      setErrorDisplayed(false);
      setSuccessDisplayed(false);
      setIsSubmitting(true);

      try {
        await serviceBus.dispatch({
          type: "cancelSubscription",
          organizationId: subscriptionOrganization.id
        })

        setSuccessMessage(t("subscriptions.successfullyCanceled"));
        setSuccessDisplayed(true);
      } catch (e) {
        setErrorMessage(t("common.alerts.alert_notification"));
        setErrorDisplayed(true);
      } finally {
        setIsSubmitting(false);
        setCancellationModalOpen(false);
      }
    },
    [
      subscriptionOrganization,
      serviceBus,
      setErrorDisplayed,
      setSuccessDisplayed,
      setSuccessMessage,
      setErrorMessage,
      setIsSubmitting,
      setCancellationModalOpen,
      t
    ]
  );

  return (
    <StyledDialog
      open={cancellationModalOpen}
      onClose={() => setCancellationModalOpen(false)}
      title={t("subscriptions.cancel")}
      dialogActions={
        <DeleteButton
          onClick={handleSubscriptionCancellation}
          autoFocus
          loading={isSubmitting}
        >
          {t("subscriptions.cancel")}
        </DeleteButton>
      }
    >
      <Typography color={theme.palette.neutral["600"]}>
        {t("subscriptions.aboutCancellation1")}
      </Typography>
      <Typography color={theme.palette.neutral["600"]}>
        {t("subscriptions.aboutCancellation2", { date: dayjs(subscriptionOrganization.subscription.currentPeriodEndDate).format("DD/MM/YYYY") })}
      </Typography>
      <Typography color={theme.palette.neutral["600"]}>
        {t("subscriptions.aboutCancellation3")}
      </Typography>
    </StyledDialog>
  );
}

interface ResumeSubscriptionModalProps extends SubscriptionComponentProps {
  resumingModalOpen: boolean;
  setResumingModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ResumeSubscriptionModal(props: ResumeSubscriptionModalProps) {
  const { subscriptionOrganization, setErrorDisplayed, setSuccessDisplayed, setErrorMessage, setSuccessMessage, resumingModalOpen, setResumingModalOpen } = props;
  const { t } = useI18n();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const serviceBus = useServiceBus();

  const handleUnpaidSubscriptionResuming = useCallback(
    async () => {
      setErrorDisplayed(false);
      setSuccessDisplayed(false);
      setIsSubmitting(true);

      try {
        await serviceBus.dispatch({
          type: "resumeSubscription",
          organizationId: subscriptionOrganization.id
        })

        setSuccessMessage(t("subscriptions.successfullyReactivated"));
        setSuccessDisplayed(true);
      } catch (e) {
        if ((e instanceof ResponseError && e.response.status === 402)) {
          setErrorMessage(t("subscriptions.paymentFailed"));
        } else {
          setErrorMessage(t("common.alerts.alert_notification"));
        }
        setErrorDisplayed(true);
      } finally {
        setIsSubmitting(false);
        setResumingModalOpen(false);
      }
    },
    [
      subscriptionOrganization,
      serviceBus,
      setErrorDisplayed,
      setSuccessDisplayed,
      setSuccessMessage,
      setErrorMessage,
      setIsSubmitting,
      setResumingModalOpen,
      t
    ]
  );

  return (
    <StyledDialog
      open={resumingModalOpen}
      onClose={() => setResumingModalOpen(false)}
      title={t("subscriptions.resume")}
      dialogActions={
        <LoadingButton
          onClick={handleUnpaidSubscriptionResuming}
          autoFocus
          loading={isSubmitting}
        >
          {t("subscriptions.resume")}
        </LoadingButton>
      }
    >
      {subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Paused ? (
        <>
          <Typography color={theme.palette.neutral["600"]}>
          {t("subscriptions.aboutPausedResuming1")}
          </Typography>
          <Typography color={theme.palette.neutral["600"]}>
            {t("subscriptions.aboutPausedResuming2")}
          </Typography>
          <Typography color={theme.palette.neutral["600"]}>
            {t("subscriptions.aboutPausedResuming3")}
          </Typography>
        </>
      ) : (
        <>
          <Typography color={theme.palette.neutral["600"]}>
            {t("subscriptions.aboutUnpaidResuming1")}
          </Typography>
          <Typography color={theme.palette.neutral["600"]}>
            {t("subscriptions.aboutUnpaidResuming2")}
          </Typography>
          <Typography color={theme.palette.neutral["600"]}>
            {t("subscriptions.aboutUnpaidResuming3")}
          </Typography>
        </>
      )}
    </StyledDialog>
  );
}