import { useCallback, useEffect, useState } from "react";
import { useI18n } from "../../../hooks/useI18n";
import { useApis } from "../../../providers/Dependencies";
import { SubscriptionComponentProps } from "./Subscription";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { theme } from "../../../layout/Theme";
import { InvalidRequestError, ResponseError, SubscriptionStatusEnum } from "@syadem/kairos-subscription-js";
import GroupsIcon from '@mui/icons-material/Groups';
import { LoadingButton } from "@mui/lab";
import StyledDialog from "../../../components/mui/StyledDialog";
import { useServiceBus } from "../../../hooks/useServiceBus";
import { useCountryConfig } from "../../../hooks/useCountryConfig";

export function SubscriptionQuantity(props: SubscriptionComponentProps) {
  const { subscriptionOrganization, setErrorDisplayed, setSuccessDisplayed, setErrorMessage, setSuccessMessage } = props;
  const { t } = useI18n();
  const apis = useApis();
  const serviceBus = useServiceBus();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<string>(subscriptionOrganization.subscription.quantity.toString());
  const [membersCount, setMembersCount] = useState<number | undefined>(undefined);
  const [upcomingInvoiceAmount, setUpcomingInvoiceAmount] = useState<number | undefined>(undefined);
  const [quantityUpdateModalOpen, setQuantityUpdateModalOpen] = useState<boolean>(false);
  const { subscription } = useCountryConfig();

  useEffect(() => {
    (async () => {
      // TODO: Wrap this inside a query
      const organizationMembers = (await apis.team.organizationApi.indexMembers(subscriptionOrganization.id)).professionalAccounts;
      setMembersCount(organizationMembers.length);
    })();
  }, [apis, subscriptionOrganization]);

  const handleSubscriptionQuantityUpdate = useCallback(
    async () => {
      if (quantity.length == 0) { return }

      const parsedQuantity = parseInt(quantity);

      if (parsedQuantity != subscriptionOrganization.subscription.quantity) {
        setErrorDisplayed(false);
        setSuccessDisplayed(false);
        setIsSubmitting(true);
  
        try {
          await serviceBus.dispatch({
            type: "updateSubscritpionQuantity",
            organizationId: subscriptionOrganization.id,
            subscription: { quantity: parsedQuantity }
          })

          setSuccessMessage(t("subscriptions.quantitySuccessfullyUpdated"));
          setSuccessDisplayed(true);
        } catch (e) {
          if (e instanceof ResponseError && e.response.status === 403) {
            const code = ((await e.response.json()) as InvalidRequestError).error.code;

            if (code == "forbidden_downgrade") {
              setErrorMessage(t("subscriptions.forbiddenDowngrade"));
            } else {
              setErrorMessage(t("common.alerts.alert_notification"));
            }
          } else if ((e instanceof ResponseError && e.response.status === 402)) {
            setErrorMessage(t("subscriptions.paymentFailed"));
          } else {
            setErrorMessage(t("common.alerts.alert_notification"));
          }
          setErrorDisplayed(true);
        } finally {
          setIsSubmitting(false);
          setQuantityUpdateModalOpen(false);
        }
      }
    },
    [
      subscriptionOrganization,
      serviceBus,
      setErrorDisplayed,
      setSuccessDisplayed,
      setSuccessMessage,
      setErrorMessage,
      setIsSubmitting,
      quantity,
      t
    ]
  );

  const handleSubscriptionQuantityUpdateModalOpen = useCallback(
    async () => {
      if (!subscription || quantity.length == 0) { return }

      const parsedQuantity = parseInt(quantity);

      if (parsedQuantity == subscriptionOrganization.subscription.quantity) { return }

      if (parsedQuantity > subscriptionOrganization.subscription.quantity) {
        const upcomingInvoice = await subscription?.queries.subscriptionUpcomingInvoiceQuery.call({
          organizationId: subscriptionOrganization.id,
          quantity: parsedQuantity
        });
        setUpcomingInvoiceAmount(upcomingInvoice.amount);
      }

      setQuantityUpdateModalOpen(true);
    }, [subscription, quantity, subscriptionOrganization]
  )

  return (
    <Paper
      sx={{
        maxWidth: "sm",
        marginX: "auto",
        marginTop: 1,
        overflow: "hidden",
        border: `solid 1px ${theme.palette.neutral[200]}`,
        paddingX: 4,
        paddingY: 2
      }}
      elevation={0}
    >
      <Box display="flex" alignItems="center" mb={1}>
        <GroupsIcon sx={{ color: theme.palette.neutral["600"] }}/>
        <Typography fontWeight={500} color={theme.palette.neutral["600"]} fontSize={18} ml={1}>
          {t("subscriptions.amountOfSeats")}
        </Typography>
      </Box>
      <Typography color={theme.palette.neutral["600"]}>
        {t("subscriptions.seatsOccupiedAmount", { amount: membersCount })}
      </Typography>
      <Typography color={theme.palette.neutral["600"]}>
        {t("subscriptions.adjustSeats")}
      </Typography>
      <Grid container alignItems="center" mt={1}>
        <Grid item xs={2}>
          <TextField
            type="number"
            inputProps={{ step: 1, min: 1, style: { color: theme.palette.neutral["600"] } }}
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <Typography color={theme.palette.neutral["600"]} ml={1}>{t("subscriptions.perFiveSeats")}</Typography>
        </Grid>
        <Grid item xs={3}>
          {quantity.length > 0 &&(
            <Typography color={theme.palette.neutral["600"]}>
              {t("subscriptions.totalCalculatedSeats", { seats: parseInt(quantity) * 5 })}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          {quantity.length > 0 &&(
            <Typography color={theme.palette.neutral["600"]}>
              {t(
                "subscriptions.totalCalculatedAmount",
                { amount: subscriptionOrganization.subscription.coupon ?
                  parseInt(quantity) * 299 - subscriptionOrganization.subscription.coupon.amountOff / 100 :
                  parseInt(quantity) * 299 }
              )}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" mt={2}>
        {subscriptionOrganization.subscription.status != SubscriptionStatusEnum.Trialing &&
          subscriptionOrganization.subscription.status != SubscriptionStatusEnum.Active && (
            <Button variant="outlined" disabled>
              {t("subscriptions.adjustSeatsQuantity")}
            </Button>
        )}
        {subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Trialing && (
          <LoadingButton variant="outlined" onClick={handleSubscriptionQuantityUpdate} loading={isSubmitting}>
            {t("subscriptions.adjustSeatsQuantity")}
          </LoadingButton>
        )}
        {subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Active && (
          <>
            <Button variant="outlined" onClick={handleSubscriptionQuantityUpdateModalOpen}>
              {t("subscriptions.adjustSeatsQuantity")}
            </Button>
            <StyledDialog
              open={quantityUpdateModalOpen}
              onClose={() => setQuantityUpdateModalOpen(false)}
              title={t("subscriptions.adjustSeatsQuantity")}
              dialogActions={
                <LoadingButton
                  onClick={handleSubscriptionQuantityUpdate}
                  autoFocus
                  loading={isSubmitting}
                >
                  {t("subscriptions.adjustSeatsQuantity")}
                </LoadingButton>
              }
            >
              {parseInt(quantity) > subscriptionOrganization.subscription.quantity ? (
                upcomingInvoiceAmount && (
                  <>
                    <Typography color={theme.palette.neutral["600"]}>
                      {t("subscriptions.upgradeSeats1")}
                    </Typography>
                    <Typography color={theme.palette.neutral["600"]}>
                      {t("subscriptions.upgradeSeats2", { amount: upcomingInvoiceAmount / 100 })}
                    </Typography>
                    <Typography color={theme.palette.neutral["600"]}>
                      {t("subscriptions.upgradeSeats3")}
                    </Typography>
                  </>

                )
              ) : (
                <>
                  <Typography color={theme.palette.neutral["600"]}>
                    {t("subscriptions.downgradeSeats1")}
                  </Typography>
                  <Typography color={theme.palette.neutral["600"]}>
                    {t("subscriptions.downgradeSeats2")}
                  </Typography>
                </>
              )}
            </StyledDialog>
          </>
        )}
      </Box>
    </Paper>
  );
}