import LoadingButton from "@mui/lab/LoadingButton";
import {
  Grid,
  Typography,
  Paper,
  Alert
} from "@mui/material";
import { PageLayout } from "../../layout/PageLayout";
import { Formik } from "formik";
import { ReactNode, useMemo, useState } from "react";
import { TranslateFunction, createTranslateFn } from "../../../utils/formUtils";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";
import { CitiesAutocomplete } from "../../components/CitiesAutocomplete";
import { StyledInput } from "../../components/mui/StyledInput";
import { City } from "@syadem/ariane-js";
import yup from "../../../utils/yup";
import { useAllOrganizations } from "../../../store";
import { useAppContext } from "../../hooks/useAppContext";
import { useServiceBus } from "../../hooks/useServiceBus";
import { useCountryConfig } from "../../hooks/useCountryConfig";

export function NewTeam() {
  const { t } = useI18n();
  const { organizationId } = useAppContext();
  const translateErrors: TranslateFunction = useMemo(() => createTranslateFn(t), [t]);
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | undefined>(undefined);
  const [teamCity, setTeamCity] = useState<City | undefined>(undefined);
  const [createSuccess, setCreateSuccess] = useState<boolean>(false);
  const serviceBus = useServiceBus();
  const organizations = useAllOrganizations();
  const { zipCode, phoneNumber } = useCountryConfig();

  const teamCreationSchema = yup.object({
    name: yup.string().max(50).required(),
    address: yup.string().required(),
    city: yup.string().required(),
    zipCode: yup
        .string()
        .required()
        .min(zipCode.length)
        .max(zipCode.length)
        .matches(zipCode.regex),
    phoneNumber: yup
        .string()
        .required()
        .min(phoneNumber.minLength)
        .max(phoneNumber.maxLength)
        .matches(phoneNumber.regex),
  });

  return (
    <PageLayout title={t("common.interface.newTeam")}>
      {!createSuccess && (
        <Alert severity="info" sx={{ maxWidth: "md", marginX: "auto", mb: 2 }}>
          {t("team.createPending.description")}
        </Alert>
      )}
      <Paper
        sx={{
          overflow: "hidden",
          backgroundColor: "background.paper",
          border: `solid 1px ${theme.palette.neutral[200]}`,
          maxWidth: "md",
          marginX: "auto"
        }}
        elevation={0}
      >
        {createSuccess && (
          <Grid container justifyContent="center" sx={{ padding: "20px" }}>
            <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
              {t("team.createPending.success")}
            </Typography>
          </Grid>
        )}
        {!createSuccess && organizations && organizationId && (
          <Formik
            initialValues={{
              name: "",
              address: "",
              city: "",
              zipCode: "",
              phoneNumber: ""
            }}
            validationSchema={teamCreationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              if (organizationId) {
                setErrorMessage(undefined);
                try {
                  await serviceBus.dispatch({
                    type: "createTeam",
                    organizationId,
                    team: values
                  })

                  setCreateSuccess(true);
                } catch (e) {
                  setSubmitting(false);
                  setErrorMessage(t("common.alerts.alert_notification"));
                }
              }
            }}
          >
            {({
              errors,
              getFieldProps,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid container rowSpacing={2} columnSpacing={4} justifyContent="space-between" padding={4}>
                  {errorMessage && (
                    <Grid item xs={12}>
                      <Alert severity="error">{errorMessage}</Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <StyledInput
                      disabled
                      name="organization"
                      value={organizations[organizationId].name}
                      fullWidth
                      size="small"
                      label={t("organization.name")}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <StyledInput
                      id="name"
                      fullWidth
                      size="small"
                      error={touched.name && !!errors.name}
                      errorMessage={errors.name}
                      touched={touched.name}
                      translateErrors={translateErrors}
                      label={t("team.name")}
                      placeholder={t("team.namePlaceholder")}
                      required
                      testId="name"
                      {...getFieldProps("name")}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <StyledInput
                      id="phoneNumber"
                      fullWidth
                      size="small"
                      error={touched.phoneNumber && !!errors.phoneNumber}
                      errorMessage={errors.phoneNumber}
                      touched={touched.phoneNumber}
                      translateErrors={translateErrors}
                      label={t("team.phoneNumber")}
                      required
                      placeholder={phoneNumber.placeholder}
                      testId="phoneNumber"
                      {...getFieldProps("phoneNumber")}
                      />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <StyledInput
                      id="address"
                      fullWidth
                      size="small"
                      error={touched.address && !!errors.address}
                      errorMessage={errors.address}
                      touched={touched.address}
                      translateErrors={translateErrors}
                      label={t("team.address")}
                      required
                      testId="address"
                      {...getFieldProps("address")}
                      />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                      <StyledInput
                      id="zipCode"
                      fullWidth
                      size="small"
                      error={touched.zipCode && !!errors.zipCode}
                      errorMessage={errors.zipCode}
                      touched={touched.zipCode}
                      translateErrors={translateErrors}
                      label={t("team.zipCode")}
                      required
                      placeholder={zipCode.placeholder}
                      testId="zipCode"
                      {...getFieldProps("zipCode")}
                      />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <CitiesAutocomplete
                      label={t("team.city")}
                      error={touched.city && !!errors.city}
                      errorMessage={errors.city}
                      touched={touched.city}
                      translateErrors={translateErrors}
                      placeholder={t("team.city")}
                      required
                      cityCode={teamCity ? teamCity.code : undefined}
                      onChange={(city) => {
                        setFieldTouched("city", true);
                        if (!city) {
                          setFieldValue("city", "");
                          setTeamCity(undefined);
                        } else {
                          setFieldValue("city", city.name);
                          setTeamCity(city);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item container justifyContent="space-between" xs={12} sx={{ marginTop: "30px" }}>
                    <LoadingButton
                      variant="contained"
                      disableElevation
                      type="submit"
                      loading={isSubmitting}
                      data-testid="submit"
                    >
                      {t("common.cta.add")}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        )}
        </Paper>
    </PageLayout>
  );
}
