import { Box, Typography } from "@mui/material";
import { Daphne } from "@syadem/daphne-js";
import { CertificationMethodEnum } from "@syadem/kairos-pro-js";
import { getTranslatedFieldByLocale } from "../../utils/translatedFields";
import { useDiseasesByVaccine } from "../hooks/useDiseasesByVaccine";
import { useI18n } from "../hooks/useI18n";
import { theme } from "../layout/Theme";

export function isCertified(certificationMethod: CertificationMethodEnum | null) {
  return (
    certificationMethod === CertificationMethodEnum.Execution || certificationMethod === CertificationMethodEnum.Proof
  );
}
interface VaccineProps {
  vaccineId: string;
  daphne: Daphne;
  displayDiseases?: boolean;
  showActions?: boolean;
}

export function Vaccine({ vaccineId, daphne, displayDiseases = true, showActions = false }: VaccineProps) {
  const vaccine = daphne.repositories.vaccines.find(vaccineId);
  const { diseases } = useDiseasesByVaccine(vaccine);
  const { locale } = useI18n();

  return (
    <>
      {vaccine && (
        <Box sx={{ pr: { xs: 0, md: showActions ? "250px" : 0 } }}>
          <Typography fontWeight="500" color={theme.palette.neutral[600]}>
            {getTranslatedFieldByLocale(locale, vaccine.name)}
          </Typography>
          {displayDiseases && diseases && (
            <Typography color={theme.palette.neutral[600]}>
              {diseases.map((disease) => getTranslatedFieldByLocale(locale, disease.name)).join(", ")}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
}
