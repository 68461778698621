export function validateSecurityNumber(value: string | undefined): boolean {
  const cleanedValue = value ? value.trim().replaceAll(" ", "").replaceAll("_", "") : "";

  if (cleanedValue == "") { return true; }

  const regex = /([12])([0-9]{2})(0[1-9]|1[0-2])(2[AaBb]|[0-9]{2})([0-9]{3})([0-9]{3})([0-9]{2})/g;

  if (!cleanedValue.match(regex)) { return false; }

  const convertedValue = cleanedValue.replace(/2[Aa]/gi, "19").replace(/2[Bb]/gi, "18");
  const baseNumber = parseInt(convertedValue.slice(0, 13));
  const key = parseInt(convertedValue.slice(13));
  const computedKey = 97 - (baseNumber % 97);

  return key === computedKey;
}
