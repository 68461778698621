import { TeamApiInterface, TeamMember } from "@syadem/kairos-team-js";
import { AppStore } from "../store";

interface Payload {
  organizationId: string;
  teamId: string;
}

export class TeamMembersQuery {
  constructor(
    private readonly store: AppStore,
    private readonly teamApi: TeamApiInterface
  ) {}

  async call({ organizationId, teamId }: Payload): Promise<TeamMember[]> {
    const response = await this.teamApi.indexTeamMembers(organizationId, teamId);
    this.store.setState({ teamMembers: response.teamMembers });
    return response.teamMembers;
  }
}
