import CloseIcon from "@mui/icons-material/Close";
import { Alert, useMediaQuery } from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { ReactNode } from "react";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";
import { CancelButton } from "../mui/StyledButtons";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    border: "none",
  },
  "& .MuiDialogActions-root": {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    justifyContent: "space-between",
  },
}));

interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, px: 3 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface CustomDialogProps extends DialogProps {
  children: ReactNode;
  title: string;
  open: boolean;
  errorMessage?: string;
  onClose: () => void;
  dialogActions?: ReactNode;
  hideDialogActions?: boolean;
}

export default function StyledDialog({
  children,
  title,
  open,
  onClose,
  dialogActions,
  errorMessage,
  hideDialogActions = false,
  ...other
}: CustomDialogProps) {
  const { t } = useI18n();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <CustomDialog onClose={onClose} open={open} fullScreen={fullScreen} fullWidth {...other}>
        <BootstrapDialogTitle onClose={onClose}>{title}</BootstrapDialogTitle>
        <DialogContent dividers>
          {children}
          {errorMessage && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Alert>
          )}
        </DialogContent>
        {!hideDialogActions && (
          <DialogActions>
            <CancelButton onClick={onClose} variant="contained">
              {t("common.cta.cancel")}
            </CancelButton>
            <div>{dialogActions}</div>
          </DialogActions>
        )}
      </CustomDialog>
    </div>
  );
}
