import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { ProfessionalAccount } from "@syadem/kairos-team-js";
import { capitalize } from "lodash-es";
import { useI18n } from "../hooks/useI18n";
import { theme } from "../layout/Theme";
import IconDoctor from "./icons/IconDoctor";

interface ProShareListItemProps {
  pro: ProfessionalAccount;
}

export function ProShareListItem(props: ProShareListItemProps) {
  const { pro } = props;
  const { t } = useI18n();

  const showPro = (pro: ProfessionalAccount) => {
    if (pro.firstName && pro.lastName) {
      return <Typography>{`${capitalize(pro.firstName)} ${capitalize(pro.lastName)}`}</Typography>;
    } else if (pro.email) {
      return <Typography>{pro.email}</Typography>;
    } else {
      <></>;
    }
  };

  return (
    <ListItem
      sx={{
        px: 3,
        py: 2,
        mb: 1,
        border: `solid 1px ${theme.palette.neutral[200]}`,
        borderRadius: 1,
        bgcolor: "background.paper",
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ background: theme.palette.primary[100] }}>
          <IconDoctor color={theme.palette.primary[500]} style={{ height: "20px" }} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={showPro(pro)} secondary={t("users.pro")} />
    </ListItem>
  );
}
