import { ProfessionalAccount, ProfessionalAccountApiInterface } from "@syadem/kairos-pro-js";
import { Service } from "./service";
import { AppStore } from "../store";
import { Success } from "../actionResult";

interface Payload {
  professionalAccount: {
    visible: boolean;
  };
}

export class UpdateProfessionalAccountVisibilityService implements Service<Payload, ProfessionalAccount> {
  constructor(
    private readonly store: AppStore,
    private readonly professionalAccountApi: ProfessionalAccountApiInterface
  ) {}

  async call({ professionalAccount }: Payload): Promise<Success<ProfessionalAccount>> {
    const currentAccount = (await this.professionalAccountApi.updateProfessionalAccountVisibility({ professionalAccount })).professionalAccount;
    this.store.setState({ currentAccount });

    return {
      type: "success",
      value: currentAccount
    };
  }
}
