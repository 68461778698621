export function CapitalizeFirstNames(value: string): string {
  return value
    .split(' ')
    .map(name =>
      name
        .split('-')
        .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
        .join('-')
    )
    .join(' ');
}
