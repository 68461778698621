import { UserManager } from "oidc-client-ts";
import { Service } from "./service";

export class SignOutService implements Service<void, void> {
  constructor(private readonly window: Window, private readonly oidcManager: UserManager) {}

  async call() {
    this.oidcManager.signoutRedirect({ post_logout_redirect_uri: this.window.location.href });
  }
}
