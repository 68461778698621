import { useLocation } from "react-router-dom";

interface AppContext {
  organizationId: string | null;
  teamId: string | null
}

export function useAppContext(): AppContext {
  const { pathname } = useLocation();

  const uuidRegex = "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}";
  const organizationRegex = new RegExp(`organizations/(${uuidRegex})`);
  const teamRegex = new RegExp(`teams/(${uuidRegex})`);
  const organizationMatch = pathname.match(organizationRegex);
  const teamMatch = pathname.match(teamRegex);

  return {
    organizationId: organizationMatch ? organizationMatch[1] : null,
    teamId: teamMatch ? teamMatch[1] : null
  }
}