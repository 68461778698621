import { parseApiOrganization } from "../network/parsers/parseApiOrganization";
import { OrganizationApiInterface } from "@syadem/kairos-team-js";
import { CreateSubscriptionService } from "./CreateSubscriptionService";
import { AppStore } from "../store";
import { Success } from "../actionResult";
import { Organization } from "../domain/organization";
import { Service } from "./service";

interface Payload {
  organization: {
    name: string;
    address: string;
    city: string;
    zipCode: string;
    phoneNumber: string;
  };
}

// TODO: Move this in CountrySpecs once APIs can be properly injected
export class CreateOrganizationService implements Service<Payload, Organization> {
  constructor(
    private readonly store: AppStore,
    private readonly organizationApi: OrganizationApiInterface,
    private readonly createSubscription?: CreateSubscriptionService
  ) {}

  async call({ organization }: Payload): Promise<Success<Organization>> {
    const apiOrganization = (await this.organizationApi.createOrganization({ organization })).organization;
    const domainOrganization = parseApiOrganization(apiOrganization);

    this.store.setState((state) => ({
      ...state,
      organizations: {
        ...(state.organizations || {}),
        [domainOrganization.id]: domainOrganization
      },
    }));

    if (this.createSubscription) {
      await this.createSubscription.call({ organization, organizationId: domainOrganization.id });
    }

    return {
      type: "success",
      value: domainOrganization
    };
  }
}
