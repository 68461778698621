import { Button, ClickAwayListener, Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useCallback } from "react";
import { useI18n } from "../../hooks/useI18n";

export function GlobalFallback() {
  const i18n = useI18n();
  const title = i18n?.t("common.errors.title_unknown") ?? "Something went wrong";
  const notificationText = i18n?.t("common.alerts.alert_notification") ?? "An error occurred";
  const backHomeMessage = i18n?.t("common.interface.back_to_home") ?? "Back to home";
  const handleBackHome = useCallback(() => {
    window.location.href = "/";
  }, []);

  return (
    <ClickAwayListener onClickAway={handleBackHome}>
      <Container sx={{ marginTop: "25vh" }}>
        <CssBaseline />
        <Container maxWidth="sm">
          <Typography
            variant="h1"
            align="center"
            sx={{
              fontSize: "2em",
              fontWeight: 300,
              "& strong": {
                fontWeight: 400,
              },
              marginY: "1em",
            }}
          >
            <strong>{title}</strong>
          </Typography>
          <Typography variant="body1" align="center" sx={{ marginY: "2em" }}>
            {notificationText}
          </Typography>

          <Box textAlign="center">
            <Button variant="contained" href="/" onClick={handleBackHome} disableElevation>
              {backHomeMessage}
            </Button>
          </Box>
        </Container>
      </Container>
    </ClickAwayListener>
  );
}
