import { useParams } from "react-router-dom";
import { useI18n } from "../../../hooks/useI18n";
import { useHealthRecord } from "../../../hooks/useHealthRecord";
import { useCallback, useState } from "react";
import StyledDialog from "../../../components/mui/StyledDialog";
import { DeleteButton } from "../../../components/mui/StyledButtons";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useApis } from "../../../providers/Dependencies";
import { useAppContext } from "../../../hooks/useAppContext";

interface DeleteModalProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  noteId: string | undefined;
}

export function NoteDeleteModal({ modalOpen, setModalOpen, noteId }: DeleteModalProps) {
  const { t } = useI18n();
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();
  const { id } = useParams() as { id: string };
  const { healthRecord, refetch } = useHealthRecord(id);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const deleteNote = useCallback(
    async () => {
      if (healthRecord && noteId) {
        setErrorMessage(undefined);
        setIsSubmitting(true);
  
        try {
          // TODO: Wrap this inside a service
          if (organizationId && teamId) {
            await apis.team.noteApi.deleteNote(organizationId, teamId, healthRecord.id, noteId)
          } else {
            await apis.pro.noteApi.deleteNote(healthRecord.id, noteId)
          }

          refetch();
          setModalOpen(false);
        } catch (e) {
          setErrorMessage(t("common.alerts.alert_notification"));
        } finally {
          setIsSubmitting(false);
        }
      }
    }, [apis, healthRecord, noteId, refetch, setModalOpen, t, organizationId, teamId]
  )

  return (
    <StyledDialog
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      title={t("notes.deleteModalTitle")}
      dialogActions={
        <DeleteButton
          onClick={deleteNote}
          autoFocus
          startIcon={<DeleteOutlineIcon />}
          loading={isSubmitting}
        >
          {t("common.cta.delete")}
        </DeleteButton>
      }
      errorMessage={errorMessage}
    >
      {t("notes.deleteModalContent")}
    </StyledDialog>
  )
}