import { parseApiTeam } from "../network/parsers/parseApiTeam";
import { parseApiOrganization } from "../network/parsers/parseApiOrganization";
import { Organization } from "../domain/organization";
import { OrganizationApiInterface } from "@syadem/kairos-team-js";
import { AppStore } from "../store";

export class AllOrganizationsQuery {
  constructor(
    private readonly store: AppStore,
    private readonly organizationApi: OrganizationApiInterface
  ) {}

  async call(): Promise<Organization[]> {
    const apiOrganizations = await this.organizationApi.allOrganizations();
    const organizations = apiOrganizations.map((o) => parseApiOrganization(o));
    const teams = apiOrganizations.flatMap((o) => o.teams.map((t) => parseApiTeam(t, o.id)));

    this.store.setState({
      organizations: organizations.reduce((acc, o) => ({ ...acc, [o.id]: o }), {}),
      teams: teams.reduce((acc, t) => ({ ...acc, [t.id]: t }), {})
    });

    return organizations;
  }
}
