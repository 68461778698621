import { VaccinationAct as ProVaccinationAct, VaccinationActCertificatorTypeEnum } from "@syadem/kairos-pro-js";
import { VaccinationAct as TeamVaccinationAct } from "@syadem/kairos-team-js";

export function isEditableVaccination(
  vaccinationAct: ProVaccinationAct | TeamVaccinationAct,
  currentUserId: string | undefined,
  teamId: string | undefined
): boolean {
  if (vaccinationAct.certificator && vaccinationAct.certificationMethod == "execution") {
    switch (vaccinationAct.certificator.type) {
      case VaccinationActCertificatorTypeEnum.Professional:
        return(!teamId && vaccinationAct.certificator.id == currentUserId);
      case VaccinationActCertificatorTypeEnum.Team:
        return(vaccinationAct.certificator.id == teamId);
    }
  }

  return true;
}