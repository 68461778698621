import { Box, CircularProgress, Typography } from "@mui/material";

export function Loading({ title }: { title?: string }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <CircularProgress />
      {title && (
        <Typography variant="h6" component="div" sx={{ pt: 2 }}>
          {title}
        </Typography>
      )}
    </Box>
  );
}
