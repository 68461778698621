import { Service } from "./service";
import { Success } from "../actionResult";
import { HealthRecordApiInterface as ProHealthRecordApiInterface, HealthRecord as ProHealthRecord, CreateHealthRecordBodyHealthRecordGenderEnum } from "@syadem/kairos-pro-js";
import { HealthRecordApiInterface as TeamHealthRecordApiInterface, HealthRecord as TeamHealthRecord } from "@syadem/kairos-team-js";

interface Payload {
  healthRecord: {
    externalId: string | null;
    firstNames: string;
    lastName: string;
    customaryName: string | null;
    birthDate: Date;
    gender: CreateHealthRecordBodyHealthRecordGenderEnum;
    zipCode: string;
    birthCountryCode: string | null;
    birthCityCode: string | null;
  };
  organizationId: string | null;
  teamId: string | null;
}

export class CreateHealthRecordService implements Service<Payload, ProHealthRecord | TeamHealthRecord> {
  constructor(
    private readonly proHealthRecordApi: ProHealthRecordApiInterface,
    private readonly teamHealthRecordApi: TeamHealthRecordApiInterface
  ) {}

  async call({ healthRecord, organizationId, teamId }: Payload): Promise<Success<ProHealthRecord | TeamHealthRecord>> {
    let apiHealthRecord: ProHealthRecord | TeamHealthRecord;

    if (organizationId && teamId) {
      apiHealthRecord = (await this.teamHealthRecordApi.createHealthRecord(organizationId, teamId, { healthRecord })).healthRecord
    } else {
      apiHealthRecord = (await this.proHealthRecordApi.createHealthRecord({ healthRecord })).healthRecord
    }
    
    return {
      type: "success",
      value: apiHealthRecord
    };
  }
}
