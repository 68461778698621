import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { Avatar, Box, IconButton, Link } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { useI18n } from "../hooks/useI18n";
import { useCurrentTeamInfos } from "../hooks/useCurrentTeamInfos";
import { theme } from "../layout/Theme";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useAuthenticatedUser } from '../../store';

export default function TeamAdminButton() {
  const { t } = useI18n();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const currentTeam = useCurrentTeamInfos();
  const { teamId, organizationId } = useParams();
  const currentUser = useAuthenticatedUser();
  const basePath = '/organizations/' + organizationId + '/teams/' + teamId

  const isAdmin = currentUser ? currentTeam?.team?.adminIds?.includes(currentUser?.id) : false;

  return (isAdmin ? (
    <Box sx={{ mr: 2 }}>
      <IconButton
        id="settingsMenuButton"
        aria-controls={open ? "settingsMenuButton" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Avatar sx={{ background: theme.palette.primary.main, width: 24, height: 24 }}>
          <SettingsOutlinedIcon sx={{ color: "white" }} fontSize="small" />
        </Avatar>
      </IconButton>
      <Menu
        id="helpCenterMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "helpCenterMenuButton",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          sx={{
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          <Link
              underline="none"
              color={theme.palette.primary.main}
              to={basePath + '/invite-member'}
              component={RouterLink}
              sx={{
                "&:hover": {
                  color: theme.palette.primary[300],
                },
              }}
            >
            <PersonAddIcon sx={{ mr: 2 }} />
            {t("team.invite")}
          </Link>
        </MenuItem>
        <MenuItem
          sx={{
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          <Link
              underline="none"
              color={theme.palette.primary.main}
              to={basePath + '/members'}
              component={RouterLink}
              sx={{
                "&:hover": {
                  color: theme.palette.primary[300],
                },
              }}
            >
            <GroupsIcon sx={{ mr: 2 }} />
            {t("team.member_list")}
          </Link>
        </MenuItem>
      </Menu>
    </Box>
  )
  : null
  );
}
