import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Typography } from "@mui/material";
import { useI18n } from "../hooks/useI18n";

export function LoadingAppFailed() {
  const { t } = useI18n();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f5f5f5",
      }}
    >
      <ErrorOutlineIcon color="error" style={{ fontSize: 60 }} />
      <Typography variant="h4" component="div" sx={{ pt: 2 }}>
        {t("common.alerts.alert_auth_failed")}
      </Typography>
      <Typography variant="body1" component="div" sx={{ pt: 2, px: 5, textAlign: "center" }}>
        {t("common.alerts.alert_auth")}
      </Typography>
      <Button
        variant="contained"
        disableElevation
        color="primary"
        sx={{ mt: 3 }}
        onClick={() => {
          window.location.reload();
        }}
      >
        {t("common.retry")}
      </Button>
    </Box>
  );
}
