import { HealthRecord } from "@syadem/kairos-pro-js";
import { useCallback, useEffect } from "react";
import { create } from "zustand";
import { useApis } from "../providers/Dependencies";
import { useAppContext } from "./useAppContext";
import { Apis } from "../../network/apis";

export function useHealthRecord(id: string): {
  isLoading: boolean;
  healthRecord?: HealthRecord;
  error?: Response | Error;
  refetch: () => void;
} {
  const state = useStore();
  const fetchAction = useStore((state) => state.fetch);
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();

  const refetch = useCallback(() => {
    fetchAction(apis, id, organizationId, teamId, true);
  }, [fetchAction, id, organizationId, teamId, apis]);

  useEffect(() => {
    fetchAction(apis, id, organizationId, teamId, false);
  }, [fetchAction, id, organizationId, teamId, apis]);

  return {
    ...state,
    refetch,
  };
}

interface StoreState {
  isLoading: boolean;
  healthRecord?: HealthRecord;
  error?: Response | Error;
  fetch: (apis: Apis, id: string, organizationId: string | null, teamId: string | null, refresh: boolean) => Promise<void>;
}

const useStore = create<StoreState>((set, get) => ({
  isLoading: false,
  healthRecord: undefined,
  error: undefined,
  fetch: async (apis, id, organizationId, teamId, refresh) => {
    if (get().isLoading && !refresh) {
      return;
    }
    set({ isLoading: true, error: undefined });
    try {
      // TODO: Wrap this inside a query
      if (organizationId && teamId) {
        const { healthRecord } = await apis.team.healthRecordApi.getHealthRecord(organizationId, teamId, id)
        set({ isLoading: false, healthRecord, error: undefined });
      } else {
        const { healthRecord } = await apis.pro.healthRecordApi.getHealthRecord(id)
        set({ isLoading: false, healthRecord, error: undefined });
      }
    } catch (error) {
      set({ isLoading: false, healthRecord: undefined, error: error as Response | Error });
    }
  },
}));
