import { OrganizationApiInterface } from "@syadem/kairos-subscription-js";

interface Payload {
  organizationId: string;
}

export class SubscriptionCheckoutUrlQuery {
  constructor(private readonly organizationApi: OrganizationApiInterface) {}

  async call({ organizationId }: Payload): Promise<string> {
    return (await this.organizationApi.getCheckoutUrl(organizationId)).checkoutUrl;
  }
}
