import { en } from "./en";
import { lv } from "./lv";
import { gr } from "./gr";
import { fr, TranslationObject } from "./fr";

const translations: { [key in LocaleString]: TranslationObject } = {
  fr,
  "en-gb": en,
  lv,
  gr
};
export default translations;

export const AVAILABLE_LOCALES = ["fr", "en-gb", "lv", "gr"] as const;
export type LocaleString = typeof AVAILABLE_LOCALES[number];

export const LOCALE_LABELS: { [key in LocaleString]: string } = {
  fr: "FR",
  "en-gb": "EN",
  lv: "LV",
  gr: "GR"
};
