import { ActionResult } from "./actionResult";
import { AppStore } from "./store";

export class ResultNotifier {
  constructor(private readonly store: AppStore) {}

  call(result: ActionResult<unknown>) {
    if (result.message) {
      this.store.setState((state) => ({
        actionResults: [...state.actionResults, result],
      }));

      // Delete the result after a few seconds
      setTimeout(() => {
        this.store.setState((state) => ({
          actionResults: state.actionResults.filter((i) => i != result),
        }));
      }, 8000);
    }
  }
}
