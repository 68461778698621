import { Alert, Button } from "@mui/material";
import { useI18n } from "../../hooks/useI18n";
import { PageLayout } from "../../layout/PageLayout";
import { useAuthenticatedUser, useOrganization, useSubscriptionOrganization, useTeamsForOrganizationId } from "../../../store";
import { EmptyListPlaceholder } from "../../components/shared/EmptyListPlaceholder";
import { Link } from "react-router-dom";
import { theme } from "../../layout/Theme";
import AddIcon from "@mui/icons-material/Add";
import GroupsIcon from '@mui/icons-material/Groups';
import { SubscriptionStatusEnum } from "@syadem/kairos-subscription-js";
import { useCallback } from "react";
import { useAppContext } from "../../hooks/useAppContext";
import { useCountryConfig } from "../../hooks/useCountryConfig";

export function EmptyOrganization() {
  const { t } = useI18n();
  const { organizationId } = useAppContext();
  const organization = useOrganization(organizationId ?? undefined);
  const teams = useTeamsForOrganizationId(organizationId ?? undefined);
  const currentUser = useAuthenticatedUser();
  const subscriptionOrganization = useSubscriptionOrganization(organizationId ?? undefined) ?? undefined;
  const { subscription } = useCountryConfig();

  const isActive =
    subscriptionOrganization &&
    (subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Active ||
    subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Trialing)

  const hasNoPaymentMethod =
    subscriptionOrganization && subscription &&
    subscriptionOrganization.subscription.product.id == subscription.defaultProductId &&
    !subscriptionOrganization.defaultPaymentMethod

  const displayedText = useCallback(
    () => {
      if (currentUser && organization && !organization.adminIds.includes(currentUser.id)) {
        return t("team.contactAdminToAddTeams")
      } else if (isActive === false) {
        return t("team.reactivateSubscriptionToAddTeam")
      } else if (hasNoPaymentMethod === true) {
        return t("team.addPaymentMethodToAddTeam")
      } else {
        return t("team.addTeamsForOrganization")
      }
    }, [isActive, hasNoPaymentMethod, currentUser, organization, t]
  )

  return (
    organization && teams && (
      <PageLayout title={organization.name}>
        {teams.filter((t) => !t.activated).map((team) => (
          <Alert severity="info" key={team.id} sx={{ mb: 1 }}>{t("organization.teamActivationPending", { name: team.name })}</Alert>
        ))}
        <EmptyListPlaceholder
          title={t("team.none")}
          icon={<GroupsIcon fontSize="large" sx={{ color: theme.palette.primary[500] }} />}
          button={
            currentUser && organization.adminIds.includes(currentUser.id) && isActive !== false && hasNoPaymentMethod !== true && (
              <Button size="large" component={Link} to="teams/new" variant="contained" startIcon={<AddIcon />} disableElevation>
                {t("team.add")}
              </Button>
            )
          }
        >
          {displayedText()}
        </EmptyListPlaceholder>
      </PageLayout>
    )
  )
}