import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, IconButton, SxProps, Theme, Tooltip, useMediaQuery } from "@mui/material";
import { ReactNode, useCallback, useState } from "react";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";
import { DeleteButton } from "../mui/StyledButtons";
import StyledDialog from "../mui/StyledDialog";
interface ListItemDeleteButtonProps {
  deleteRecord: () => Promise<void>;
  refreshCallback: () => void;
  title: string;
  modalTitle: string;
  modalContent: string | ReactNode;
  icon: ReactNode;
  testId?: string;
  label?: string;
  isDisabled?: boolean;
  sx?: SxProps<Theme> | undefined;
  variant?: "text" | "outlined" | "contained";
  type?: "button" | "icon";
  buttonWidth?: string;
  fullWidth?: boolean;
}

export function ListItemDeleteButton(props: ListItemDeleteButtonProps) {
  const { t } = useI18n();
  const {
    deleteRecord,
    refreshCallback,
    title,
    modalTitle,
    modalContent,
    icon,
    testId,
    label,
    isDisabled = false,
    sx,
    variant = "contained",
    type = "button",
    buttonWidth = "auto",
    fullWidth,
  } = props;
  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const breakpointMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const handleClickOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleClose = () => {
    setDeleteDialogOpen(false);
  };

  const deleteHandler = useCallback(async () => {
    setErrorMessage(undefined);
    setIsSending(true);
    try {
      await deleteRecord();
      refreshCallback();
      setDeleteDialogOpen(false);
    } catch (e) {
      setErrorMessage(t("common.alerts.alert_notification"));
    } finally {
      setIsSending(false);
    }
  }, [deleteRecord, refreshCallback, t]);

  return (
    <>
      {type === "button" ? (
        <Button
          variant={variant}
          color="error"
          onClick={handleClickOpen}
          data-testid={testId}
          disabled={isDisabled}
          sx={{
            ...sx,
            width: { xs: "auto", md: buttonWidth },
          }}
          fullWidth={fullWidth}
          size={breakpointMdUp ? "medium" : "small"}
          startIcon={icon}
          disableElevation
        >
          {title}
        </Button>
      ) : (
        <Tooltip title={title} arrow>
          <IconButton
            onClick={handleClickOpen}
            data-testid={testId}
            disabled={isDisabled}
            sx={{
              "&.Mui-disabled svg": {
                color: theme.palette.neutral[400],
              },
            }}
          >
            {icon}
          </IconButton>
        </Tooltip>
      )}
      <StyledDialog
        open={deleteDialogOpen}
        onClose={handleClose}
        data-testid={`${testId}Dialog`}
        title={modalTitle}
        dialogActions={
          <DeleteButton
            onClick={deleteHandler}
            autoFocus
            data-testid={`${testId}DialogDeleteCta`}
            startIcon={<DeleteOutlineIcon />}
            loading={isSending}
          >
            {label ? label : t("common.cta.delete")}
          </DeleteButton>
        }
        errorMessage={errorMessage}
      >
        {modalContent}
      </StyledDialog>
    </>
  );
}
