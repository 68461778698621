import { FormControl, FormGroup, FormLabel } from "@mui/material";
import { theme } from "../../layout/Theme";

interface StyledCheckboxProps {
  label?: string;
  fullWidth?: boolean;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  testId?: string;
  children: React.ReactNode;
}

export const StyledCheckbox = ({
  label,
  fullWidth,
  error,
  disabled,
  required,
  testId = "",
  children,
}: StyledCheckboxProps) => {
  return (
    <FormControl
      variant="standard"
      error={error}
      fullWidth={!!fullWidth}
      disabled={disabled}
      required={required}
      component="fieldset"
    >
      {label && (
        <FormLabel
          sx={{
            color: theme.palette.neutral[600],
            fontSize: 14,
            fontWeight: 600,
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            transition: "color 0.2s ease-in-out",
            transform: "none",
            ".MuiFormLabel-asterisk": {
              color: theme.palette.error[500],
            },
            mb: 1.3,
          }}
          data-testid={`${testId}Label`}
        >
          {label}
        </FormLabel>
      )}
      <FormGroup
        sx={{
          ".MuiCheckbox-root": {
            color: theme.palette.neutral[400],
            "&.Mui-checked": {
              color: theme.palette.primary.main,
            },
          },
          ".MuiFormControlLabel-root": {
            color: disabled ? theme.palette.neutral[500] : "inherit",
          },
        }}
        data-testid={`${testId}CheckboxGroup`}
      >
        {children}
      </FormGroup>
    </FormControl>
  );
};
