import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import { useI18n } from "../../hooks/useI18n";
import { AddVaccination } from "./AddVaccination";
import AddVaccinations from "./AddVaccinations";

function AddVaccinationType() {
  const { t } = useI18n();
  const [formType, setFormType] = useState("simple");

  const handleChange = (event: React.MouseEvent<HTMLElement>, newFormType: string) => {
    setFormType(newFormType);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ToggleButtonGroup
        color="primary"
        value={formType}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        sx={{ mb: 3 }}
      >
        <ToggleButton value="simple">{t("vaccines.addVaccinationType.simple_entry")}</ToggleButton>
        <ToggleButton value="multiple">{t("vaccines.addVaccinationType.multiple_entry")}</ToggleButton>
      </ToggleButtonGroup>
      {formType === "simple" && <AddVaccination />}
      {formType === "multiple" && <AddVaccinations />}
    </Box>
  );
}

export default AddVaccinationType;
