import { useParams } from "react-router-dom";
import { useHealthRecord } from "../../../hooks/useHealthRecord";
import { Alert, Box, Button, Chip, Divider, FormControl, Grid, IconButton, InputLabel, OutlinedInput, Tooltip, Typography } from "@mui/material";
import { Note, NoteVisibilityEnum } from "@syadem/kairos-pro-js";
import { useI18n } from "../../../hooks/useI18n";
import { formatDistanceToNow } from "../../../../utils/dayjs";
import { theme } from "../../../layout/Theme";
import { useCallback, useEffect, useState } from "react";
import { useArianeApi } from "../../../hooks";
import { Professional, Team } from "@syadem/ariane-js";
import { useCurrentTeamInfos } from "../../../hooks/useCurrentTeamInfos";
import { NoteDeleteModal } from "./NoteDeleteModal";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuthenticatedUser } from "../../../../store";
import { useApis } from "../../../providers/Dependencies";
import { useAppContext } from "../../../hooks/useAppContext";

export function NotesList() {
  const { id } = useParams() as { id: string };
  const { healthRecord, refetch } = useHealthRecord(id);
  const { t } = useI18n();
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();
  const arianeApi = useArianeApi();
  const [prosAndTeams, setProsAndTeams] = useState<(Professional | Team)[] | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<string | undefined>(undefined);
  const [idToEdit, setIdToEdit] = useState<string | undefined>(undefined);
  const [contentToEdit, setContentToEdit] = useState<string | undefined>(undefined);
  const [isSubmittingEdit, setIsSubmittingEdit] = useState<boolean>(false);
  const [editErrorMessage, setEditErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (healthRecord && healthRecord.notes.length > 0) {
        const proAndTeamIds = healthRecord.notes.flatMap(
          (note) => note.teamId ? [note.professionalId, note.teamId] : [note.professionalId]
        )

        let result: (Professional | Team)[] = [];

        try {
          result = await arianeApi.professionals.searchByIds([...new Set(proAndTeamIds)]);
        } catch (error) {
          result = [];
        }

        setProsAndTeams(result);
      }
    })();
  }, [arianeApi.professionals, healthRecord]);

  const submitEditForm = useCallback(
    async () => {
      if (healthRecord && idToEdit && contentToEdit && contentToEdit.trim().length > 0) {
        setEditErrorMessage(undefined);
        setIsSubmittingEdit(true);
  
        try {
          // TODO: Wrap this inside a service
          if (organizationId && teamId) {
            await apis.team.noteApi.updateNote(
              organizationId,
              teamId,
              healthRecord.id,
              idToEdit,
              { note: { content: contentToEdit.trim() } }
            )
          } else {
            await apis.pro.noteApi.updateNote(
              healthRecord.id,
              idToEdit,
              { note: { content: contentToEdit.trim() } }
            )
          }

          refetch();
          setIdToEdit(undefined);
          setContentToEdit(undefined);
        } catch (e) {
          setEditErrorMessage(t("common.alerts.alert_notification"));
        } finally {
          setIsSubmittingEdit(false);
        }
      }
    }, [apis, contentToEdit, idToEdit, healthRecord, refetch, t, organizationId, teamId]
  )
  
  if (!healthRecord || healthRecord.notes.length == 0 || !prosAndTeams) { return null; }

  return (
    <Box mb={2}>
      {healthRecord.notes.map((note, i) => {
        const pro = prosAndTeams.find((p) => p.id == note.professionalId);
        const team = prosAndTeams.find((t) => t.id == note.teamId);

        return (
          <Box key={note.id}>
            <Grid container>
              <Grid item container xs={10}>
                <Grid item container justifyContent="space-between" alignItems="center" xs={12} pl="14px">
                  <AuthorLabel pro={pro} team={team} />
                  <VisibilityChip note={note} />
                </Grid>
                <Grid item xs={12}>
                  {idToEdit == note.id ? (
                    <EditContentInput
                      contentToEdit={contentToEdit}
                      setContentToEdit={setContentToEdit}
                      editErrorMessage={editErrorMessage}
                    />
                  ) : (
                    <Content
                      note={note}
                      setIdToEdit={setIdToEdit}
                      setContentToEdit={setContentToEdit}
                      setEditErrorMessage={setEditErrorMessage}
                    />
                  )}
                </Grid>
                <Grid item xs={12} pl="14px">
                  {idToEdit == note.id ? (
                    <CancelModificationsButton
                      setIdToEdit={setIdToEdit}
                      setContentToEdit={setContentToEdit}
                      setEditErrorMessage={setEditErrorMessage}
                    />
                  ) : (
                    <Timestamps note={note} />
                  )}
                </Grid>
              </Grid>
              <Grid item container alignItems="center" justifyContent="flex-end" xs={2}>
                {idToEdit == note.id ? (
                  <ApplyModificationsButton isSubmittingEdit={isSubmittingEdit} submitEditForm={submitEditForm} />
                ) : (
                  <DeleteButton note={note} setIdToDelete={setIdToDelete} setModalOpen={setModalOpen} />
                )}
              </Grid>
            </Grid>
            {i != healthRecord.notes.length -1 && <Divider sx={{ my: 1 }} />}
          </Box>
        )
      })}
      <NoteDeleteModal modalOpen={modalOpen} setModalOpen={setModalOpen} noteId={idToDelete} />
    </Box>
  )
}

function AuthorLabel({ pro, team }: { pro: Professional | Team | undefined, team: Professional | Team | undefined }) {
  const { t } = useI18n();
  const currentTeam = useCurrentTeamInfos().team;

  const label = useCallback(
    () => {
      const proLabel = pro ? `${(pro as Professional).first_name} ${(pro as Professional).last_name}` : t("notes.unknownAuthor")

      if (!team) { return proLabel; }
      
      if (team.id == currentTeam?.id) {
        return `${(team as Team).name} (${proLabel})`;
      } else {
        return (team as Team).name;
      }
    }, [currentTeam, team, pro, t]
  )

  return (
    <Typography fontWeight="500" color={theme.palette.neutral[600]}>
      {label()}
    </Typography>
  )
}

function VisibilityChip({ note }: { note: Note }) {
  const { t } = useI18n();
  const { team } = useCurrentTeamInfos();

  if (note.visibility == NoteVisibilityEnum.Internal) {
    return <Chip label={team ? t("notes.internal") : t("notes.private")} size="small" sx={{ background: theme.palette.warning[200] }} />
  } else if (note.visibility == NoteVisibilityEnum.Public) {
    return <Chip label={t("notes.public")} size="small" />
  }
}

function Timestamps({ note }: { note: Note }) {
  const { t, locale } = useI18n();

  return (
    <>
      <Typography component="span" color={theme.palette.neutral[500]} fontSize="0.85rem">
        {t(`notes.addedAt`, {
          distance: formatDistanceToNow(locale, note.createdAt),
        })}
      </Typography>
      {note.updatedAt.toISOString() != note.createdAt.toISOString() && (
        <Typography component="span" color={theme.palette.neutral[500]} fontSize="0.85rem">
          {" - " + t(`notes.lastModified`, {
            distance: formatDistanceToNow(locale, note.updatedAt),
          })}
        </Typography>
      )}
    </>
  )
}

interface ContentProps {
  note: Note;
  setIdToEdit: (value: React.SetStateAction<string | undefined>) => void;
  setContentToEdit: React.Dispatch<React.SetStateAction<string | undefined>>;
  setEditErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
}

function Content({ note, setIdToEdit, setContentToEdit, setEditErrorMessage }: ContentProps) {
  const currentUser = useAuthenticatedUser();
  const { team } = useCurrentTeamInfos();

  const isEditable = useCallback(
    () => {
      if (note.professionalId != currentUser?.id) { return false; }

      return !!(!note.teamId && !team) || !!(note.teamId && note.teamId == team?.id)
    }, [currentUser, team, note]
  );
  
  return (
    <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
      <OutlinedInput
        size="small"
        multiline
        disabled={!isEditable()}
        value={note.content}
        onClick={() => {
          if (isEditable()) {
            setEditErrorMessage(undefined);
            setContentToEdit(note.content);
            setIdToEdit(note.id);
          } 
        }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline" : {
            borderWidth : 0
          },
          "&:hover > .MuiOutlinedInput-notchedOutline" : {
            borderWidth : isEditable() ? 1 : 0,
            borderColor: theme.palette.neutral[400]
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#212121",
          }
        }}
      />
    </FormControl>
  )
}

interface DeleteButtonProps {
  note: Note;
  setIdToDelete: (value: React.SetStateAction<string | undefined>) => void;
  setModalOpen: (value: React.SetStateAction<boolean>) => void
}

function DeleteButton({ note, setIdToDelete, setModalOpen }: DeleteButtonProps) {
  const { t } = useI18n();
  const currentUser = useAuthenticatedUser();
  const { team } = useCurrentTeamInfos();

  if ((!note.teamId && !team && note.professionalId == currentUser?.id) || (note.teamId && note.teamId == team?.id)) {
    return (
      <Tooltip title={t("common.cta.delete")}>
        <IconButton
          sx={{
            color: theme.palette.neutral[400]
          }}
          onClick={() => {
            setIdToDelete(note.id);
            setModalOpen(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    )
  } else {
    return null;
  }
}

interface EditContentInputProps {
  contentToEdit: string | undefined;
  setContentToEdit: (value: React.SetStateAction<string | undefined>) => void;
  editErrorMessage: string | undefined;
}

function EditContentInput({ contentToEdit, setContentToEdit, editErrorMessage }: EditContentInputProps) {
  const { t } = useI18n();

  return (
    <>
      <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
        <InputLabel size="small">{t("notes.editContent")}</InputLabel>
        <OutlinedInput
          label={t("notes.editContent")}
          size="small"
          multiline
          value={contentToEdit}
          autoFocus
          onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setContentToEdit(event.target.value)}
        />
      </FormControl>
      {editErrorMessage && <Alert severity="error" sx={{ mt: 1, py: 0, px: 1 }}>{editErrorMessage}</Alert>}
    </>
  )
}

interface CancelModificationsButtonProps {
  setIdToEdit: (value: React.SetStateAction<string | undefined>) => void;
  setContentToEdit: (value: React.SetStateAction<string | undefined>) => void;
  setEditErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
}

function CancelModificationsButton({ setIdToEdit, setContentToEdit, setEditErrorMessage }: CancelModificationsButtonProps) {
  const { t } = useI18n();

  return (
    <Button
      variant="text"
      sx={{ p: 0 }}
      onClick={() => {
        setEditErrorMessage(undefined);
        setIdToEdit(undefined);
        setContentToEdit(undefined);
      }}
    >
      <Typography component="span" color={theme.palette.neutral[500]} fontSize="0.85rem">
        {t("notes.cancelModifications")}
      </Typography>
    </Button>
  )
}

interface ApplyModificationsButtonProps {
  isSubmittingEdit: boolean;
  submitEditForm: () => Promise<void>;
}

function ApplyModificationsButton({ isSubmittingEdit, submitEditForm }: ApplyModificationsButtonProps) {
  const { t } = useI18n();

  return (
    <LoadingButton
      disableElevation
      variant="contained"
      loading={isSubmittingEdit}
      onClick={submitEditForm}
      sx={{ ml: 2 }}
    >
      {t("common.cta.edit")}
    </LoadingButton>
  )
}