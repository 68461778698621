import { OrganizationApiInterface } from "@syadem/kairos-team-js";
import { User } from "../domain/user";
import { AppRouter } from "../appConfig";

export const LAST_HOME_PAGE_KEY = "lastHomepage";

export class RedirectToLiberalOrTeamService {
  constructor(
    private readonly router: AppRouter,
    private readonly localStorage: Storage,
    private readonly organizationApi: OrganizationApiInterface
  ) {}

  async call({ searchParams, user }: { searchParams: URLSearchParams, user: User }) {
    if (!user.verified) {
      this.router.navigate("/account-verification", { replace: true });
      return;
    }

    const lastHomePage = this.localStorage.getItem(LAST_HOME_PAGE_KEY);
    if (lastHomePage && lastHomePage.trim() !== "") {
      this.router.navigate(`${lastHomePage}?` + searchParams.toString(), { replace: true });
      return;
    }

    if (user.rpps) {
      this.router.navigate("/liberal?" + searchParams.toString(), { replace: true });
    } else {
      const organizations = await this.organizationApi.allOrganizations();
      const firstOrgWithTeam = organizations.find((org) => org.teams.length > 0);
      const firstTeam = firstOrgWithTeam ? firstOrgWithTeam.teams[0] : undefined;
      if (firstOrgWithTeam && firstTeam) {
        const teamUrl = `/organizations/${firstOrgWithTeam.id}/teams/${firstTeam.id}`;
        this.router.navigate(teamUrl, { replace: true });
      } else {
        this.router.navigate("/liberal/rpps-missing", { replace: true });
      }
    }
  }
}
