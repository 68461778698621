import { frFR, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Polyglot from "node-polyglot";
import { createContext, ReactNode, useEffect, useMemo } from "react";
import translations, { LocaleString } from "../../translations";
import { dayjs } from "../../utils/dayjs";
import usePersistedState from "../hooks/usePersistedState";
import { fr } from "../../translations/fr";
export interface I18nInterface {
  setLocale: (locale: LocaleString) => void;
  t: (phrase: string, options?: number | Polyglot.InterpolationOptions | undefined) => string;
  getObject: (key: string) => string;
  locale: LocaleString;
}

type Path<T> = {
  [K in keyof T]: T[K] extends object ? `${string & K}` | `${string & K}.${Path<T[K]>}` : `${string & K}`;
}[keyof T];

export type I18nKey = Path<typeof fr>;

export const getLocaleText = (locale: string) => {
  switch (locale) {
    case "fr":
      return frFR.components.MuiLocalizationProvider.defaultProps.localeText;
    default:
      return undefined;
  }
};

// I18nContext value is set by the I18nProvider component.
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const I18nContext = createContext<I18nInterface>(null!);

export const I18nProvider = ({ children, defaultLocale }: { children: ReactNode, defaultLocale: LocaleString }) => {
  const [currentLocale, setLocale] = usePersistedState<LocaleString>("proLocale", defaultLocale);

  useEffect(() => {
    dayjs.locale(currentLocale);
    if (typeof window !== "undefined") {
      document.documentElement.setAttribute('lang', currentLocale);
    }
  }, [currentLocale]);

  const polyglot = useMemo<Polyglot>(
    // Create a new instance on every change
    // This make sure we always consume the latest phrases and Polyglot context
    () =>
      new Polyglot({
        locale: currentLocale,
        phrases: translations[currentLocale],
      }),
    [currentLocale]
  );

  const polyglotContext: I18nInterface = useMemo(
    () => ({
      locale: polyglot.locale() as LocaleString,
      t: polyglot.t.bind(polyglot),
      setLocale,
      getObject: (key: string) => {
        return key
          .replace(/\[([^[\]]*)\]/g, ".$1.")
          .split(".")
          .filter((t) => t !== "")
          .reduce((prev: any, cur: string) => prev && prev[cur], translations[currentLocale]);
      },
    }),
    [polyglot, currentLocale, setLocale]
  );

  return (
    <I18nContext.Provider value={polyglotContext}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={dayjs.locale(currentLocale === "en-gb" ? "en" : currentLocale)}
        localeText={getLocaleText(currentLocale)}
      >
        {children}
      </LocalizationProvider>
    </I18nContext.Provider>
  );
};
