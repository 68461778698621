import Box from "@mui/material/Box";
import ReactCountryFlag from "react-country-flag";
import { LOCALE_LABELS, LocaleString } from "../../translations";

export function FlagIcon({ locale }: { locale: LocaleString }) {
  return (
    <Box sx={{ fontSize: "12px" }}>
      <ReactCountryFlag
        countryCode={locale == "en-gb" ? "GB" : locale.toUpperCase()}
        svg
        style={{
          height: "1.3em",
          marginBottom: "3px",
          marginRight: "12px",
          borderRadius: "5px",
        }}
      />
      {LOCALE_LABELS[locale]}
    </Box>
  );
}
